/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import { PageView } from "../Tracking";
import { ROUTES } from "../../constants/routes";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const StyledTyp = styled(Typography)`
  font-size: 20px !important;
`;

export const ScheduleSuccess = () => {
  const navigate = useNavigate();
  useEffect(() => {
    PageView();
  }, []);
  return (
    <Grid
      xs={12}
      container
      justifyContent="center"
      alignItems="center"
      className="fill-height margin-auto p-a-20"
    >
      <Grid item>
        <Typography variant="h1" className="m-b-30 text-green">
          Your appointment has been scheduled!
        </Typography>
        <StyledTyp style={{ maxWidth: "600px" }} variant="body1" className="margin-auto">
          Check your email for your full hailscale report, an appointment confirmation, and ability to request a rescheduled appointment. If you do not see an email, check your spam.
        </StyledTyp>
        <Button
          onClick={() => navigate(ROUTES.LANDING_PAGE)}
          sx={{ marginTop: "40px" }}
        >Back to home</Button>
      </Grid>
    </Grid>
  );
};

export default ScheduleSuccess;
