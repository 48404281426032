import { Grid, Typography } from "@mui/material";
import { COLORS } from "../../../constants/colors";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const Stat = ({ icon, stat, text, additionalSX }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Grid
      item
      sx={{
        borderRadius: "10px",
        backgroundColor: COLORS.GREY,
        padding: isMedium ? "25px 20px" : "10px",
        maxWidth: isMedium ? "271px" : "122px",
        minWidth: isMedium ? "230px" : "100px",
        "img": {
          maxWidth: isMedium ? "88px" : "48px",
          maxHeight: isMedium ? "77px" : "32px",
          height: isMedium ? "77px" : "32px",
          objectFit: "contain",
          objectPosition: "bottom"
        },
        ...additionalSX
      }}
    >
      <img src={icon} alt="" />
      <Typography variant="h4" sx={{
        fontSize: `${isMedium ? 65 : 30}px !important`,
        fontFamily: "Colfax Medium !important",
      }}>{stat}</Typography>
      <Typography
        variant=""
        sx={{ maxWidth: "225px", fontSize: `${isMedium ? 24 : 16}px !important` }}
      >
        {text}
      </Typography>
    </Grid>
  )
}

export default Stat;