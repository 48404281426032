import React, { createContext, useContext, useReducer } from "react";
import { useLocation } from "react-router-dom";

export const TYPES = {
  ADD_FULL_NAME: "addFullName",
  ADD_ADDRESS: "addAddress",
  ADD_ROOF_AGE: "addRoofAge",
  ADD_PROPERTY_TYPE: "addPropertyType",
  ADD_PHONE_NUMBER: "addPhoneNumber",
  ADD_EMAIL: "addEmail",
  ADD_CONTACT_PREFERENCES: "addContactPreferences",
  ADD_PROMOS: "addPromos",
  CLEAR_FORM: "clearForm"
};
const {
  ADD_FULL_NAME,
  ADD_PHONE_NUMBER,
  ADD_ADDRESS,
  ADD_ROOF_AGE,
  ADD_PROPERTY_TYPE,
  ADD_EMAIL,
  ADD_CONTACT_PREFERENCES,
  ADD_PROMOS,
  CLEAR_FORM
} = TYPES;

const MonitorContext = createContext();

const monitorReducer = (state, action) => {
  switch (action.type) {
    case ADD_FULL_NAME:
      return { ...state, fullName: action.payload };
    case ADD_ADDRESS:
      return { ...state, address: action.payload };
    case ADD_ROOF_AGE:
      return { ...state, roofAge: action.payload };
    case ADD_PROPERTY_TYPE:
      return { ...state, propertyType: action.payload };
    case ADD_PHONE_NUMBER:
      return { ...state, phoneNumber: action.payload };
    case ADD_EMAIL:
      return { ...state, email: action.payload };
    case ADD_CONTACT_PREFERENCES:
      return { ...state, contactPreferences: action.payload };
    case ADD_PROMOS:
      return { ...state, promos: action.payload };
    case CLEAR_FORM:
      return { ...action.payload };
    default:
      return { ...state };
  }
};

const INITIAL_STATE = {
  fullName: "",
  address: "",
  roofAge: "",
  propertyType: "",
  email: "",
  contactPreferences: [],
  phoneNumber: "",
  promos: ""
}

const MonitorProvider = ({ children }) => {

  const location = useLocation();
  let fullName = "";
  let email = "";

  if (location.state) {
    fullName = location.state.name;
    email = location.state.email;
  }

  const [state, dispatch] = useReducer(monitorReducer, { ...INITIAL_STATE, fullName: fullName, email: email });

  const value = { state, dispatch };
  return (
    <MonitorContext.Provider value={value}>
      {children}
    </MonitorContext.Provider>
  );
};

const useMonitor = () => {
  const context = useContext(MonitorContext);

  const { state, dispatch } = context;

  const addFullName = (payload) => dispatch({ type: ADD_FULL_NAME, payload });
  const addPhoneNumber = (payload) => dispatch({ type: ADD_PHONE_NUMBER, payload });
  const addAddress = (payload) => dispatch({ type: ADD_ADDRESS, payload });
  const addRoofAge = (payload) => dispatch({ type: ADD_ROOF_AGE, payload });
  const addPropertyType = (payload) => dispatch({ type: ADD_PROPERTY_TYPE, payload });
  const addEmail = (payload) => dispatch({ type: ADD_EMAIL, payload });
  const addContactPreferences = (payload) => dispatch({ type: ADD_CONTACT_PREFERENCES, payload });
  const addPromos = (payload) => dispatch({ type: ADD_PROMOS, payload });
  const clearForm = () => {
    dispatch({ type: CLEAR_FORM, payload: INITIAL_STATE });
    let els = document.querySelectorAll('input[type=checkbox]');
    for (const el of els) {
      el.checked = false;
    }
  };

  return {
    state,
    addFullName,
    addAddress,
    addRoofAge,
    addPropertyType,
    addPhoneNumber,
    addEmail,
    addContactPreferences,
    addPromos,
    clearForm
  };
};

export { useMonitor, MonitorProvider };