import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { ThemeProvider as OldThemeProvider } from "@material-ui/core";
import "./stylesheets/App.scss";
import ComponentRoutes from "./components/Routes";
import Ctx from "./components/store/Ctx";
import Footer from "./components/Footer";
import { oldTheme, theme } from "./materialTheme";
import { initGA } from "./components/Tracking";
import ScrollTop from "./components/ScrollTop";
import GlobalLoad from "./components/GlobalLoad";
import Header from "./components/Header";

if (!window.location.href.includes("localhost")) {
  initGA();
}

const App = () => {
  const [hideFooter, setHideFooter] = useState(false);

  useEffect(() => {
    setHideFooter(window.location.href.includes("admin"))
  }, [hideFooter]);
  return (
    <div id="App" className="App">
      <ThemeProvider theme={theme}>
        <OldThemeProvider theme={oldTheme}>
          <Ctx>
            <Router>
              <Header />
              <ScrollTop />
              <GlobalLoad />
              <ComponentRoutes />
              <Footer hideFooter={hideFooter} />
            </Router>
          </Ctx>
        </OldThemeProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
