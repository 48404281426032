import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { ADMIN_ROUTES } from "../../../../constants/routes";
import withAdminContext from "../../store/withAdminContext";
import { changeHeaderTitleUrl } from "../../store/actions";

const StyledMenuContainer = styled.div`
  position: fixed;
  width: inherit;
  a {
    height: ${(window.innerHeight - 129) / 6}px;
  }
`;


export const Menu = ({ context: { state, dispatch } }) => {

  return (
    <StyledMenuContainer id="admin_menu">
      {Object.keys(ADMIN_ROUTES).map(route => {
        return ADMIN_ROUTES[route].showInMenu &&
          <NavLink
            id={`navLink_${ADMIN_ROUTES[route].route}`}
            key={route}
            onClick={() => dispatch(changeHeaderTitleUrl({ title: ADMIN_ROUTES[route].title, url: ADMIN_ROUTES[route].route }))}
            className={`navLink ${state.headerTitleUrl.url.includes(ADMIN_ROUTES[route].route) ? "activeNavLink" : "inactiveNavLink"}`}
            to={ADMIN_ROUTES[route].route}
          >
            {ADMIN_ROUTES[route].title}
          </NavLink>
      })}
    </StyledMenuContainer>
  );
}

export default withAdminContext(Menu);