import { useEffect } from "react";
import withAdminContext from "../../store/withAdminContext";
import { getActiveAppointments, getPartners } from "../../../../services";
import { addActiveAppointments, addPartnerNames, areAppointmentsLoading } from "../../store/actions";
import { STAGES } from "../../../../constants/appointment_stages";
import { activeAppointments as appPayload } from "../../store/initialState";


// counts appointments in each stage
export const countAndSortAppointments = (appointments) => {

  for (let i = 0; i < appointments.length; i++) {
    const app = appointments[i];

    appPayload.activeApps++;
    switch (app.appointmentStage) {
      case STAGES.PARTNER_NEEDED:
        appPayload.partnerNeeded.count++;
        appPayload.partnerNeeded.appointments.push(app);
        break;
      case STAGES.FIELD_REP_NEEDED:
        appPayload.fieldRepNeeded.count++;
        appPayload.fieldRepNeeded.appointments.push(app);
        break;
      case STAGES.INSPECTION_OVERDUE:
        appPayload.inspectionOverdue.count++;
        appPayload.inspectionOverdue.appointments.push(app);
        break;
      case STAGES.AWAITING_INSPECTION:
        appPayload.awaitingInspection.count++;
        appPayload.awaitingInspection.appointments.push(app);
        break;
      case STAGES.HOMEOWNER_TO_FILE:
        appPayload.homeownerToFile.count++;
        appPayload.homeownerToFile.appointments.push(app);
        break;
      case STAGES.AWAITING_ADJS_DATE:
        appPayload.awaitingAdjsDate.count++;
        appPayload.awaitingAdjsDate.appointments.push(app);
        break;
      case STAGES.AWAITING_ADJS_OUTCOME:
        appPayload.awaitingAdjsOutcome.count++;
        appPayload.awaitingAdjsOutcome.appointments.push(app);
        break;
      case STAGES.READY_TO_BE_CLOSED:
        appPayload.readyToBeClosed.count++;
        appPayload.readyToBeClosed.appointments.push(app);
        break;
      default:
        appPayload.closedAppointments.count++;
        appPayload.closedAppointments.appointments.push(app);
        break;
    }
  }
  return appPayload;
};

export const AdminLoad = ({ context: { dispatch } }) => {

  useEffect(() => {
    loadAppointments();
    loadPartnerNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAppointments = async () => {
    dispatch(areAppointmentsLoading(true));
    const appointmentsRes = await getActiveAppointments();

    dispatch(addActiveAppointments(countAndSortAppointments(appointmentsRes.data)));
    dispatch(areAppointmentsLoading(false));
  }

  const loadPartnerNames = async () => {
    let partnerNamesRes = await getPartners({ onlyNames: true });
    dispatch(addPartnerNames(partnerNamesRes.data));
  }

  return null;
}

export default withAdminContext(AdminLoad);