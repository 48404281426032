import React, { Fragment } from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useInspection } from "../Provider";
import styled from "styled-components";
import DateImg from "../../../assets/Date_Black.webp";
import { StyledLabel } from "../../Common/StyledElements";

const CalImg = styled.img`
width: 26px;
height: auto;
`;

export const InspectionDate = () => {
  const { state, addInspectionDate } = useInspection();

  return (
    <Fragment>
      <StyledLabel>Inspection date<span className="text-red">*</span></StyledLabel>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          fullWidth
          autoOk
          variant="inline"
          className="bg-white border-radius-10"
          inputVariant="outlined"
          value={state.inspectionDate}
          format="MM/DD/YYYY"
          onChange={(date) => addInspectionDate(date)}
          minDate={state.inspectionDate === "" ? new Date() : ""}
          keyboardIcon={<CalImg src={DateImg} alt="" />}
        />
      </MuiPickersUtilsProvider>
    </Fragment>
  );
};
