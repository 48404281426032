import React from "react";


export const AppHistory = () => {


  return (
    <div>
      AppHistory
    </div>
  );
}

export default AppHistory;