import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ease from "easy-ease";
import { COLORS } from "../../../constants/colors";
import withLocalContext from "../../store/withLocalContext";
import { getReport } from "../../store/selectors";
import Typography from "@mui/material/Typography";

const getColor = (val) => {
  const parsed = parseFloat(val);
  switch (true) {
    case parsed < 3.5:
      return COLORS.GREEN;
    case parsed >= 3.5 && parsed < 7.0:
      return COLORS.YELLOW;
    case parsed >= 7.0:
    default:
      return COLORS.RED;
  }
};

const BadgeWrapper = styled(Grid)`
  min-height: 77px;
`;

const Rating = styled.div`
  background-color: white;
  color: ${COLORS.BLUE};
  font-size: 80px;
  font-family: Colfax Bold;
  text-align: left;
  margin-right: 10px;
  line-height: 83px;
  min-width: 123px;

  @media (max-width: 600px) {  
    margin: 0;
    justify-content: flex-start;
    font-size: 50px;
    line-height: 63px;
  }
`;

const Risk = styled.div`
  font-size: ${(props) => (props.isSmall ? "34px" : "16px")};
  font-family: Colfax Medium;
  color: ${(props) => getColor(props.rating)};
  align-slef: flex-end;

`;

export const StyledPhrase = styled(Typography)`
  font-style: italic;
  @media (max-width: 600px) {
    text-align: left;
    margin-top: -30px;
    margin-bottom: 30px;
  }
`;

export const RatingBadge = ({ context: { state } }) => {
  const { hailscaleRating = 0 } = getReport(state);
  const [ratingScore, setRatingScore] = useState(0.0);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const getRisk = (val) => {
    const parsed = parseFloat(val);
    switch (true) {
      case parsed < 3.5:
      default:
        return "Low";
      case parsed >= 3.5 && parsed < 7.0:
        return "Moderate";
      case parsed >= 7.0:
        return "High";
    }
  };

  useEffect(() => {
    ease({
      startValue: 1,
      endValue: hailscaleRating,
      durationMs: hailscaleRating * 500,
      onStep: function (value) {
        setRatingScore(value.toFixed(1));
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <BadgeWrapper
      item
      container
      className="text-align-left"
      rating={ratingScore}
      wrap={isMedium ? "nowrap" : "wrap"}
      xs={12}
    >
      <Rating rating={ratingScore}>
        {ratingScore}
      </Rating>
      <Grid item container xs={12}>
        <div className={isSmall === 11 ? "p-t-20 p-b-20 p-l-12 p-r-12" : ""}>
          <Risk isSmall={isSmall} rating={ratingScore}>
            {getRisk(ratingScore)} Risk of Damage
          </Risk>
        </div>
        {isMedium ?
          <Grid item xs={12}>
            <StyledPhrase variant="subtitle1">Rating assumes asphalt roofing system.</StyledPhrase>
          </Grid>
          :
          ""
        }
      </Grid>
    </BadgeWrapper>
  );
};

export default withLocalContext(RatingBadge);
