import React from "react";
import { StyledLabel } from "../../Common/StyledElements";
import RoofAgeSelect from "../../RoofAgeSelect";
import { Grid as Gridsx } from '@mui/material';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const RoofAge = () => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Gridsx
      container
      justifyItems={"flex-start"}
      className={`${isMedium ? "width-350 m-l-13" : "fill-width p-t-20"}`}
      sx={{ ">div": { maxWidth: "100%" } }}
    >
      <StyledLabel>Age of roof<span>*</span></StyledLabel>
      <RoofAgeSelect outline />
    </Gridsx>
  );
};
