import { ROUTES } from "./routes"

export const MENU_ARR = [
  {
    title: "Home",
    link: ROUTES.LANDING_PAGE
  },
  {
    title: "Property Monitoring",
    link: ROUTES.MONITORING
  },
  // {
  //   title: "How It Works",
  //   link: ROUTES.HOW_IT_WORKS
  // },
  {
    title: "Our Certified Roofers",
    link: ROUTES.OUR_INSPECTORS
  },
  {
    title: "Resources & Help",
    link: ROUTES.FAQ
  },
  {
    title: "About Us",
    link: ROUTES.ABOUT_US
  },
]