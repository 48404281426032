import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import React, { Fragment } from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import about_us_hero from "../../assets/about_us_hero.webp";
import { COLORS } from "../../constants/colors";

const StyledCoreTeam = styled(Grid)`
  background-color: ${COLORS.LIGHT_BLUE};
  p {
    max-width: 560px;
  }
  b {
    font-family: Colfax Bold;
  }
  ${(props) => (props.ismedium === "true" ? `
    padding: 20px 62px;
    padding-right: 0;
    ` :
    `
    padding: 20px;
    p {
      display: flex;
      flex-direction: column;
    }
    `
  )}
`;

const StyledHeroImg = styled.img`
  max-width: 100%;
  display: flex;
`;

const StyledContent = styled.div`
  p {
    line-height: 33px !important;
  }
`;

export const AboutUsIntro = () => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  const text =
    <Grid item xs={12} md={isMobile ? 12 : 4} className={`bg-blue text-white ${isMedium ? 'p-t-40 p-b-40 p-l-60 p-r-60' : 'p-a-20'}`}>
      <StyledContent>
        <Grid container >
          <Grid item xs={12}>
            <Typography variant="h2" className="m-b-15">Hailscale’s story</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Hailscale was founded in Minneapolis by a community of homeowners, roofing specialists, and general contractors. This group shared the realization that very few of their neighbors understood how quickly hail damage in Minnesota can deteriorate roofs, windows, and siding. To make matters worse, homeowners had no way of checking their home's personalized hail history and staying informed. Thus, Hailscale was born. Our mission is simple: We exist to help homeowners discover hail damage, and empower them to take action.
            </Typography>
            <Typography variant="body2" className="m-t-15">
              We are happy you found us and we hope you find the tools and resources on this site helpful in caring for your property. To get in touch, or for any questions about what we do, please send us an email at <span className="text-font-fam-colfax-bold">hello@hailscale.com</span>
            </Typography>
            <Grid item xs={12}>
              <Typography variant="h2" className="m-b-15 m-t-35">Our Mission</Typography>
            </Grid>
            <Typography variant="body2" className="m-t-15">
              Empower Homeowners to Discover Damage and Take Action.
            </Typography>
          </Grid>
        </Grid>
      </StyledContent>
    </Grid>
    ;

  const photo =
    <Grid item xs={12} md={isMobile ? 12 : 8} className='bg-blue'>
      <Grid item>
        <StyledHeroImg src={about_us_hero} alt="The Core Team" />
      </Grid>
      <StyledCoreTeam ismedium={String(isMedium)}>
        <Typography variant="h3" className="m-b-15 text-blue">The Core Team</Typography>
        <Typography variant="body2">
          <span><b>Malyssa Mavetz</b> (Lead Designer), </span>
          <span><b>Justin Bedford</b> (Managing Director), </span>
          <span><b>Justus Witmer</b> (Senior Software Engineer), </span>
          <span><b>Luke Lorence</b> (Director of Sales)</span>
        </Typography>
      </StyledCoreTeam>
    </Grid>
    ;

  return (
    <Grid container className="text-align-left">
      {!isMobile ?
        <Fragment>
          {text}
          {photo}
        </Fragment>
        :
        <Fragment>
          {photo}
          {text}
        </Fragment>
      }
    </Grid>
  );
}

export default AboutUsIntro;