import { Grid } from "@material-ui/core";
import { Grid as Gridsx } from '@mui/material';
import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import SingleStat from "./SingleStat";

import Rate_Black from "../../../assets/Rate_Black.webp";
import Clock_Black from "../../../assets/Clock_Black.webp";
import Measure_Black from "../../../assets/Measure_Black.webp";
import dayjs from "dayjs";
import withLocalContext from "../../store/withLocalContext";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { OurCertifiedRoofersToolTip } from "../../Tooltips/Tooltips";

const smDsktpBP = 1366;

const makeBreakPoint = (bp) => `@media (max-width: ${bp}px)`;

const StyledStatsContainer = styled(Grid)`
  height: 150px;
  ${props => props.ismedium === "true" ? `` : `
    height: auto !important;
  `}

  ${makeBreakPoint(smDsktpBP)} {
    height: ${props => props.hasstats === "true" ? "300px" : "150px"};
  }

  #stats-container {
    display: flex;
    flex-direction: row;
    width: ${props => props.hasstats === "true" ? "100%" : 0};
    max-width: 630px;
    min-width: 0;
    overflow: hidden;
    gap: 14px;
    margin-right: ${props => props.hasstats === "true" ? "21px" : 0};
    ${props => props.ismedium === "true" ? `` : `
    margin: 0 -22px;
    max-width: 100vw;
    gap: 8px;
    overflow: visible;
    `}
    ${makeBreakPoint(smDsktpBP)} {
      width: calc(100% + 24px);
      max-width: 100%;
      order: 2;
    }
  }

  #steps-container {
    margin-right: -30px;
    min-width: 0;
    width: calc(100% + 21px);
    overflow: hidden;
    ${props => props.hasstats === "true" ? `max-width: calc(100% - 630px);` : ``}
    height: 125px;
    ${makeBreakPoint(smDsktpBP)} {
      width: 100%;
      margin-bottom: 30px;
      height: auto;
      padding: 0;
      ${props => props.hasstats === "true" ? `
      max-width: 100%; 
      `: ``}
    }
    
    ol {
      padding-left: 50px;
      line-height: 30px;
      ${makeBreakPoint(smDsktpBP)} {
        padding: 0 10px 0 30px;
        li {
          margin-bottom: 10px;
        }
      }
    }
  }
`;

const Stats = ({ context: { state } }) => {
  const [hasStats, setHasStats] = useState(false);
  const [stats, setStats] = useState();
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {

    if (Object.keys(state.report).length > 0) {
      const { cumulateHailDurationOverRoofAge,
        hailscaleRating,
        largestHailEvent: {
          hailSize,
          localStormDate
        }
      } = state.report;
      setStats({
        cumulateHailDurationOverRoofAge: cumulateHailDurationOverRoofAge,
        hailscaleRating,
        hailSize,
        localStormDate
      })
      setHasStats(true);
    }
  }, [state.report])

  return (
    <Fragment>
      <StyledStatsContainer ismedium={String(isMedium)} container item xs={12} alignItems="center" hasstats={String(hasStats)}>
        <Gridsx id="stats-container" >
          {hasStats &&
            <Fragment>
              <SingleStat icon={Rate_Black} text={"Rating for hail damage"} stat={stats.hailscaleRating} />
              <SingleStat icon={Clock_Black} text={<span>Minutes of total hail</span>} stat={stats.cumulateHailDurationOverRoofAge} />
              <SingleStat icon={Measure_Black} text={`${stats.hailSize} inch hail on ${dayjs(stats.localStormDate).format("MMMM DD")}`} stat={stats.hailSize} />
            </Fragment>
          }
        </Gridsx>
        <Gridsx id="steps-container" className="bg-light-grey text-align-left" >
          <ol>
            <li>Select a date and time.</li>
            <li>If your property has damage, <OurCertifiedRoofersToolTip /> will find it.</li>
            <li>There is no obligation to file a claim, but we can advise if one is needed.</li>
          </ol>
        </Gridsx>
      </StyledStatsContainer>
    </Fragment >
  )
}
export default withLocalContext(Stats);