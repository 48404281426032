/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import Grid from "@material-ui/core/Grid";
import { Grid as Gridsx } from '@mui/material';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import withLocalContext from "../store/withLocalContext";
import { addRatingParams } from "../store/actions";
import { PageView } from "../Tracking";
import TopSection from "./TopSection";
import HowItWorks from "./HowItWorks";
import FAQ from "./FAQ";
import { ROUTES } from "../../constants/routes";
import Typography from "@mui/material/Typography";
import { COLORS } from "../../constants/colors";
import CalendarPicker from "../Common/CalendarPicker";
import HomeOwnerReview from "./HomeOwnerReview";
import OurRoofers from "./OurRoofers";

export const LandingPage = ({ context: { dispatch } }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    dispatch(addRatingParams({ roofAge: '' }));
    PageView();
  }, []);


  const HomeOwnerBanner = () => {
    return (
      <Gridsx container sx={{ background: `linear-gradient(180deg, ${COLORS.LIGHT_BLUE} 14.06%, #E5ECF4 100%)`, marginBottom: "-1px" }} alignItems={"center"} justifyContent={"center"}>
        <Typography variant="h3" sx={{ fontSize: "20px", fontFamily: "Colfax Regular !important", padding: "20px 10px" }}>Helping homeowners restore roofs, windows, and siding from hail storms</Typography>
      </Gridsx>
    )
  }

  const ScheduleBanner = () => {
    return (
      <Gridsx container item xs={12} sx={{ background: `linear-gradient(0deg, #BECEE1 0%, ${COLORS.GREY} 100%);`, marginTop: "-1px", paddingBottom: "20px" }} alignContent={"center"} justifyContent={"center"}>
        <Gridsx container flexDirection={"column"} alignItems={"center"}>
          <Typography variant="h3" sx={{ fontSize: "22px", padding: "20px 10px" }}>View roof inspection availability</Typography>
          <Gridsx sx={{ position: "relative", maxWidth: "200px" }}>
            <CalendarPicker />
          </Gridsx>
        </Gridsx>
      </Gridsx>
    )
  }

  return (
    <Fragment>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>Discover Hail Damage and Take Action</title>
        <link rel="canonical" href={`https://www.hailscale.com${ROUTES.LANDING_PAGE}`} />
        <meta name="description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Discover Hail Damage and Take Action" />
        <meta itemprop="description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta itemprop="image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.hailscale.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Discover Hail Damage and Take Action" />
        <meta property="og:description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta property="og:image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Discover Hail Damage and Take Action" />
        <meta name="twitter:description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta name="twitter:image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Meta Tags Generated via http://heymeta.com --> */}
      </Helmet>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <TopSection />
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          xs={12}
        // className={`bg-blue ${isMedium ? "p-t-45" : "p-t-24"}`}
        >
          {!isMedium &&
            <Grid
              item
              container
              justifyContent="center"
              xs={12}
              className="sticky-header"
            >
              <HomeOwnerBanner />
            </Grid>
          }
          <Grid
            container
            justifyContent="center"
            item
            xs={12}
            className="p-relative"
          // className="p-l-30 p-r-30"
          >
            <HowItWorks />
          </Grid>
          {!isMedium &&
            <Grid
              item
              container
              justifyContent="center"
              xs={12}
              className="sticky-header"
            >
              <ScheduleBanner />
            </Grid>
          }
          {
            isMedium &&
            <Grid
              item
              container
              justifyContent="center"
              xs={12}
            >
              <HomeOwnerReview />
            </Grid>
          }
          <Grid
            item
            container
            justifyContent="center"
            xs={12}
          >
            <OurRoofers />
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            style={{ backgroundColor: "#ffffff" }}
          >
            <FAQ />
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default withLocalContext(LandingPage);
