import React from "react";
import { useAdminAppointment } from "../Provider";
import { AdminInputAndTitle } from "../../Common/AdminInputAndTitle";

export const Name = ({ canEdit, value }) => {
  const { state, addName } = useAdminAppointment();
  // useEffect(() => {
  // }, [value, addName]);
  // addName(value);

  return (
    <AdminInputAndTitle
      isDisabled={!canEdit}
      classNames="font-26-regular-black uppercase m-b-20"
      value={!canEdit ? value : state.name ? state.name : value}
      onChange={(val) => addName(val.target.value)}
    />
  );
};
export default Name;