import React, { createContext, useContext, useReducer } from "react";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
// import { TIME_SLOTS, SHINGLE_TYPES } from "./Inputs";

export const TYPES = {
  ADD_FULL_NAME: "addFullName",
  ADD_PHONE_NUMBER: "addPhoneNumber",
  ADD_EMAIL: "addEmail",
  ADD_ADDRESS: "addAddress",
  ADD_APPOINTMENT_TIME: "addTimeSlot",
  ADD_APPOINTMENT_DATE: "addApptDate",
  ADD_INSURANCE_PROVIDER: "addInsuranceProvider",
  // ADD_PROPERTY_MANAGER: "addPropertyManager",
  ADD_SHINGLE_TYPE: "addShingleType",
  ADD_REFERRAL_CODE: "addReferralCode",
  ADD_CERTIFY_OWNER: "addCertifyOwner",
  ADD_ROOF_AGE: "addRoofAge"
};
const {
  ADD_FULL_NAME,
  ADD_PHONE_NUMBER,
  ADD_EMAIL,
  ADD_ADDRESS,
  ADD_APPOINTMENT_TIME,
  ADD_APPOINTMENT_DATE,
  ADD_INSURANCE_PROVIDER,
  // ADD_PROPERTY_MANAGER,
  ADD_REFERRAL_CODE,
  ADD_SHINGLE_TYPE,
  ADD_CERTIFY_OWNER,
  ADD_ROOF_AGE
} = TYPES;

const AppointmentContext = createContext();

const appointmentReducer = (state, action) => {
  switch (action.type) {
    case ADD_FULL_NAME:
      return { ...state, fullName: action.payload };
    case ADD_PHONE_NUMBER:
      return { ...state, phoneNumber: action.payload };
    case ADD_EMAIL:
      return { ...state, email: action.payload };
    case ADD_ADDRESS:
      return { ...state, address: action.payload };
    case ADD_APPOINTMENT_TIME:
      return { ...state, timeSlot: action.payload };
    case ADD_APPOINTMENT_DATE:
      return { ...state, apptDate: action.payload };
    case ADD_INSURANCE_PROVIDER:
      return { ...state, insurance: action.payload };
    // case ADD_PROPERTY_MANAGER:
    //   return { ...state, propertyManager: action.payload };
    case ADD_SHINGLE_TYPE:
      return { ...state, shingleType: action.payload };
    case ADD_REFERRAL_CODE:
      return { ...state, referralCode: action.payload };
    case ADD_CERTIFY_OWNER:
      return { ...state, certifyOwner: action.payload };
    case ADD_ROOF_AGE:
      return { ...state, roofAge: action.payload };
    default:
      return { ...state };
  }
};

const AppointmentProvider = ({ children }) => {
  const location = useLocation();
  let date = dayjs().add(2, "d").format("YYYY-MM-DD");
  let timeSlot = "";

  if (location.state) {
    date = dayjs(location.state.date.$d).format("YYYY-MM-DD");
    if (location.state.timeSlot) {
      timeSlot = location.state.timeSlot;
    }
  }

  const [state, dispatch] = useReducer(appointmentReducer, {
    fullName: "",
    email: "",
    address: "",
    phoneNumber: "",
    apptDate: date,
    timeSlot: timeSlot,
    shingleType: "",
    insurance: "",
    referralCode: "",
    // propertyManager: "",
    certifyOwner: false,
    roofAge: ""
  });

  const value = { state, dispatch };
  return (
    <AppointmentContext.Provider value={value}>
      {children}
    </AppointmentContext.Provider>
  );
};

const useAppointment = () => {
  const context = useContext(AppointmentContext);

  const { state, dispatch } = context;

  const addFullName = (payload) => dispatch({ type: ADD_FULL_NAME, payload });
  const addPhoneNumber = (payload) =>
    dispatch({ type: ADD_PHONE_NUMBER, payload });
  const addEmail = (payload) => dispatch({ type: ADD_EMAIL, payload });
  const addAddress = (payload) => dispatch({ type: ADD_ADDRESS, payload });
  const addApptDate = (payload) =>
    dispatch({ type: ADD_APPOINTMENT_DATE, payload });
  const addTimeSlot = (payload) =>
    dispatch({ type: ADD_APPOINTMENT_TIME, payload });
  const addInsuranceProvider = (payload) =>
    dispatch({ type: ADD_INSURANCE_PROVIDER, payload });
  // const addPropertyManager = (payload) =>
  //   dispatch({ type: ADD_PROPERTY_MANAGER, payload });
  const addReferralCode = (payload) =>
    dispatch({ type: ADD_REFERRAL_CODE, payload });
  const addShingleType = (payload) =>
    dispatch({ type: ADD_SHINGLE_TYPE, payload });
  const addCertifyOwner = (payload) =>
    dispatch({ type: ADD_CERTIFY_OWNER, payload });
  const addRoofAge = (payload) =>
    dispatch({ type: ADD_ROOF_AGE, payload });
  return {
    state,
    addFullName,
    addPhoneNumber,
    addEmail,
    addAddress,
    addApptDate,
    addTimeSlot,
    addInsuranceProvider,
    // addPropertyManager,
    addReferralCode,
    addShingleType,
    addCertifyOwner,
    addRoofAge
  };
};

export { useAppointment, AppointmentProvider };
