import React, { createContext, useContext, useReducer } from "react";
import moment from "moment";

export const TYPES = {
  ADD_ID: "addId",
  ADD_FULL_NAME: "addFullName",
  ADD_SUBMITTED_BY: "addSubmittedBy",
  ADD_SHINGLE_TYPE: "addShingleType",
  ADD_DMG_AMOUNT: "addDmgAmount",
  RECOMMEND_FILING_CLAIM: "addRecommendFilingClaim",
  ADD_CLAIM_LIKELIHOOD: "addClaimLikelihood",
  ADD_ADDRESS: "addAddress",
  ADD_HAIL_COUNT: "addTotalHailCountOverRoofAge",
  ADD_HAIL_DURATION: "addTotalHailDurationOverRoofAge",
  ADD_RATING: "addHscRating",
  ADD_ROOF_AGE: "addRoofAge",
  IS_HSC_LOADING: "isHSCLoading",
  IS_INSPECTION_SUBMITTING: "isInspectionSubmitting",
  ADD_GEOLOCATION: "addGeolocation",
  ADD_FILING_STATUS: "addFilingStatus",
  ADD_INSPECTION_DATE: "addInspectionDate",
  ADD_HAIL_LOCATIONS: "addHailLocations",
  ADD_INSURANCE_PROVIDER: "addInsuranceProvider",
  ADD_ROOF_PITCH: "addRoofPitch",
  ADD_SIDING_TYPE: "addSidingType",
  ADD_ADDITIONAL_COMMENTS: "addAdditionalComments",
  ADD_ACCESS_HIGHEST_POINT: "addAccessHighestPoint",
  ADD_UPLOAD_PHOTOS: "addUploadPhotos",
  ADD_RETAIL_BIDS: "addRetailBids",
  ADD_PROPERTY_MANAGER: "addPropertyManager",
  PREPOPULATE: "prepopulate",
  RESET_FORM: "resetForm",
};
const {
  ADD_ID,
  ADD_FULL_NAME,
  ADD_SUBMITTED_BY,
  ADD_SHINGLE_TYPE,
  ADD_DMG_AMOUNT,
  ADD_CLAIM_LIKELIHOOD,
  ADD_RECOMMEND_FILING_CLAIM,
  ADD_ADDRESS,
  ADD_HAIL_COUNT,
  ADD_HAIL_DURATION,
  ADD_RATING,
  ADD_ROOF_AGE,
  IS_HSC_LOADING,
  IS_INSPECTION_SUBMITTING,
  ADD_GEOLOCATION,
  ADD_FILING_STATUS,
  ADD_INSPECTION_DATE,
  ADD_HAIL_LOCATIONS,
  ADD_INSURANCE_PROVIDER,
  ADD_ROOF_PITCH,
  ADD_SIDING_TYPE,
  ADD_ADDITIONAL_COMMENTS,
  ADD_ACCESS_HIGHEST_POINT,
  ADD_UPLOAD_PHOTOS,
  ADD_RETAIL_BIDS,
  ADD_PROPERTY_MANAGER,
  PREPOPULATE,
  RESET_FORM,
} = TYPES;

const InspectionContext = createContext();

const inspectionReducer = (state, action) => {
  switch (action.type) {
    case ADD_ID:
      return { ...state, _id: action.payload };
    case ADD_FULL_NAME:
      return { ...state, fullName: action.payload };
    case ADD_SUBMITTED_BY:
      return { ...state, submittedBy: action.payload };
    case ADD_SHINGLE_TYPE:
      return { ...state, shingleType: action.payload };
    case ADD_DMG_AMOUNT:
      return { ...state, dmgAmount: action.payload };
    case ADD_RECOMMEND_FILING_CLAIM:
      return { ...state, recommendFilingClaim: action.payload };
    case ADD_CLAIM_LIKELIHOOD:
      return { ...state, approvalLikelihood: action.payload };
    case ADD_ROOF_AGE:
      return { ...state, roofAge: action.payload };
    case ADD_ADDRESS:
      return { ...state, address: action.payload };
    case ADD_HAIL_COUNT:
      return { ...state, totalHailCountOverRoofAge: action.payload };
    case ADD_HAIL_DURATION:
      return { ...state, totalHailDurationOverRoofAge: action.payload };
    case ADD_RATING:
      return { ...state, hscRating: action.payload };
    case ADD_GEOLOCATION:
      return { ...state, geoLocation: action.payload };
    case ADD_FILING_STATUS:
      return { ...state, filingStatus: action.payload };
    case ADD_INSPECTION_DATE:
      return { ...state, inspectionDate: action.payload };
    case IS_HSC_LOADING:
      return { ...state, isHSCLoading: action.payload };
    case IS_INSPECTION_SUBMITTING:
      return { ...state, isInspectionSubmitting: action.payload };
    case ADD_HAIL_LOCATIONS:
      return { ...state, hailLocations: action.payload };
    case ADD_INSURANCE_PROVIDER:
      return { ...state, insuranceProvider: action.payload };
    case ADD_ROOF_PITCH:
      return { ...state, roofPitch: action.payload };
    case ADD_SIDING_TYPE:
      return { ...state, sidingType: action.payload };
    case ADD_ADDITIONAL_COMMENTS:
      return { ...state, additionalComments: action.payload };
    case ADD_ACCESS_HIGHEST_POINT:
      return { ...state, accessHighestPoint: action.payload };
    case ADD_UPLOAD_PHOTOS:
      return { ...state, images: action.payload };
    case ADD_RETAIL_BIDS:
      return { ...state, retailBids: action.payload };
    case ADD_PROPERTY_MANAGER:
      return { ...state, propertyManager: action.payload };
    case PREPOPULATE:
      return { ...action.payload };
    case RESET_FORM:
      return { ...action.payload };
    default:
      return { ...state };
  }
};
export const INITIAL_STATE = {
  _id: "",
  fullName: "",
  address: "",
  totalHailCountOverRoofAge: 0,
  totalHailDurationOverRoofAge: 0,
  hscRating: 0,
  submittedBy: "",
  shingleType: "",
  dmgAmount: "",
  recommendFilingClaim: "",
  approvalLikelihood: "",
  filingStatus: "",
  inspectionDate: moment().format("YYYY-MM-DD"),
  hailLocations: [],
  insuranceProvider: "",
  roofPitch: "",
  sidingType: "",
  additionalComments: "",
  accessHighestPoint: "",
  images: [],
  retailBids: "",
  propertyManager: "",
  isHSCLoading: false,
};

const InspectionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(inspectionReducer, INITIAL_STATE);

  const value = { state, dispatch };
  return (
    <InspectionContext.Provider value={value}>
      {children}
    </InspectionContext.Provider>
  );
};

const useInspection = () => {
  const context = useContext(InspectionContext);
  const { state, dispatch } = context;

  const addId = (payload) =>
    dispatch({ type: ADD_ID, payload });
  const addFullName = (payload) =>
    dispatch({ type: ADD_FULL_NAME, payload });
  const addContractor = (payload) =>
    dispatch({ type: ADD_SUBMITTED_BY, payload });
  const addShingleType = (payload) =>
    dispatch({ type: ADD_SHINGLE_TYPE, payload });
  const addDmgAmount = (payload) =>
    dispatch({ type: ADD_DMG_AMOUNT, payload });
  const addRecommendFilingClaim = (payload) => {
    if (payload === "No") {
      dispatch({ type: ADD_FILING_STATUS, payload: "Declined to file" });
    }
    dispatch({ type: ADD_RECOMMEND_FILING_CLAIM, payload });
  }
  const addLikelihood = (payload) =>
    dispatch({ type: ADD_CLAIM_LIKELIHOOD, payload });
  const addAddress = (payload) =>
    dispatch({ type: ADD_ADDRESS, payload });
  const addTotalHailCountOverRoofAge = (payload) =>
    dispatch({ type: ADD_HAIL_COUNT, payload });
  const addTotalHailDurationOverRoofAge = (payload) =>
    dispatch({ type: ADD_HAIL_DURATION, payload });
  const addHscRating = (payload) =>
    dispatch({ type: ADD_RATING, payload });
  const addGeoLocation = (payload) =>
    dispatch({ type: ADD_GEOLOCATION, payload });
  const addRoofAge = (payload) =>
    dispatch({ type: ADD_ROOF_AGE, payload });
  const addHSCLoading = (payload) =>
    dispatch({ type: IS_HSC_LOADING, payload });
  const addIsInspectionSubmitting = (payload) =>
    dispatch({ type: IS_INSPECTION_SUBMITTING, payload });
  const addFilingStatus = (payload) =>
    dispatch({ type: ADD_FILING_STATUS, payload });
  const addInspectionDate = (payload) =>
    dispatch({ type: ADD_INSPECTION_DATE, payload });
  const addHailLocations = (payload) =>
    dispatch({ type: ADD_HAIL_LOCATIONS, payload });
  const addInsuranceProvider = (payload) =>
    dispatch({ type: ADD_INSURANCE_PROVIDER, payload });
  const addRoofPitch = (payload) =>
    dispatch({ type: ADD_ROOF_PITCH, payload });
  const addSidingType = (payload) =>
    dispatch({ type: ADD_SIDING_TYPE, payload });
  const addAdditionalComments = (payload) =>
    dispatch({ type: ADD_ADDITIONAL_COMMENTS, payload });
  const addAccessHighestPoint = (payload) =>
    dispatch({ type: ADD_ACCESS_HIGHEST_POINT, payload });
  const addUploadPhotos = (payload) =>
    dispatch({ type: ADD_UPLOAD_PHOTOS, payload });
  const addRetailBids = (payload) =>
    dispatch({ type: ADD_RETAIL_BIDS, payload });
  const addPropertyManager = (payload) =>
    dispatch({ type: ADD_PROPERTY_MANAGER, payload });
  const prepopulate = (payload) =>
    dispatch({ type: PREPOPULATE, payload });
  const resetForm = () =>
    dispatch({ type: RESET_FORM, payload: INITIAL_STATE });

  return {
    state,
    addId,
    addFullName,
    addContractor,
    addShingleType,
    addDmgAmount,
    addRecommendFilingClaim,
    addLikelihood,
    addAddress,
    addTotalHailCountOverRoofAge,
    addTotalHailDurationOverRoofAge,
    addHscRating,
    addRoofAge,
    addHSCLoading,
    addIsInspectionSubmitting,
    addGeoLocation,
    addFilingStatus,
    addInspectionDate,
    addHailLocations,
    addInsuranceProvider,
    addRoofPitch,
    addSidingType,
    addAdditionalComments,
    addAccessHighestPoint,
    addUploadPhotos,
    addRetailBids,
    addPropertyManager,
    prepopulate,
    resetForm
  };
};

export { useInspection, InspectionProvider };
