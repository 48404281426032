import React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import withAdminContext from '../../store/withAdminContext';
import { COLORS } from '../../../../constants/colors';
import styled from 'styled-components';
import downArrow from "../../assets/downArrow.svg"

const TIMES = [
  "8:00am",
  "9:00am",
  "10:00am",
  "11:00am",
  "12:00pm",
  "1:00pm",
  "2:00pm",
  "3:00pm",
  "4:00pm",
  "5:00pm",
  "6:00pm",
  "7:00pm",
  "8:00pm"
]

const StyledSelect = styled(Select)`
  ~ ul[role=listbox] {
    width: 100px !important;
  }
  
  color: ${COLORS.BLACK} !important;
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: baseline;
  gap: 5px;
  padding-left: 10px;
  ${(props) => (props.isdisabled === "false" && `
    border: 1px solid ${COLORS.BLUE};
    border-radius: 5px;
  `)}
`;

const AdminSelect = ({ isDisabled, startTime, endTime, onChangeStart, onChangeEnd, label, alignItems }) => {
  return (
    <Grid2 sx={{ display: alignItems ? "flex" : "block" }} alignItems={alignItems ? "center" : ""}>
      {label && <span className={"font-size-16 text-font-fam-colfax-medium uppercase p-l-10"}>{label}</span>}
      <StyledDiv isdisabled={String(isDisabled)}>
        <StyledSelect
          disabled={isDisabled}
          defaultValue={startTime === "" ? TIMES[0] : startTime}
          indicator={isDisabled ? "" : <img alt="" src={downArrow} />}
          onChange={onChangeStart}
          key={startTime}
          sx={{
            padding: 0,
            width: 'fit-content',
            border: 'none',
            [`& .${selectClasses.indicator}`]: {
              transition: '0.2s',
              color: COLORS.BLUE,
              "& img": {
                width: 15
              },
              [`&.${selectClasses.expanded}`]: {
                transform: 'rotate(-180deg)'
              },
            }
          }}
        >
          {TIMES.map(time => {
            return <Option
              sx={{
                width: 100
              }}
              key={`1${time}`}
              value={time}>
              {time}
            </Option>
          })}
        </StyledSelect>
        <span> - </span>
        <StyledSelect
          disabled={isDisabled}
          defaultValue={endTime === "" ? TIMES[TIMES.length - 1] : endTime}
          indicator={isDisabled ? "" : <img alt="" src={downArrow} />}
          onChange={onChangeEnd}
          key={endTime}
          sx={{
            padding: 0,
            // minWidth: 90,
            width: 'fit-content',
            border: 'none',
            [`& .${selectClasses.indicator}`]: {
              transition: '0.2s',
              color: COLORS.BLUE,
              "& img": {
                width: 15
              },
              [`&.${selectClasses.expanded}`]: {
                transform: 'rotate(-180deg)'
              },
            }
          }}
        >
          {TIMES.map(time => {
            return <Option
              sx={{
                width: 100
              }}
              key={`2${time}`}
              value={time}>
              {time}
            </Option>
          })}
        </StyledSelect>
      </StyledDiv>
    </Grid2>
  );
}

export default withAdminContext(AdminSelect);