import React from "react";
import { Grid } from "@material-ui/core";
import { Button, Typography } from "@mui/material";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const StyledParagraph = styled(Typography)`
  font-size: 20px !important;
  line-height: 35px !important;
  padding: 21px 10px;
  max-width: 410px;
  @media (max-width: 600px) {
    font-size: 16px !important;
    line-height: 22px !important;
  }
`;

const StyledTitle = styled(Typography)`
  max-width: 420px;
  margin-bottom: 12px;
`;

const IPDeniedModalContent = ({ closeModal }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  const paragraph = `Hailscale allows users to check 8 roofs a day. Come back tomorrow to check again!`;
  const paragraphTwo = <p>We’re glad you're enjoying Hailscale, if you’d like to upgrade to be able to check more than 8 addresses a day, please call us at <span className="text-font-fam-colfax-bold ">612-424-5420</span></p>;
  return (
    <Grid container justifyContent="center" className={`${isMedium ? "p-b-24" : ""} text-align-center`}>
      <StyledTitle variant="h2">You’ve reached your daily address limit.</StyledTitle>
      <StyledParagraph>{paragraph}</StyledParagraph>
      <Grid item container xs={12} justifyContent="center">
        <Button
          onClick={() => closeModal()}
        >
          Back to home
        </Button>
      </Grid>
      <Grid item container justifyContent="center" xs={12}>
        <StyledParagraph style={{ maxWidth: 450 }}>{paragraphTwo}</StyledParagraph>
      </Grid>
    </Grid >
  );
}

export default IPDeniedModalContent;