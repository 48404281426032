import React from "react";
import withAdminContext from "../../../store/withAdminContext";
import { STAGES } from "../../../../../constants/appointment_stages";
import CardWrapper from "../../Card/CardWrapper";
import PartnerNeededApp from "./PartnerNeededApp";

export const APartnerNeeded = ({ context: { state } }) => {

  return (
    <div>
      {state.activeAppointments.partnerNeeded.appointments.map(app => {
        return app.appointmentStage === STAGES.PARTNER_NEEDED &&
          <CardWrapper key={app._id} stage={app.appointmentStage} child={<PartnerNeededApp app={app} />} />
      })}
    </div>
  );
}
export default withAdminContext(APartnerNeeded);