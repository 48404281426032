/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import { ROUTES } from "../../constants/routes";
import withLocalContext from "../store/withLocalContext";
import { getReport } from "../store/selectors";
import { PageView } from "../Tracking";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styled from "styled-components";
import { COLORS } from "../../constants/colors";

// Page Components
import HailDetail from "./HailDetail";
import HailSummary from "./HailSummary";
import NextSteps from "./NextSteps";
import RatingMeter from "./RatingMeter";
import MapAddress from "./MapAddress";
import NotReady from "./NotReady";
import Share from "./Share";
import { useLocation, useNavigate } from "react-router-dom";
import { GOOGLE_GEOCODE_URL } from "../../constants/apis";
import { addRatingParams } from "../store/actions";

const StyledCon = styled(Grid)`
  border: 3px solid ${COLORS.TAN};
  border-radius: 10px;
  padding: 40px 60px 25px;
  @media (max-width: 960px) {
    padding: 30px 35px;
  }
`;

export const Report = ({ context: { state, dispatch } }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const hasReport = Object.keys(getReport(state)).length > 0;

  const { hailscaleRating, roofAge, geoLocation } = getReport(state);
  const [inspect, setInspect] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setInspect(hailscaleRating > 7.0);
  }, [setInspect, hailscaleRating]);

  useEffect(() => {
    PageView();
    const makeHash = (ageOfRoof, addLat, addLng) => `${Number(ageOfRoof).toLocaleString('en-US', { minimumIntegerDigits: 2 })}+${addLat}+${addLng}`;
    if (!hasReport) {
      if (location.hash) {
        const getAddressDeets = async () => {
          const splitHash = location.hash.replace("#", "").split("+");

          const url = `${GOOGLE_GEOCODE_URL}?latlng=${splitHash[1]},${splitHash[2]}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
          const response = await axios.get(url);

          if (response.data.results) {
            const zip = response.data.results[0].address_components.filter(item => item.types.includes("postal_code"))[0].short_name;
            const streetNum = response.data.results[0].address_components.filter(item => item.types.includes("street_number"))[0].long_name;
            const route = response.data.results[0].address_components.filter(item => item.types.includes("route"))[0].long_name;
            const shortAdd = `${streetNum} ${route}`;

            const addPayload = {
              roofAge: Number(splitHash[0]),
              address: {
                address: response.data.results[0].formatted_address,
                zipCode: zip,
                shortAddress: shortAdd,
                place_id: response.data.results[0].place_id,
                geoLocation: response.data.results[0].geometry.location
              }
            }
            dispatch(addRatingParams(addPayload));
            navigate(ROUTES.LOADING_REPORT);
          }
        }
        getAddressDeets();
      }

      navigate(ROUTES.LANDING_PAGE);
    } else {
      window.location.hash = makeHash(roofAge, geoLocation.lat, geoLocation.lng);
    }
  }, []);

  if (!hasReport) {
    return null;
  }


  return (
    <div className="bg-white">
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>Report Page</title>
        <link rel="canonical" href={`https://www.hailscale.com${ROUTES.REPORT}`} />
        <meta name="description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Report Page" />
        <meta itemprop="description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta itemprop="image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.hailscale.com" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Report Page" />
        <meta property="og:description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta property="og:image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Report Page" />
        <meta name="twitter:description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta name="twitter:image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Meta Tags Generated via http://heymeta.com --> */}
      </Helmet>
      <Grid
        container
        justifyContent="center"
        xs={12}
        className={`p-t-30`}
      >
        <Grid
          item
          container
          xs={12}
          style={{
            paddingRight: isMedium ? "" : "inherit",
            paddingLeft: isMedium ? "8px" : "inherit",
          }}
        >
          <Grid item xs={12} md={5}>
            <RatingMeter />
          </Grid>
          <Grid item xs={12} md={7}>
            <HailSummary />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <NextSteps />
        </Grid>
        <Grid
          item
          container
          xs={12}
          style={{ marginTop: !isMedium ? 16 : 55 }}
        >
          <Grid item xs={12} md={7}>
            <HailDetail />
          </Grid>
          <Grid item xs={12} md={5}>
            <MapAddress />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid container className={`${isMedium ? "p-l-95 p-r-95 m-t-85 m-b-85" : "p-l-30 p-r-30 m-t-40 m-b-40"}`}>
            <StyledCon container item xs={12}>
              {inspect ?
                <NotReady />
                :
                <Share />}
            </StyledCon>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withLocalContext(Report);
