import React from "react";
import withAdminContext from "../../../store/withAdminContext";
import CardWrapper from "../../Card/CardWrapper";
import MissingInspectionApp from "./MissingInspectionApp";

export const CMissingInspection = ({ context: { state } }) => {
  return (
    <div>
      {state.activeAppointments.inspectionOverdue.appointments.map(app => {
        return <CardWrapper key={app._id} stage={app.appointmentStage} child={<MissingInspectionApp app={app} />} />
      })}
    </div>
  );
}

export default withAdminContext(CMissingInspection);