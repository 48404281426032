import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import dayjs from "dayjs";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import withLocalContext from "../../store/withLocalContext";
import { getReport } from "../../store/selectors";
import ease from "easy-ease";
import Clock_Blue from "../../../assets/Clock_Blue.webp";
import Storm_Blue from "../../../assets/Storm_Blue.webp";
import Measure_Blue from "../../../assets/Measure_Blue.webp";
import Stat from "./Stat";

const StyledHistoryContainer = styled(Grid)`
margin-right: -8px;
margin-left: 0;
@media (max-width: 960px) {
  margin: 0;
  padding: 0 16px;
  }
`;

const StyledHistoryItem = styled(Grid)`
  text-align: left;
  margin-bottom: 42px;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 959px) {
    margin-bottom: 20px;
  }
`;

const StyledTitle = styled(Typography)`
font-size: 34px !important;
text-align: center !important;
@media (max-width: 959px) {
  font-size: 17px !important;
}
`;

export const HailSummary = ({ context: { state } }) => {
  const [hailCount, setHailCount] = useState(0);
  const [hailDuration, setHailDuration] = useState(0);
  const [hailSize, setHailSize] = useState(0);

  const {
    cumulativeHailCountOverRoofAge,
    cumulateHailDurationOverRoofAge,
    roofAge,
    shortAddress,
    hailscaleRating,
    largestHailEvent,
    largestHailEvent: { hailSize: hail_size }
  } = getReport(state);

  useEffect(() => {
    ease({
      startValue: 1,
      endValue: cumulativeHailCountOverRoofAge,
      durationMs: (hailscaleRating * 500) + 500,
      onStep: function (value) {
        setHailCount(value.toFixed(0));
      },
    });
    ease({
      startValue: 1,
      endValue: cumulateHailDurationOverRoofAge,
      durationMs: (hailscaleRating * 500) + 1000,
      onStep: function (value) {
        setHailDuration(value.toFixed(0));
      },
    });

    let tempSize = Number(hail_size.replace("<", ""));

    ease({
      startValue: 0,
      endValue: tempSize,
      durationMs: (hailscaleRating * 500) + 1500,
      onStep: function (value) {
        setHailSize(value.toFixed(2));
      },
    });
  }, [cumulateHailDurationOverRoofAge, hailscaleRating, cumulativeHailCountOverRoofAge, hail_size]);

  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <StyledHistoryContainer
      container
    >
      <Grid
        item
        container
        className={`${isMedium ? "p-t-45 p-b-45 p-r-50" : "p-t-20 p-b-20"}`}
      >
        <StyledHistoryItem
          container
          item
          wrap="nowrap"
        >
          <Grid item xs={12} className={`text-align-center m-b-16`}>
            <StyledTitle variant="" sx={{ fontFamily: "Colfax Regular !important" }} className="">
              Since {dayjs().subtract(roofAge, "y").year()} at: <span className="text-blue text-font-fam-colfax-medium">{shortAddress}</span>
            </StyledTitle>
          </Grid>
        </StyledHistoryItem>
        <Grid item container xs={12} gap={isMedium ? "20px" : "9px"} justifyContent={"center"}>
          <Stat
            icon={Storm_Blue}
            stat={hailCount}
            text={['Hail storms - 1/2”', <br />, 'hail or greater']}
          />
          <Stat
            icon={Clock_Blue}
            stat={hailDuration}
            text={['Minutes of', <br />, 'total hail']}
          />
          <Stat
            icon={Measure_Blue}
            stat={(hail_size.includes("<") ? ">0.5" : hailSize)}
            text={['Inch hail on', <br />, dayjs(largestHailEvent.localStormDate).format("MMMM DD")]}
            additionalSX={{ "img": { maxWidth: "100px", objectFit: "contain", height: "77px", objectPosition: "bottom" } }}
          />
        </Grid>
      </Grid>
    </StyledHistoryContainer>
  );
};

export default withLocalContext(HailSummary);