import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@mui/material/Checkbox";
import { useAppointment } from "../Provider";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styled from "styled-components";
import check from "../../../assets/check-solid.svg";

const StyledCheckbox = styled(Checkbox)`
&.MuiCheckbox-root > .MuiIconButton-label > .MuiSvgIcon-root {
    color: transparent;
  }
> svg {
  color: transparent;
}
`;

const Image = styled.img`
width: 26px;
height: auto;
`;

const StyledSpan = styled.span`
  height: 36px;
  width: 36px;
  display: flex;
  background-color: white;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #0E5CBF !important;
`;

export const CertifyOwner = () => {
  const { state, addCertifyOwner } = useAppointment();
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container alignItems="center" wrap="nowrap">
      <Grid item style={{ display: "flex" }}>
        <StyledSpan>
          <StyledCheckbox
            sx={{
              '&:hover': { bgcolor: 'transparent' },
            }}
            checkedIcon={<Image src={check} alt="" />}
            checked={state.certifyOwner}
            onChange={(val) => addCertifyOwner(val.target.checked)}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </StyledSpan>
      </Grid>
      <Grid item container alignContent="center">
        <Grid item>
          <Typography className={`text-align-left ${isMedium ? "m-l-36" : "m-l-22"}`} variant="body1">
            I certify that I am an owner or authorized agent for this property and will be present for this inspection.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
