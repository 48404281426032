import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Select } from '@material-ui/core';
import { StyledFormControl, StyledMenuItem } from '../Common/StyledElements';
import styled from "styled-components";
import { COLORS } from '../../constants/colors';

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
 color: black !important;
 font-size: 35px;
 @media (max-width: 959px) {
  font-size: 25px;
 }
`;

const HailLocationsFormControl = styled(StyledFormControl)`
>label {
  color: ${COLORS.BLACK};
  
}
>div div {
  opacity: 0;
}
`;

export default function HailLocationSelect({ text, label, selectValue, change, listMap = [], iconImg }) {
  const SelectIcon = iconImg ? iconImg : StyledExpandMoreIcon;

  return (
    <Box sx={{ width: "100%" }}>
      <HailLocationsFormControl fullWidth>
        <InputLabel id={`simple-select-${label}`}>{label}</InputLabel>
        <Select
          labelId={`simple-select-${label}`}
          id={`${label}-simple-select`}
          value={selectValue}
          label={label}
          onChange={change}
          IconComponent={SelectIcon}
        >
          {listMap.map((item, i) => (
            <StyledMenuItem
              key={i}
              style={
                {
                  border: `1px solid`,
                  borderBottom: i === listMap.length - 1 ? '1px solid' : 'none',
                  borderBottomRightRadius: i === listMap.length - 1 ? 10 : 0,
                  borderBottomLeftRadius: i === listMap.length - 1 ? 10 : 0,
                }
              }
              value={item.value ? item.value : item}>{item.description ? item.description : item}
            </StyledMenuItem>
          ))}
        </Select>
      </HailLocationsFormControl>
    </Box>
  );
}