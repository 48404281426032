import React, { useCallback, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

import Gauge from "../../assets/gauges/meter_no_needle.webp";
// import Gauge from "../../assets/gauges/meter_no_needle.webp";
import Needle from "../../assets/gauges/blue_needle.svg";
import withLocalContext from "../store/withLocalContext";
import { getReport } from "../store/selectors";
const GaugeImg = styled.img`
  position: relative;
  width: 100%;
  object-position: top;
  height: 241px;
  object-fit: contain;
  @media (max-width: 450px) {
    height: 111px;
    }
`;

const breatheAnimation = keyframes`
from {
  transform: rotate(-92deg);
  -webkit-transform:rotate(-92deg);
  -ms-transform: rotate(-92deg);
  -moz-transform: rotate(-92deg);
}
to {
  transform: rotate(${(props) => props.degrees}deg);
  -webkit-transform: rotate(${(props) => props.degrees}deg);
  -ms-transform: rotate(${(props) => props.degrees}deg);
  -moz-transform: rotate(${(props) => props.degrees}deg);
}
 `;

const NeedleImg = styled.div`
  width: 50%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: calc(50%);
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
`;

const NeedleAnim = styled.img`
  width: 31px;
  // height: 88%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: calc(50% - 15px);
  transform-origin: center calc(100% - 15px);
  -webkit-transform-origin: center calc(100% - 15px);
  -ms-transform-origin: center calc(100% - 15px);
  -moz-transform-origin: center calc(100% - 15px);
  transform: rotate(${(props) => props.degrees}deg);
  -webkit-transform: rotate(${(props) => props.degrees}deg);
  -ms-transform: rotate(${(props) => props.degrees}deg);
  -moz-transform: rotate(${(props) => props.degrees}deg);

  animation: ${breatheAnimation} ${(props) => props.rating - 1}s 1;
  -webkit-animation: ${breatheAnimation} ${(props) => props.rating - 1}s 1;
  -ms-animation: ${breatheAnimation} ${(props) => props.rating - 1}s 1;
  -moz-animation: ${breatheAnimation} ${(props) => props.rating - 1}s 1;
  transition-timing-function: cubic-bezier(1, 1, 0.2, 0.5);
  -webkit-transition-timing-function: cubic-bezier(1, 1, 0.2, 0.5);
  -ms-transition-timing-function: cubic-bezier(1, 1, 0.2, 0.5);
  -moz-transition-timing-function: cubic-bezier(1, 1, 0.2, 0.5);
`;

export const GaugeOmeter = ({ context: { state } }) => {
  const { hailscaleRating } = getReport(state);


  // Get rate differences
  let noActRateDiff = (3.4 - 0) * 10;
  let monRateDiff = (7 - 3.4) * 10;
  let actRateDiff = (10 - 7) * 10;


  // Get degree differences
  let noActDegDiff = -34 - -92;
  let monDegDiff = 34 - -34;
  let actDegDiff = 92 - 34;


  // Degrees to 0.1 scale
  let noActDegScale = noActDegDiff / noActRateDiff;
  let monDegScale = monDegDiff / monRateDiff;
  let actDegScale = actDegDiff / actRateDiff;


  // Set up degrees according to their scale
  let noActDegrees = 0;
  let monDegrees = 0;
  let actDegrees = 0;

  // Calculate degrees according to their scale
  if (hailscaleRating <= 3.4) {
    noActDegrees = hailscaleRating * noActDegScale * 10;
  } else {
    noActDegrees = 3.4 * noActDegScale * 10;
  }

  if ((hailscaleRating > 3.4) && (hailscaleRating <= 7)) {
    monDegrees = (hailscaleRating - 3.4) * monDegScale * 10;
  }

  if (hailscaleRating >= 7.1) {
    monDegrees = (7 - 3.4) * monDegScale * 10;
    actDegrees = (hailscaleRating - 7) * actDegScale * 10;
  }

  // Add all scaled degrees together
  const degrees = -92 + noActDegrees + monDegrees + actDegrees;
  const [screenWidth, setElWidth] = useState(0);

  const getScreenWidth = useCallback(() => {
    const halfContWIdth = document.getElementById("gaugeOmeter").clientWidth / 2;
    setElWidth(halfContWIdth);
  }, [])

  useEffect(() => {
    getScreenWidth();
    window.addEventListener("resize", getScreenWidth);
    return () => window.removeEventListener("resize", getScreenWidth);
  }, [getScreenWidth]);

  return (
    <div
      id="gaugeOmeter"
      style={{
        position: "relative",
        width: "100%",
        height: screenWidth,
        maxWidth: "438px",
      }}
    >
      <GaugeImg src={Gauge} alt="gauge" />
      <NeedleImg>
        <NeedleAnim
          src={Needle}
          alt="needle"
          degrees={degrees}
          rating={hailscaleRating}
        />
      </NeedleImg>
    </div>
  );
};

export default withLocalContext(GaugeOmeter);
