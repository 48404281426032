import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useInspection } from "./Provider";
import withLocalContext from "../store/withLocalContext";
import InspectionAutoComplete from "./InspectionAutoComplete";
import { StyledLabel } from "../Common/StyledElements";

export const AddressSection = ({ loading, propertyMgName }) => {
  const setPropertyMgName = (value) => {
    propertyMgName(value);
  };

  const {
    state
  } = useInspection();
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid className="text-align-left" container item xs={12}>
      <Grid item xs={12} className="m-t-8 m-b-16">
        <StyledLabel>Address<span className="text-red" >*</span></StyledLabel>
        <InspectionAutoComplete loading={loading} propertyMgNameValue={setPropertyMgName} />
      </Grid>
      <Fragment>
        <Grid item className="m-t-16 m-r-40" style={{ height: isMedium ? "40px" : "16px" }}>
          <Typography variant="body1">Rating: <span className="text-font-fam-colfax-bold">
            {state.hscRating}
          </span>
          </Typography>
        </Grid>
        <Grid item className="m-t-16 m-r-40">
          <Typography variant="body1">Number of Storms: <span className="text-font-fam-colfax-bold">
            {state.totalHailCountOverRoofAge}
          </span>
          </Typography>
        </Grid>
        <Grid item className="m-t-16">
          <Typography variant="body1">Cumm. Duration: <span className="text-font-fam-colfax-bold">
            {state.totalHailDurationOverRoofAge}
          </span>
          </Typography>
        </Grid>
      </Fragment>
    </Grid>
  );
};

export default withLocalContext(AddressSection);
