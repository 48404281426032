import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import styled from "styled-components";
import HailScaleModal from "../Common/HailScaleModal";
import { useState } from "react";
import ReviewContainer from "./ReviewContainer";

const StyledQualityImg = styled.img`
  height: 70px;
  @media (max-width: 600px) {
    height: 34px;
  }
`;

const StyledQuality = styled(Typography)`
  font-size: 20px !important;
  max-width: 170px;
  font-family: Colfax Medium !important;
  @media (max-width: 600px) {
    font-size: 12px !important;
    line-height: 14px !important;
  }
`;

const StyledImg = styled.img`
    width: 100%;
    object-position: top;
    border-radius: 10px;
    height: 100%;
    object-fit: cover;
  `;

const SingleInspector = ({ ins }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Grid id={ins.title.replace(/ /g, "_").toLowerCase()} item xs={12} md={6} container className="m-b-75 p-l-20 p-r-20" alignContent="space-between">
      <Grid container item xs={12} className={isMedium ? "" : ""}>
        <Grid item xs={12} style={{ height: isMedium ? "25vw" : "auto" }}>
          <StyledImg src={ins.image} alt={ins.title} />
        </Grid>
        <Grid item xs={12} className="m-b-10">
          <Typography variant="h2" className="m-t-20">{ins.title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" className="text-align-left">{ins.paragraph}</Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} wrap="nowrap" className="border-radius-10 border-LIGHT_BLUE m-t-25">
        {ins.qualities.map((quality, i) => (
          <Grid key={quality.description} item container className={`${isMedium ? "p-t-20 p-b-20" : "p-t-8 p-b-8"} ${ins.qualities.length - 1 === i ? "" : "border-r-LIGHT_BLUE"}`} alignContent="flex-start" style={{ height: isMedium ? "150px" : "auto" }}>
            <Grid item xs={12} style={{ marginBottom: "5px", height: "fit-content" }}>
              <StyledQualityImg src={quality.icon} alt="" />
            </Grid>
            <Grid item container alignContent="flex-start" xs={12}>
              {quality.description === "reviews" ?
                <Grid item container xs={12} flexDirection={"column"} className="cursor-pointer" onClick={() => setIsModalOpen(true)}>
                  <StyledQuality className="margin-auto">{`${ins.review.reviewRating}/5.00`}</StyledQuality>
                  <StyledQuality className="margin-auto text-decoration-underline">{`${ins.review.numberOfReviews} ${quality.description}`}</StyledQuality>
                </Grid>
                :
                <StyledQuality className="margin-auto">{quality.description}</StyledQuality>
              }
            </Grid>
          </Grid>
        ))}
      </Grid>
      <HailScaleModal
        modalWidth={"100%"}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        children={<ReviewContainer reviews={ins.review.elfsight.html} />}
      />
    </Grid>
  )
}

export default SingleInspector;