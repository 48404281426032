import React, { useEffect } from "react";
import { CssVarsProvider } from '@mui/joy/styles';
import { theme } from "./joyTheme";
import { Navigate, Outlet } from "react-router-dom";
import { getIsAuthorized } from "../store/selectors";
import { ADMIN_ROUTES, ROUTES } from "../../constants/routes";
import Grid2 from '@mui/material/Unstable_Grid2';
import AdminCtx from "./store/AdminCtx";
import Header from "./Components/Header";
import Menu from "./Components/Menu";
import AdminLoad from "./Components/AdminLoad";
import withAdminContext from "./store/withAdminContext";
import { COLORS } from "../../constants/colors";

export const Admin = ({ context: { dispatch } }) => {

  // Get the root element
  let root = document.querySelector(':root');

  function setColors() {
    // Set the value of css variables to value of colors.js
    // Inspiration from here: https://www.w3schools.com/css/css3_variables_javascript.asp
    Object.entries(COLORS).map(color => {
      return root.style.setProperty(`--${color[0]}`, color[1]);
    });
  };

  useEffect(() => {
    if (!getIsAuthorized()) {
      window.history.pushState({ url: `${ROUTES.ADMIN}/${ADMIN_ROUTES.APP_MANAGEMENT.route}` }, "");
    }
  })

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname.includes('admin')) {
      document.getElementById("App").style.paddingTop = 0;
    }
  });

  useEffect(() => {
    setColors();
  });

  return (
    getIsAuthorized() ?
      (<div id="Admin" className="Admin">
        <CssVarsProvider theme={theme}>
          <AdminCtx>
            <AdminLoad />
            <Grid2 container>
              <Grid2 xs={12}>
                <Header />
              </Grid2>
              <Grid2 xs={3}>
                <Menu />
              </Grid2>
              <Grid2 xs={9}>
                <Outlet />
              </Grid2>
            </Grid2>
          </AdminCtx>
        </CssVarsProvider>
      </div>)
      : (
        <Navigate to={ROUTES.LOGIN_IN} />
      )
  );
}

export default withAdminContext(Admin);