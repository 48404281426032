import React, { useState } from "react";
import { Button, Typography, Grid, Box } from "@mui/material";
import styled from "styled-components";
import { crunchAddresses, postBatchReport, postCampaigns } from "../../services";
import CircularProgressComp from "../CircularProgress";
import greenCheck from "../../assets/green_check.svg";

const StyledMessageDiv = styled(Grid)`
    min-height: 100px;
  `;

export const Admin = () => {
  const [message, setMessage] = useState("");

  const CrunchAddresses = async () => {
    // Set initial message and go get addresses
    setMessage(<div><Typography variant="h5">Retrieving addresses...</Typography></div>);
    const addRes = await crunchAddresses();

    if (addRes.status === 200) {

      // Send addresses 20 at a time to be processed
      const batchNum = 50;
      // Used to slice out addresses and send a few at a time
      let spacing = batchNum;
      // Used for tracking the loop number
      let count = 0;
      // Used for setting the number of times to loop
      let numOfTimes = Math.ceil(addRes.data.length / batchNum);
      // Sets the progress percentage number
      let progress = 0;

      setMessage(<div><Typography variant="h5"> Found {addRes.data.length} addresses. Processing them now...</Typography> <CircularProgressComp progress={progress} /></div>);
      // Set message for how many addresses found
      while (count < numOfTimes) {
        const addSlice = addRes.data.slice(spacing - batchNum, spacing);
        const isCampaignAddress = true;
        if (isCampaignAddress) {
          // for processing addresses that are used in campaigns
          await postCampaigns({ addresses: addSlice })
        } else {
          // for processing addresses and retriving data from hailstrike
          await postBatchReport({ addresses: addSlice });
        }
        if (count === numOfTimes - 1) {
          progress = 100;
          setMessage(<div><Typography variant="h5"> Processed {addRes.data.length} addresses!</Typography> <img style={{ maxWidth: 30 }} src={greenCheck} alt="" /></div>);
        } else {
          progress = 100 * (spacing / addRes.data.length);
          setMessage(<div><Typography variant="h5"> Found {addRes.data.length} addresses. Processing them now...</Typography> <CircularProgressComp progress={progress} /></div>);
        }

        spacing += batchNum;
        count++;
      }
    } else {
      setMessage(<Typography variant="h5">Something went wrong. Please refresh page and try again. If the problem persists, contact the system administrator</Typography>);
    }
  };

  return (
    <div style={{ paddingTop: 100 }} className='reporting'>
      <Button onClick={CrunchAddresses}>Crunch those Addresses</Button>
      <StyledMessageDiv container justifyContent="center" alignContent="center">{message}</StyledMessageDiv>
      <Box component={"div"} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Button sx={{ maxWidth: "300px", marginBottom: "30px" }}>Create Next Day Hail Alerts</Button>
        <Box component={"label"} sx={{ marginBottom: "3px" }}>Hail Start Date</Box>
        <Box component={"input"} type="date" sx={{ marginBottom: "50px" }} />
      </Box>
    </div>
  );
}

export default Admin;