import React from "react";
import { useInspection } from "../Provider";
import BasicSelect from "../../Common/HSCSelect";

export const DAMAGE_TYPES = [
  {
    description: "No damage",
    value: "No damage"
  },
  {
    description: "Some damage",
    value: "Some damage"
  },
  {
    description: "Moderate damage",
    value: "Moderate damage"
  },
  {
    description: "Significant damage",
    value: "Significant damage"
  },
];

export const DmgAmount = () => {
  const { state, addDmgAmount } = useInspection();

  return (
    <BasicSelect
      text="Overall amount of hail damage"
      label="Choose..."
      selectValue={state.dmgAmount}
      change={(val) => addDmgAmount(val.target.value)}
      listMap={DAMAGE_TYPES}
    />
  );
};
