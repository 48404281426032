import React from 'react';
import HSC_Meter_404 from "../../assets/hsc_meter_404.webp";
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Typography from "@mui/material/Typography";
import { COLORS } from "../../constants/colors";
import { ROUTES } from "../../constants/routes";
import ATagBtn from "../Common/ATagBtn";

const StyledGrid = styled(Grid)`
height: calc(100% - 266px);
position: fixed;
@media (max-width: 960px) {
    height: calc(100% - 371px);
}
`;

const StyledTitle = styled(Typography)`
    color: ${COLORS.RED};
    margin: 30px 0;
`;

const StyledSubTitle = styled(Typography)`
    font-size: 20px !important;
    margin-bottom: 30px;
    @media (max-width: 960px) {
        padding: 0 30px;
    }
`;

export const NotFound = () => {

    return (
        <StyledGrid container justifyContent='center' alignContent='center'>
            <div>
                <img style={{ maxWidth: 350 }} src={HSC_Meter_404} alt="" />
                <StyledTitle variant='h1'>High Risk of this page not existing</StyledTitle>
                <StyledSubTitle variant='body2'>We aren’t sure this page exists. Try again, or come back in a few minutes. </StyledSubTitle>
                <ATagBtn text="Back to home" href={ROUTES.LANDING_PAGE} />
            </div>
        </StyledGrid>
    );
}

export default NotFound;