import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styled from "styled-components";
import moment from "moment";
import withLocalContext from "../../store/withLocalContext";
import { getReport } from "../../store/selectors";
import Popover from "../../Common/Popover";
import { TOOLTIPS } from "../../../constants/tooltips";
import json2csv from "json2csv";

const StyledMessage = styled(Typography)`
line-height: 40px !important;
@media (max-width: 600px) {
  line-height: 24px !important;
}
`;

const reportToCSV = (incomingData) => {
  let list = [];
  const data = incomingData;
  data.at_point.map((item) => {
    const stormObj = {
      "type": "at",
      "stations": item.stations[0],
      "station_distances": item.station_distances[0],
      "composite_storms": item.composite_storms[0],
      "storm_start": item.storm_start,
      "storm_end": item.storm_end,
      "local_storm_start": item.local_storm_start,
      "local_storm_end": item.local_storm_end,
      "duration": item.duration,
      "max_dhi_at_point": item.max_dhi_at_point,
      "max_dhi_within_radius": item.max_dhi_within_radius,
      "average_dhi_at_point": item.average_dhi_at_point,
      "average_dhi_around_point": item.average_dhi_around_point,
      "has_spotters": item.has_spotters,
      "storm_date": item.storm_date,
      "storm_time": item.storm_time,
      "local_storm_date": item.local_storm_date,
      "local_storm_time": item.local_storm_time,
      "storm_duration_minutes": item.storm_duration_minutes,
      "hail_size": item.hail_size,
      "storm_speed": item.storm_speed,
      "storm_heading": item.storm_heading,
      "storm_duration": item.storm_duration,
      "duration_at_point": item.duration_at_point,
    }
    return list.push(stormObj);
  });

  data.around_point.map((item) => {
    const stormObj = {
      "type": "around",
      "stations": item.stations[0],
      "station_distances": item.station_distances[0],
      "composite_storms": item.composite_storms[0],
      "storm_start": item.storm_start,
      "storm_end": item.storm_end,
      "local_storm_start": item.local_storm_start,
      "local_storm_end": item.local_storm_end,
      "duration": item.duration,
      "max_dhi_at_point": item.max_dhi_at_point,
      "max_dhi_within_radius": item.max_dhi_within_radius,
      "average_dhi_at_point": item.average_dhi_at_point,
      "average_dhi_around_point": item.average_dhi_around_point,
      "has_spotters": item.has_spotters,
      "storm_date": item.storm_date,
      "storm_time": item.storm_time,
      "local_storm_date": item.local_storm_date,
      "local_storm_time": item.local_storm_time,
      "storm_duration_minutes": item.storm_duration_minutes,
      "hail_size": item.hail_size,
      "storm_speed": item.storm_speed,
      "storm_heading": item.storm_heading,
      "storm_duration": item.storm_duration
    }
    return list.push(stormObj);
  });

  const csv = json2csv.parse(list, {
    fields: [
      "type",
      "stations",
      "station_distances",
      "composite_storms",
      "storm_start",
      "storm_end",
      "storm_start",
      "local_storm_start",
      "local_storm_end",
      "duration",
      "max_dhi_at_point",
      "max_dhi_within_radius",
      "average_dhi_at_point",
      "average_dhi_around_point",
      "has_spotters",
      "storm_date",
      "storm_time",
      "local_storm_date",
      "local_storm_time",
      "storm_duration_minutes",
      "hail_size",
      "storm_speed",
      "storm_heading",
      "storm_duration",
      "duration_at_point",
    ],
  });

  var hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
  hiddenElement.target = '_blank';

  //provide the name for the CSV file to be downloaded  
  hiddenElement.download = 'Dataforme.csv';
  hiddenElement.click();
}
let run = 0;

export const HailDetail = ({ context: { state } }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  const { heuristic, hailscaleRating, hailstrikeData } = getReport(state);

  // const hailstrikeData = getReport(state).hailstrikeData;


  useEffect(() => {
    if (run === 1) {
      reportToCSV(hailstrikeData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [run])

  const message = heuristic || "";

  let newmessage = message.split("NEXRAD radar");
  let Noaa = newmessage[1].split(" NOAA's ");

  const title = "Your Recent Hail History";
  let recentHailDate;
  if (hailstrikeData.at_point.length > 0) {
    recentHailDate = `${moment(hailstrikeData.at_point[0].local_storm_start).format("MMM Do YYYY [at] h:mm A")}`;
  } else {
    recentHailDate = "You have had no hail storms in the timeframe specified";
  }
  const whyItMatters = hailscaleRating < 7.0 ? "Hail damage is cumulative and can degrade your roof over time. It is important to monitor your roof and perform and inspection after the next hail storm."
    :
    "Hail of this magnitude can substantially degrade your roof and compromise its ability to prevent future leaks.";

  const NEXRADText = TOOLTIPS.NEXRAD;
  const NEXTADBtn = "NEXRAD radar";
  const NOAAText = TOOLTIPS.NOAA;
  const NOAABtn = "NOAA's";


  return (
    <Grid item container xs={12} alignItems="center" className={`text-align-left ${isMedium ? "p-l-95 p-r-90" : "p-t-40 p-r-30 p-l-30"}`}>
      <Grid item xs={12}>
        <Typography style={{ maxWidth: "590px" }} className="text-blue" variant="h3">{title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <StyledMessage className={isMedium ? "m-t-30" : "m-t-10"} variant="body2"><span className="text-font-fam-colfax-bold">Most Recent Hail Date: </span>{recentHailDate}</StyledMessage>
      </Grid>
      <Grid item xs={12}>
        <StyledMessage variant="body2" className={`text-align-left ${isMedium ? "m-t-16" : "m-t-5"}`}>
          <span className="text-font-fam-colfax-medium">Most Significant Hail Event: </span>
          {newmessage[0]}
          <Popover
            btnText={NEXTADBtn}
            bodyText={NEXRADText}
            position="top-end"
            classes={"text-blue"}
          />
          {Noaa[0]}
          <Popover
            btnText={NOAABtn}
            bodyText={NOAAText}
            classes={"text-blue"}
          />
          {Noaa[1]}
        </StyledMessage>
      </Grid>
      <Grid item xs={12}>
        <StyledMessage className={isMedium ? "m-t-30" : "m-t-10"} variant="body2"><span className="text-font-fam-colfax-bold">Why it Matters: </span>{whyItMatters}</StyledMessage>
      </Grid>
    </Grid>
  );
};

export default withLocalContext(HailDetail);