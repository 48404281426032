import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { OUR_INSPECTORS } from "../../constants/inspectors";
import { COLORS } from "../../constants/colors";
import { NavLink } from "react-router-dom";

const OurRoofers = () => {

  const ROOFERS = OUR_INSPECTORS.filter(item => item.title !== "MNRC")

  const bp1420 = useMediaQuery('(max-width:1420px)');
  const bp1300 = useMediaQuery('(min-width:1300px)');
  const bp750 = useMediaQuery('(min-width:750px)');
  return (
    <Grid container item xs={12} justifyContent={bp1300 ? "flex-start" : "center"} sx={{ backgroundColor: COLORS.BLUE, height: bp1300 ? 243 : "auto" }}>
      <Grid container item xs={bp1300 ? 3 : 12} justifyContent={"center"} alignItems={"center"} sx={bp750 ? bp1420 ? { marginRight: "-50px", marginLeft: "-30px" } : {} : {}}>
        <Typography
          variant="h4"
          sx={{
            textTransform: "uppercase",
            fontSize: `${bp750 ? 51 : 24}px !important`,
            color: COLORS.WHITE,
            maxWidth: bp1300 ? 265 : "unset",
            textAlign: "left",
            marginTop: bp750 ? 0 : "25px"
          }}
        >
          Meet Our Roofing Partners
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={bp1300 ? 9 : 12}
        justifyContent={bp1300 ? "flex-start" : "center"}
        alignItems={"flex-end"}
        sx={{ marginTop: bp1300 ? "-50px !important" : 0, width: "auto" }}
      >
        {ROOFERS.map((item, i) => (
          <NavLink
            key={item.title}
            to={`${item.link}#${item.title.replace(/ /g, "_").toLowerCase()}`}
            style={{
              position: "relative",
              height: "fit-content",
              display: "flex",
              zIndex: 1,
              marginRight: bp1300 ? (i !== ROOFERS.length - 1 ? "100px" : 0) : 0
            }}
          >
            <Grid container item flexDirection={bp1300 ? "row" : "column"}>
              <Box
                component={"img"}
                alt={item.name}
                src={item.cutout}
                sx={{
                  objectPosition: "bottom",
                  width: bp750 ? 245 : 136,
                  objectFit: "cover",
                  overflow: "visible",
                  marginRight: bp750 ? 0 : "-10px"
                }}
              />
              <Box
                component={"img"}
                alt={item.name}
                src={item.logo}
                sx={{
                  position: bp1300 ? "absolute" : "unset",
                  maxHeight: bp750 ? 60 : 38,
                  marginTop: bp1300 ? 0 : "30px",
                  marginBottom: bp1300 ? 0 : "15px",
                  objectFit: "contain",
                  bottom: i === 0 ? "145px" : "160px",
                  left: i !== ROOFERS.length - 1 ? "180px" : "160px"
                }}
              />
            </Grid>
          </NavLink>
        ))}
      </Grid>
    </Grid >
  )
}

export default OurRoofers;