import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { COLORS } from "../../../constants/colors";
import withLocalContext from "../../store/withLocalContext";
import { HighRiskDamageToolTip, VerifiedHailDateLocationToolTip, InsuranceClaimEligibleToolTip, FreeInspectionToolTip } from "../../Tooltips/Tooltips";
import CalendarPicker from "../../Common/CalendarPicker";
import { TimeSlot } from "./TimeSlots";
import rightArrow from "../../../assets/rightArrow.webp";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { ROUTES } from "../../../constants/routes";
import { findFirstAvailableDate } from "../../Common/findFirstAvailableDate";

const Inspect = ({ context: { state } }) => {
  const [appDetails, setAppDetails] = useState({ date: findFirstAvailableDate(dayjs()) });
  const navigate = useNavigate();
  const bp1300 = useMediaQuery('(min-width:1300px)');
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  const { report:
    { largestHailEvent:
      {
        hailSize,
        localStormDate
      }
    }
  } = state;

  const INSPECT_POINTS = [
    {
      text: "High risk of damage",
      tooltip: <HighRiskDamageToolTip classes={"text-black"} />
    },
    {
      text: "Verified hail date at location",
      tooltip: <VerifiedHailDateLocationToolTip stats={{ hailSize: hailSize === "<1.00" ? ">0.50" : hailSize, localStormDate: localStormDate }} classes={"text-black"} />
    },
    {
      text: "Insurance claim eligible",
      tooltip: <InsuranceClaimEligibleToolTip classes={"text-black"} />
    },
    {
      text: "Hailscale inspections are free",
      tooltip: <FreeInspectionToolTip classes={"text-black"} />
    }
  ]

  return (
    <Grid container item xs={12} className={`text-align-left p-t-32 p-b-50 ${isMedium ? "p-l-85 p-r-60" : "p-l-35 p-r-35"}`} alignItems={"center"}>
      <Grid item xs={12}>
        <Typography variant="h4" className="text-white m-b-20" sx={isMedium ? {} : { fontSize: "24px" }}>
          We recommend an inspection:
        </Typography>
      </Grid>
      <Grid item xs={12} md={bp1300 ? 4 : 6}>
        {INSPECT_POINTS.map(item => (
          <Typography
            key={item.text}
            variant="body1"
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: isMedium ? "24px !important" : "20px !important",
              width: isMedium ? "auto" : "max-content"
            }}
            className="text-white m-b-22"
          >
            <CheckRoundedIcon sx={{ color: COLORS.LIGHT_BLUE, fontSize: "30px", marginRight: "5px" }} /><Box component={"span"} sx={{ marginRight: "5px" }}>{item.text}</Box>{item.tooltip}
          </Typography>
        ))}
      </Grid>
      <Grid container item xs={12} md={bp1300 ? 8 : 6} justifyContent={isMedium ? "center" : 'flex-start'} height={"min-content"}>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: isMedium ? "30px" : "20px" }} variant="h4" className={`${isMedium ? "text-align-center" : "text-align-left m-t-40"} text-white m-b-20`}>
            Find out if your roof, siding, and windows are damaged.
          </Typography>
        </Grid>
        <Grid container item xs={12} justifyContent={bp1300 ? "center" : "flex-start"} gap={"18px"} sx={{ maxWidth: bp1300 ? "unset" : isMedium ? "500px !important" : "unset" }} >
          <Grid item sx={{ "div": { height: isMedium ? "75px" : "45px" }, ">div": { width: "100%" }, position: "relative" }}>
            <CalendarPicker
              sxStyles={{ backgroundColor: COLORS.WHITE, borderRadius: "10px", "input": { fontSize: isMedium ? "24px" : "16px" } }}
              onChange={(val) => setAppDetails({ ...appDetails, date: dayjs(val) })}
            />
          </Grid>
          <Grid item sx={{ width: isMedium ? "350px" : "calc(100% - 95px)" }}>
            <TimeSlot onChange={(val) => setAppDetails({ ...appDetails, timeSlot: val })} />
          </Grid>
          <Grid item>
            <Button
              variant="tan"
              sx={{ fontSize: "30px !important" }}
              onClick={() => navigate(ROUTES.SCHEDULE_FORM, { state: { ...appDetails } })}
              ariaLabel="Continue to Scheduling"
            ><Box component={"img"} sx={{ height: "36px", objectFit: "contain" }} src={rightArrow} /></Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default withLocalContext(Inspect);