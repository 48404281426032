import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { HOME_OWNER_REVIEWS } from '../../constants/homeOwnerReviews';
import { COLORS } from '../../constants/colors';

function HomeOwnerReview() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = HOME_OWNER_REVIEWS.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const contHeight = "400px";

  return (
    <Box sx={{ height: contHeight }}>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {HOME_OWNER_REVIEWS.map((step, index) => (
          <div key={step.owner.name}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Grid container>
                <Grid item xs={5}>
                  <Box
                    component="img"
                    sx={{
                      display: 'block',
                      overflow: 'hidden',
                      width: '100%',
                      objectFit: "cover",
                      objectPosition: "top",
                      height: contHeight
                    }}
                    src={step.image}
                    alt={step.owner.name}
                  />
                </Grid>
                <Grid item xs={7} container alignItems={"center"}>
                  <Grid sx={{ marginLeft: "80px", paddingLeft: "27px", paddingRight: "40px", borderLeft: `5px solid ${COLORS.LIGHT_BLUE}` }}>
                    <Typography
                      sx={{
                        fontFamily: "Noto sans-serif !important",
                        fontSize: "100px !important",
                        fontWeight: "300 !important",
                        color: `${COLORS.BLUE} !important`,
                        lineHeight: "1.5 !important",
                        textAlign: "left",
                        marginTop: "-30px"
                      }}
                    >
                      “
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "20px !important",
                        textAlign: "left",
                        marginTop: "-60px",
                        lineHeight: "30px !important"
                      }}
                    >
                      {step.quote}
                    </Typography>
                    <Grid container justifyContent={"space-between"} alignItems={"flex-end"} sx={{ marginTop: "20px" }}>
                      <Grid item container xs={"auto"}>
                        <Box
                          component={"img"}
                          src={step.owner.headshot}
                          alt={step.owner.name}
                          sx={{
                            height: "65px",
                            marginRight: "20px",
                            borderRadius: "50%"
                          }}
                        />
                        <Grid item sx={{ textAlign: "left", marginTop: "10px" }}>
                          <Typography variant='h3' sx={{ fontSize: "20px !important" }}>-{step.owner.name}{step.owner.location ? `, ${step.owner.location}` : ""}</Typography>
                          <Typography variant='h3' sx={{ fontSize: "20px !important", fontFamily: "Colfax Regular !important", marginLeft: "10px", marginTop: "5px" }}>Home restored in {step.owner.yearRestored}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs="auto">
                        <Box
                          component={"a"}
                          target='_blank'
                          rel='noopener noreferrer'
                          aria-label='link to review on Google'
                          href={step.reviewLink ? step.reviewLink : "https://goo.gl/maps/zDC3YGYxRz27V7C6A?coh=178572&entry=tt"}
                          sx={{ color: COLORS.BLACK, fontFamily: "Colfax Light", fontSize: "20px", textDecorationThickness: "1px" }}
                        >
                          See review on google
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            ) : null}
          </div>
        ))}
      </SwipeableViews>
      <Grid container justifyContent={"flex-end"} sx={{ marginTop: "-65px" }} zIndex={500}>
        <MobileStepper
          sx={{ zIndex: 500, height: "40px" }}
          variant='text'
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              sx={{
                backgroundColor: "transparent !important",
                color: `${COLORS.BLACK} !important`,
                "&:hover": {
                  backgroundColor: "transparent",
                  transform: "scale(1.1)"
                }
              }}
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeftIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </Button>
          }
          backButton={
            <Button
              sx={{
                backgroundColor: "transparent !important",
                color: `${COLORS.BLACK} !important`,
                "&:hover": {
                  backgroundColor: "transparent",
                  transform: "scale(1.1)"
                }
              }}
              size="small"
              onClick={handleBack} disabled={activeStep === 0}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRightIcon />
              ) : (
                <KeyboardArrowLeftIcon />
              )}
            </Button>
          }
        />
      </Grid>
    </Box>
  );
}

export default HomeOwnerReview;
