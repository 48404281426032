import React from "react";
import { useInspection } from "../Provider";
import BasicSelect from "../../Common/HSCSelect";

export const FILING_STATUS_TYPES = [
  "Filed",
  "Declined to file",
  "Incomplete",
];

export const FilingStatus = () => {
  const { state, addFilingStatus } = useInspection();

  return (
    <BasicSelect
      text="Did homeowner submit claim?"
      label="Choose..."
      selectValue={state.filingStatus}
      change={(val) => addFilingStatus(val.target.value)}
      listMap={FILING_STATUS_TYPES}
    />
  );
};
