import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import copyLink from "../../assets/copyLink.webp";
import fb from "../../assets/social_media/fb.webp";
import instagram from "../../assets/social_media/instagram.webp";
import linkedin from "../../assets/social_media/linkedin.webp";
import twitter from "../../assets/social_media/twitter.webp";
import { COLORS } from "../../constants/colors";
import { useState } from "react";

const socials = [
  {
    icon: fb,
    link: "https://www.facebook.com/sharer/sharer.php?u=www.hailscale.com",
  },
  {
    icon: instagram,
    link: "https://www.instagram.com",
  },
  {
    icon: twitter,
    link: "https://twitter.com/intent/tweet?text=www.hailscale.com",
  },
  {
    icon: linkedin,
    link: "https://www.linkedin.com/shareArticle?mini=true&url=www.hailscale.com&title=Check%20Out%20Hailscale!&summary=&source=",
  },
]

const StyledATag = styled.a`
  margin: 0 5px;
  img {
    width: 46px;
  }
  @media (max-width: 960px) {
    img {
      width: 29px;
    } 
  }
`;

const StyledCopyLink = styled(Typography)`
  font-size: 20px !important;
  cursor: pointer;
  color: ${COLORS.PRIMARY_BLUE};
  text-decoration: underline;
  img {
    height: 24px;
    margin: 0 10px;
    margin-bottom: -4px;
  }

  @media (max-width: 960px) {
    font-size: 16px !important;
    margin-top: 15px;
    img {
      height: 20px;
    }
  }
`;

const StyledConfirm = styled(Typography)`
  color: ${COLORS.GOOD_GREEN};
  font-size: 20px !important;
  margin-left: 20px;
  @media (max-width: 960px) {
    font-size: 16px !important;
  }
`;

export const Share = () => {

  const [isCopied, setIsCopied] = useState(false);
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  const CopyLinkText = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText("www.hailscale.com").then(() => {
        setIsCopied(true);
      })
    } else {
      alert('Browser Not compatible');
    }
  }
  return (
    <Grid container className={isMedium ? "text-align-left m-b-75" : ""}>
      <Grid container item xs={12} md={9} alignItems="center" justifyContent={isMedium ? "flex-start" : "center"}>
        <Grid item xs={12} className="m-b-20">
          <Typography variant="h2" className="m-b-10 text-blue">Tell a friend!</Typography>
          <Typography variant="body2">Know someone who could benefit from Hailscale? Let them know:</Typography>
        </Grid>
        <Typography variant="h5" className="m-r-5">Share link to:</Typography>
        {socials.map(social => (
          <StyledATag key={social.link} target="_blank" rel="noopener noreferrer" href={social.link}><img src={social.icon} alt="" /></StyledATag>
        ))}
        <StyledCopyLink onClick={() => CopyLinkText()} variant="h5"><img src={copyLink} alt="" />Copy Link</StyledCopyLink>
        {isCopied &&
          <StyledConfirm variant="h6">Link copied!</StyledConfirm>
        }
      </Grid>
    </Grid>
  );
}

export default Share;