import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import withLocalContext from "../../store/withLocalContext";
import styled from "styled-components";
import { Fragment } from "react";
import { ROUTES } from "../../../constants/routes";

const StyledTitle = styled(Typography)`
  text-transform: uppercase;
  font-weight: 900 !important;
  margin-bottom: 5px;
`;

const StyledBtnCon = styled.div`
button {
  padding: 10px 20px !important;
  margin: 0 9px;
  width: 232px;
}

@media (max-width: 960px) {
  width: 100%;
  button {
    width: 100%;
    padding: 10px 5px !important;
    margin: 0;
  }
}
`;

export const NotReady = ({ context: { state } }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Fragment>
      <Grid container item xs={12} lg={9} className="text-align-left m-b-20">
        <Grid item xs={12} className={`${isMedium ? "m-b-50" : "m-b-28"}`}>
          <Typography sx={{ fontSize: "28px !important", fontFamily: "Colfax Regular !important" }} variant="h3">Not ready for an inspection? Enroll in hail monitoring.</Typography>
        </Grid>
        <Grid item xs={12} md={5} className={`${isMedium ? "p-r-25" : ""}`}>
          <StyledTitle variant="h6">Real time hail alerts</StyledTitle>
          <Typography variant="body2">
            We will alert you within 24 hours of detecting a hail event that impacts your property (great for cabins and rental properties).
          </Typography>
        </Grid>
        <Grid item xs={12} md={7} className={`${isMedium ? "p-l-25 p-r-25" : "m-t-25"}`}>
          <StyledTitle variant="h6">Quarterly hail history reports</StyledTitle>
          <Typography variant="body2">
            You will receive a hail history report each quarter<br /> detailing your up-to-date weather history.
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} lg={3} justifyContent="center" alignItems="center">
        <StyledBtnCon>
          <Button
            variant="outlined"
            color="tan"
            onClick={() => navigate(ROUTES.LANDING_PAGE)}
          >Back to home</Button>
        </StyledBtnCon>
        <StyledBtnCon style={{ marginTop: isMedium ? "" : 16 }}>
          <Button
            variant="tan"
            onClick={() => navigate(ROUTES.MONITORING)}
          >Sign-up for hail monitoring</Button>
        </StyledBtnCon>
      </Grid>
    </Fragment>
  );
}

export default withLocalContext(NotReady);