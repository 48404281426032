import Grid from "@mui/material/Grid";
import styled from "styled-components";


export const Gradient = styled(Grid)`
  max-width: 100%;
  overflow: hidden;
  position: relative;
  
  background: linear-gradient(180deg,rgba(14,92,191,30%) 30%,rgba(14,92,191,20%) 40%, transparent);
  // background: linear-gradient(180deg, #0e5cbf66 38.54%, #eef2f736 63.54%, transparent 100%);
  // background: linear-gradient(180deg, rgba(182, 200, 221, 0.4) 38.54%, rgba(238, 242, 247, 0.210417) 63.54%, rgba(238, 242, 247, 0) 100%)
`;

export default Gradient;