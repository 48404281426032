import React, { Fragment } from "react";
import { COLORS } from "../../constants/colors";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { MENU_ARR } from "../../constants/menu";
import { ELEMENT_SIZES } from "../../constants/elementSizes";
import { Slide, Grid, Paper, Button, ClickAwayListener } from "@mui/material";
import { useScreenWidth } from "../Common/useScreenWidth";

const StyledPaper = styled(Paper)`
${props => props.ismedium === "true" ? `
  top: ${ELEMENT_SIZES.headerHeight.lg}px;
  background-color: ${COLORS.WHITE} !important;
  border-radius: 0;
  `: `
  top: ${ELEMENT_SIZES.headerHeight.sm}px;
  border-radius: 10px 0 10px 10px;
  right: 20px;
  `}
  right: 0px;
  z-index: 1;
  position: fixed;
  width: 100%;
  max-width: fit-content;
  border: 1px solid ${COLORS.LIGHT_BLUE};
`;

export const Menu = ({ isOpen, toggleMenuCB }) => {
  const navigate = useNavigate();
  const isMedium = useScreenWidth();

  const goTo = (route) => {
    navigate(route);
    toggleMenuCB();
  }

  if (isOpen) {
    return (
      <Fragment>
        <ClickAwayListener onClickAway={toggleMenuCB}>
          <Slide direction="left" in={isOpen} mountOnEnter unmountOnExit>
            <StyledPaper ismedium={String(isMedium)} elevation={4}>
              <Grid className="text-align-right">
                {MENU_ARR.map(item => (
                  <Grid key={item.title} className="border-t-LIGHT_BLUE">
                    <Button
                      sx={{
                        fontSize: `${isMedium ? "23px !important" : "16px !important"}`,
                        width: "100% !important",
                        backgroundColor: "transparent !important",
                        color: `${COLORS.BLUE} !important`,
                        justifyContent: "flex-end !important",
                        padding: "0 20px !important",
                        height: "53px !important",
                        "&:hover": {
                          backgroundColor: "transparent",
                          transform: "scale(1.03)"
                        }
                      }}
                      ismedium={String(isMedium)}
                      onClick={() => goTo(item.link)}
                    >
                      {item.title}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </StyledPaper>
          </Slide>
        </ClickAwayListener>
      </Fragment>
    );
  }
  return null;
};

export default Menu;
