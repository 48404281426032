import styled from "styled-components";
import { COLORS } from "../../../../constants/colors";
import { classnames } from "./ButtonLinkClassnames";
const StyledButton = styled.button`
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    transform: scale(1.04);
  }
  &:disabled {
    color: ${COLORS.LIGHT_BLUE} !important;
    border-color: ${COLORS.LIGHT_BLUE} !important;
    pointer-events: none;
  }
`;

export const AdminButton = ({ text, onClick, classNames, isDisabled }) => {
  return <StyledButton className={[...classnames, classNames]} disabled={isDisabled} onClick={onClick}>{text}</StyledButton>
}