import React from "react";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import withLocalContext from "../../store/withLocalContext";
import RatingDial from "../RatingDial";
import RatingBadge from "../RatingBadge";
import { StyledPhrase } from "../RatingBadge";

export const RatingMeter = ({ context: { state } }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid item container xs={12} className={isMedium ? "p-l-70" : "p-r-30 p-l-30"}>
      <Grid item xs={4} md={12}>
        {isMedium ?
          <RatingDial />
          :
          <RatingBadge />
        }
      </Grid>
      <Grid item xs={8} md={12}>
        {isMedium ?
          <RatingBadge />
          :
          <RatingDial />
        }
      </Grid>
      {!isMedium ?
        <Grid item xs={12}>
          <StyledPhrase variant="subtitle1">Rating assumes asphalt roofing system.</StyledPhrase>
        </Grid>
        :
        ""
      }
    </Grid>
  );
};

export default withLocalContext(RatingMeter);
