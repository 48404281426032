import { Grid, Typography } from "@mui/material";
import { ELEMENT_SIZES } from "../../constants/elementSizes";
import { useEffect, useState } from "react";
import { unsubscribeFromHailscale } from "../../services";

const Unsubscribe = () => {
  const [footerHeight, setFooterHeight] = useState(0);
  const [isComplete, setIsComplete] = useState();

  useEffect(() => {
    const footerEl = document.getElementById("hsc_footer");
    if (footerEl) {
      setFooterHeight(footerEl.clientHeight)
    }

  }, [footerHeight]);

  useEffect(() => {
    async function unsubscribeEmail() {
      const email = decodeURIComponent(window.location.search).replace("?", "").replace("email=", "");
      const unsubscribe = await unsubscribeFromHailscale({ email: email });
      setIsComplete(unsubscribe.data.status);
    }
    unsubscribeEmail();
  }, [])

  return (
    <Grid container item xs={12} justifyContent={"center"} sx={{ padding: "50px", height: `calc(100vh - ${ELEMENT_SIZES.headerHeight.lg}px - ${footerHeight}px)` }}>
      {isComplete ?
        isComplete === "success" ?
          <Typography variant="h4">You have been unsubscribed.</Typography>
          :
          <Typography variant="h4" sx={{ maxWidth: 500 }}>An error occurred. Please reach out to <a href="mailto:hello@hailscale.com">hello@hailscale.com</a> for assistance in unsubscribing.</Typography>
        :
        <Typography variant="h4" sx={{ maxWidth: 500 }}>Unsubscribing...</Typography>
      }
    </Grid>
  )
}

export default Unsubscribe;