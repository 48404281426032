import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import PhotoModal from '../PhotoModal';
import { useInspection } from "../Provider";
import { COLORS } from '../../../constants/colors';
import { StyledLabel } from '../../Common/StyledElements';

const StyledContainer = styled.div`

  border: 1px solid ${COLORS.BLUE};
  border-radius: 10px;
  position: relative;
  height: 75px;
  @media (max-width: 960px) {  
    height: 45px;
  }

  input[type="file"] {
    font-size: 17px;
    color: #b8b8b8;
    opacity: 0;
    cursor: pointer;
    height: 75px;
    width: 100%

  }
  @media (max-width: 960px) {  
    input[type="file"] {
      height: 45px;
    }
  }
`;

const StyledAddIcon = styled(AddIcon)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
`;

const StyledSpan = styled.span`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  padding-left: 15px;
  font-size: 24px;
  color: ${COLORS.LIGHT_BLUE};
  @media (max-width: 960px) {  
    font-size: 16px;
  }
`;

export const Images = ({ showPropertyMgName }) => {
  const { state, addUploadPhotos } = useInspection();
  const phrase = showPropertyMgName ? <span>(3 photos required) <span className='text-red'>*</span></span> : <span>(optional)</span>;

  const onSubmitPhoto = (item) => {
    let base64String = "";
    var reader = new FileReader();

    reader.onload = function () {
      base64String = reader.result;
      let name = item[0].name.replace(/ /g, "_");

      addUploadPhotos([...state.images, { name: name, url: base64String }]);
    }
    reader.readAsDataURL(item[0]);
  }

  return (
    <div>
      {state.images.length > 5 ?
        <StyledLabel><span className='text-red'>Up to 5 photos are allowed</span></StyledLabel>
        :
        <StyledLabel>Upload photos {phrase}</StyledLabel>
      }
      <StyledContainer>
        <label>
          <StyledSpan>{state.images.length > 0 ? `${state.images.length} uploaded` : "Upload..."}</StyledSpan>
          <input type={"file"} onChange={(val) => onSubmitPhoto(val.target.files)} />
          <StyledAddIcon />
        </label>
      </StyledContainer>
      {
        state.images.map((item, i) => (
          <PhotoModal key={i} image={item} index={i} />
        ))}
      {/* <img src="./IMG_5747.HEIC" /> */}
    </div>
  );
}