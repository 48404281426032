const PAGE_LINKS = [
  { url: "https://hailscale.com/", title: "Home" },
  { url: "https://hailscale.com/hail-monitoring", title: "Hail Monitoring" },
  { url: "https://hailscale.com/how-it-works", title: "How it works" },
  { url: "https://hailscale.com/our-inspectors", title: "Our inspectors" },
  { url: "https://hailscale.com/about-us", title: "About us" },
  { url: "https://hailscale.com/faq", title: "FAQ" },
  { url: "https://hailscale.com/articles/how-does-hailscale-know-when-hail-strikes-my-roof", title: "How Does Hailscale know When Hail Strikes My Roof" },
  { url: "https://hailscale.com/articles/when-should-i-have-my-roof-inspected-for-hail-damage", title: "When Should I Have My Roof Inspected For Hail damage" },
  { url: "https://hailscale.com/articles/will-my-premiums-go-up-if-i-file-a-claim-for-hail-damage", title: "Will My Premiums Go Up If I File A Claim For Hail Damage" },
  { url: "https://hailscale.com/articles/what-does-hail-damage-look-like", title: "What Does Hail Damage Look Like" },
  { url: "https://hailscale.com/articles/how-can-i-confirm-if-i-have-hail-damage", title: "How Can I Confirm If I Have Hail Damage" },
  { url: "https://hailscale.com/articles/should-i-file-an-insurance-claim-for-hail-damage", title: "Should I File An Insurance Claim For Hail Damage" },
  { url: "https://hailscale.com/articles/how-much-does-it-costs-to-file-a-claim-for-hail-damage", title: "How Much Does It Cost To File A Claim For Hail Damage" },
  { url: "https://hailscale.com/articles/will-my-insurance-company-replace-my-roof-for-free", title: "Will My Insurance Company Replace My Roof For Free" },
  { url: "https://hailscale.com/articles/who-are-noaas-trained-hail-spotters", title: "Who Are NOAAS Trained Hail Spotters" },
  { url: "https://hailscale.com/articles/who-should-inspect-my-roof-for-hail-damage", title: "Who Should Inspect My Roof For Hail Damage" },
  { url: "https://hailscale.com/articles/what-if-my-deductible-is-too-high-to-file-a-claim", title: "What If My Deductible Is Too High To File A Claim" },
];
const Sitemap = () => {
  return (
    <div className="text-align-left p-a-50">
      {PAGE_LINKS.map(link => (
        <li key={link.url} className="m-t-10">
          <a href={link.url}>{link.title}</a>
        </li>
      ))}
    </div>
  );
}
export default Sitemap;