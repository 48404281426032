import { Fragment, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { AdminInput } from "../Common/AdminInput";
import editBlack from "../../assets/edit_icon_black.png";
import cancel_icon from "../../assets/cancel_icon.png";
import save_icon from "../../assets/save_icon.png";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import styled from "styled-components";
import { updateDirectory, addToDirectory, deleteFromDirectory } from "../../../../services";
import { COLORS } from "../../../../constants/colors";
import Typography from "@mui/material/Typography";

const SharedStyled = `
 
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    transform: scale(1.07);
  }
`;

const StyledImg = styled.img`
  ${SharedStyled}
  width: 20px;
  height: 20px;
`;

const CloseImg = styled(CloseRoundedIcon)`
${SharedStyled}
width: 30px;
height: 30px;
color: ${COLORS.RED};
margin-left: 5px;
`;

const StyledDiv = styled.div`
  position: absolute;
  right: -47px;
  width: 45px;
  display: flex;
  height: 100%;
  align-items: center;
`;

const StyledAlert = styled(Grid2)`
  position: absolute;
  top: 5px;
  right: 0;
  min-width: 150px;
  width: max-content;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  z-index: 1;
  background-color: ${COLORS.RED};
`;

const StyledConfirm = styled(Typography)`
  color: ${COLORS.WHITE};
  text-decoration: underline;
  margin-top: 10px !important;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    transform: scale(1.07);
  }
`;

const PersonComponent = ({ item, fieldRep, cancelPerson, partnerContractor, modifyPerson }) => {
  const [canEdit, setCanEdit] = useState(false);

  const initialState = {
    _id: item._id,
    fullName: item.fullName ? item.fullName : "no name",
    title: item.title,
    email: item.email ? item.email : "no email",
    phoneNumber: item.phoneNumber ? item.phoneNumber : "no phone number",
    partnerContractor: partnerContractor
  };

  const newPersonState = {
    _id: item._id,
    fullName: "",
    title: fieldRep ? "Field Rep" : item.title,
    email: "",
    phoneNumber: "",
    partnerContractor: partnerContractor
  }

  const [personDetails, setPersonDetails] = useState(item.isAdd ? newPersonState : initialState);
  const [showAlert, setShowAlert] = useState(false);

  const updatePersonDetails = async () => {
    const payload = { type: 'update', details: personDetails, fieldRep: fieldRep };
    await updateDirectory(payload);
    modifyPerson(payload);
    setCanEdit(false);
  }

  const addPerson = async () => {
    const payload = { type: 'add', details: personDetails, fieldRep: fieldRep };
    await addToDirectory(payload);
    modifyPerson(payload);
  }

  const confirmRemovePerson = async () => {
    const payload = { type: 'delete', details: personDetails, fieldRep: fieldRep };
    await deleteFromDirectory(payload);
    setShowAlert(false);
    modifyPerson(payload);
  }

  const alertWindow = <StyledAlert container sx={{ display: showAlert ? "block" : "none" }}>
    <Typography variant="h5" >Delete {personDetails.title} {personDetails.fullName}?</Typography>
    <StyledConfirm variant="h6" onClick={() => confirmRemovePerson()}>Yes, delete them</StyledConfirm>
    <StyledConfirm variant="h6" onClick={() => setShowAlert(false)}>I changed my mind</StyledConfirm>
  </StyledAlert>;

  return (
    <Grid2 container xs={12} columnSpacing={1} sx={{
      position: "relative",
      marginTop: "2px"
    }} className="text-align-left">
      <Grid2 xs={3}>
        <AdminInput
          isDisabled={item.isAdd ? false : !canEdit}
          classNames=""
          value={item.isAdd ? personDetails.fullName : canEdit ? personDetails.fullName : initialState.fullName}
          onChange={(val) => setPersonDetails({ ...personDetails, fullName: val.target.value })}
        />
      </Grid2>
      <Grid2 xs={3}>
        <AdminInput
          isDisabled={fieldRep ? true : item.isAdd ? false : !canEdit}
          classNames=""
          value={fieldRep ? "Field Rep" : canEdit ? personDetails.title : initialState.title}
          onChange={(val) => setPersonDetails({ ...personDetails, title: val.target.value })}
        />
      </Grid2>
      <Grid2 xs={3}>
        <AdminInput
          isDisabled={item.isAdd ? false : !canEdit}
          classNames=""
          type={"email"}
          value={item.isAdd ? personDetails.email : canEdit ? personDetails.email : initialState.email}
          onChange={(val) => setPersonDetails({ ...personDetails, email: val.target.value })}
        />
      </Grid2>
      <Grid2 xs={3}>
        <AdminInput
          isDisabled={item.isAdd ? false : !canEdit}
          classNames=""
          type={"tel"}
          value={item.isAdd ? personDetails.phoneNumber : canEdit ? personDetails.phoneNumber : initialState.phoneNumber}
          onChange={(val) => setPersonDetails({ ...personDetails, phoneNumber: val.target.value })}
        />
      </Grid2>
      <StyledDiv>
        {!item.isAdd && !canEdit ?
          <Fragment>
            <StyledImg
              onClick={() => setCanEdit(!canEdit)}
              src={editBlack}
              loading="lazy"
              alt=""
            />
            <Fragment>
              <CloseImg
                onClick={() => setShowAlert(true)}
              />
              {alertWindow}
            </Fragment>
          </Fragment>
          :
          <Fragment>
            <StyledImg
              onClick={() => {
                item.isAdd ? addPerson() :
                  updatePersonDetails()
              }}
              src={save_icon}
              loading="lazy"
              alt=""
            />
            <Grid2 xs={6}>
            </Grid2>
            <StyledImg
              onClick={() => {
                item.isAdd ? cancelPerson(fieldRep) :
                  setCanEdit(!canEdit)
              }}
              src={cancel_icon}
              loading="lazy"
              alt=""
            />
          </Fragment>
        }
      </StyledDiv>
    </Grid2>
  )
}
export default PersonComponent;