import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useInspection } from "../Provider";

const StyledToggleButton = styled(ToggleButton)`
  &.Mui-selected {
    background-color: #0159c2 !important;
    color: white !important;
  }
`;

export const HasHailDmg = () => {
  const { state, addHasHailDmg } = useInspection();

  return (
    <Fragment>
      <Typography
        variant="subtitle2"
        className="text-align-left text-black m-t-20"
      >
        Was there any hail damage?
      </Typography>
      <ToggleButtonGroup
        value={state.hasHailDmg}
        exclusive
        className="fill-width border-radius-8 m-t-8 "
        onChange={(e, val) => {
          if (val !== null) addHasHailDmg(val);
        }}
      >
        <StyledToggleButton
          value={true}
          className="text-black font-size-20 fill-width border border-radius-8 border-radius-right-none"
        >
          Yes
        </StyledToggleButton>
        <StyledToggleButton
          value={false}
          className="text-black font-size-20 fill-width border border-radius-8 border-radius-left-none"
        >
          No
        </StyledToggleButton>
      </ToggleButtonGroup>
    </Fragment>
  );
};
