import React, { useEffect, Fragment } from "react";
import { Helmet } from "react-helmet";
import { PageView } from "../Tracking";
import { ROUTES } from "../../constants/routes";
import { ArticleCard } from "./articleCard";
import withLocalContext from "../store/withLocalContext";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import { useScreenWidth } from "../Common/useScreenWidth";

export const FAQ = ({ context: { state } }) => {
  const navigate = useNavigate();
  const isMedium = useScreenWidth();

  useEffect(() => PageView(), []);

  return (
    <Fragment>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>Frequently Asked Questions</title>
        <link rel="canonical" href="https://hailscale.com/faq" />
        <meta name="description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Frequently Asked Questions" />
        <meta itemprop="description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta itemprop="image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.hailscale.com/faq" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Frequently Asked Questions" />
        <meta property="og:description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta property="og:image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Frequently Asked Questions" />
        <meta name="twitter:description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta name="twitter:image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Meta Tags Generated via http://heymeta.com --> */}
      </Helmet>
      <Grid container className={`${isMedium ? "m-t-50 p-l-90 p-r-90" : "m-t-45"}`} style={{ minHeight: window.innerHeight + 1 }}>
        <Grid item xs={12} className={isMedium ? "m-b-55" : "m-b-25"}>
          <Typography variant="h2" className="text-blue margin-auto">Resources & Help</Typography>
          <Typography variant="subtitle1" className="text-blue m-t-10">Our roofing and insurance restoration experts address common homeowner concerns</Typography>
        </Grid>
        <Grid item container xs={12} className="text-align-left">
          {state.articles ?
            state.articles.map((element, i) => (
              state.articles.map((article) => {
                return (
                  i + 1 === Number(article.acf.sort_order) ?
                    <ArticleCard
                      key={article.slug}
                      article={article}
                    />
                    :
                    ""
                )
              })
            ))
            : ""
          }
        </Grid>
        <Grid item xs={12} className="m-b-86">
          <Button
            sx={{ fontSize: `${isMedium && "24px !important"}` }}
            onClick={() => navigate(ROUTES.LANDING_PAGE)}
          >Back to Home</Button>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default withLocalContext(FAQ);