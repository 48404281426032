import React, { useReducer } from 'react';
import { initialState } from './initialState';
import { reducer } from './reducer';

export const AdminContext = React.createContext({});

export const Ctx = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState
  });

  return (
    <AdminContext.Provider data-test="admin-context" value={{ state, dispatch }}>
      {children}
    </AdminContext.Provider>
  );
};

export default Ctx;
