import React, { useState, useEffect } from "react";
import { Typography, Grid, useTheme, useMediaQuery } from "@mui/material";
import { Link } from 'react-router-dom';
import styled from "styled-components";

const StyledImg = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  aspect-ratio: 1 / .65;
  object-position: top;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;

  h5 {
    font-size: 20px !important;
    @media (max-width: 960px) {
      font-size: 16px !important;
    }
  }

  p {
    font-size: 16px !important;
    a {
      color: black;
      text-decoration: none;
      font-size: 16px !important;
    }
    @media (max-width: 960px) {
      font-size: 14px !important;
      a {
        font-size: 14px !important;
      }
    }
  }
`;

const StyledSpan = styled.span`
  text-decoration: underline;
  margin-left: 5px;
`;

export const ArticleCard = ({ article }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  let title = article.title.rendered;
  let image = article.acf.image;
  let content = article.content.rendered;
  content = content.replace("<p>", "");
  content = content.replace("</p>", "");
  const [truncatedMsg, setTruncatedMsg] = useState(content);

  useEffect(() => {
    setTruncatedMsg(content.split(" ").slice(0, 25).join(" ").concat("..."));
  }, [content]);
  return (
    <Grid item container xs={12} md={6} lg={4} className={`p-l-32 p-r-32 ${isMedium ? "m-b-75" : "m-b-45"}`}>
      <StyledLink to={`/articles/${article.slug}`}>
        <Grid item xs={12} className={isMedium ? "m-b-20" : "m-b-10"}>
          <StyledImg src={image} alt={title} />
        </Grid>
        <Grid item xs={12} className={isMedium ? "m-b-15" : "m-b-10"} style={isMedium ? { minHeight: 85 } : {}}>
          <Typography variant="h5">{title}<StyledSpan className="text-blue">Read more</StyledSpan></Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" dangerouslySetInnerHTML={{ __html: truncatedMsg }}></Typography>
        </Grid>
      </StyledLink>
    </Grid>
  );
}

export default ArticleCard;