import React from "react";
import { STAGES } from "../../../../../constants/appointment_stages";
import withAdminContext from "../../../store/withAdminContext";
import CardWrapper from "../../Card/CardWrapper";
import HomeownerToFileApp from "./HomeownerToFileApp";

export const DHomeownerToFile = ({ context: { state } }) => {
  return (
    <div>
      {state.activeAppointments.homeownerToFile.appointments.map(app => {
        return app.appointmentStage === STAGES.HOMEOWNER_TO_FILE &&
          <CardWrapper key={app._id} stage={app.appointmentStage} child={<HomeownerToFileApp app={app} />} />
      })}
    </div>
  );
}

export default withAdminContext(DHomeownerToFile);