import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../../../../constants/colors";
import { ADMIN_ROUTES } from "../../../../../constants/routes";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import withAdminContext from "../../../store/withAdminContext";
import CardWrapper from "../../Card/CardWrapper";
import ActiveApp from "./ActiveApp";
import Typography from "@mui/material/Typography";


const SharedStyles = `
color: ${COLORS.BLUE};
text-align: left;
margin-left: 50px;
margin-bottom: -28px;
`;
const StyledLink = styled(NavLink)`
  text-decoration: none;
  display: flex;
  span {  
    ${SharedStyles}
    text-decoration: underline;
  }
`;

const StyledTypography = styled(Typography)`
${SharedStyles}
`;


export const AActive = ({ context: { state } }) => {
  if (state.areAppointmentsLoading) {
    return null;
  }

  const orderAppointments = [
    {
      appointments: state.activeAppointments.partnerNeeded.appointments,
      count: state.activeAppointments.partnerNeeded.count,
      title: "Contractor assignment needed ",
      url: ADMIN_ROUTES.PARTNER_NEEDED.route
    },
    {
      appointments: state.activeAppointments.fieldRepNeeded.appointments,
      count: state.activeAppointments.fieldRepNeeded.count,
      title: "Field Rep assignment needed ",
      url: ADMIN_ROUTES.FIELD_REP_NEEDED.route
    },
    // flesh this out some more ↓
    {
      appointments: state.activeAppointments.awaitingInspection.appointments,
      count: state.activeAppointments.awaitingInspection.count,
      title: "Awaiting Inspection ",
      url: ""
    },
    {
      appointments: state.activeAppointments.inspectionOverdue.appointments,
      count: state.activeAppointments.inspectionOverdue.count,
      title: "Inspection report OVERDUE ",
      url: ADMIN_ROUTES.MISSING_INSPECTION.route
    },
    {
      appointments: state.activeAppointments.homeownerToFile.appointments,
      count: state.activeAppointments.homeownerToFile.count,
      title: "Awaiting on Homeowner to File ",
      url: ADMIN_ROUTES.HOMEOWNER_TO_FILE.route
    },
    {
      appointments: state.activeAppointments.awaitingAdjsDate.appointments.concat(state.activeAppointments.awaitingAdjsOutcome.appointments),
      count: state.activeAppointments.awaitingAdjsDate.count + state.activeAppointments.awaitingAdjsOutcome.count,
      title: "Manage Claim Status ",
      url: ""
    }
  ]
  return (
    <Grid2 container className="m-t-50">
      {orderAppointments.map(app => (
        <Grid2 key={app.title} container sx={{ minHeight: "50px" }} xs={12}>
          {app.url === "" ?
            <StyledTypography
              className={"font-22-medium-black"}
            >
              <span className="m-r-5">{app.title}</span>{`(${app.count})`}
            </StyledTypography>
            :
            <StyledLink
              className={"font-22-medium-blue"}
              to={`../${app.url}`}
            >
              <span className="m-r-5">{app.title}</span>{`(${app.count})`}
            </StyledLink>
          }
          {app.appointments.map(app => (
            <CardWrapper key={app._id} stage={app.appointmentStage} classNames={"fill-width"} child={<ActiveApp app={app} partners={state.partnerNames} />} />
          ))}
        </Grid2>
      ))}
    </Grid2>
  );
}

export default withAdminContext(AActive);