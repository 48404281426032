import React, { Fragment, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import moment from 'moment';
import Typography from "@mui/material/Typography";
import { AdminButton } from "../../../Common/AdminButton";
import styled from "styled-components";

const StyledBody1Cont = styled(Grid2)`
  p {
    margin: 5px 0;
  }
`;

export const ActiveApp = ({ app }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const spanClasses = "font-size-16 text-font-fam-colfax-medium uppercase";

  return (
    <Grid2 container className="text-align-left">
      <StyledBody1Cont container flexDirection={"column"} justifyContent={"space-evenly"} xs={12} md={4} className="p-t-18 p-b-18">
        <Typography className="font-size-26 text-font-fam-colfax-medium uppercase m-b-20">{app.owner[0].fullName}</Typography>
        <Typography variant="body1"> {app._id}</Typography>
        <Typography variant="body1"> {app.appointmentStage}</Typography>
        <Typography variant="body1"><span className={spanClasses}>Date:</span> {moment(app.inspectionDate).format('MMMM DD, YYYY')}</Typography>
        <Typography variant="body1"><span className={spanClasses}>Time:</span> {app.timeSlot}</Typography>
        <Typography variant="body1"><span className={spanClasses}>Rating:</span> {app.hscRating}</Typography>
        {isExpanded &&
          <Fragment>
            <Typography variant="body1"><span className={spanClasses}>Hail duration:</span> {app.totalHailDurationOverRoofAge}</ Typography>
            <Typography variant="body1"><span className={spanClasses}>Hail storms:</span> {app.totalHailCountOverRoofAge}</Typography>
            <Typography variant="body1"><span className={spanClasses}>Damage amount:</span> {app.dmgAmount}</Typography>
          </Fragment>
        }
      </StyledBody1Cont>
      <StyledBody1Cont xs={12} md={4} container flexDirection={"column"} justifyContent={"space-evenly"} className="p-t-18 p-b-18 p-l-45 border-l-LIGHT_BLUE">
        <Typography variant="body1"><span className={spanClasses}>Contractor:</span> {app.associated_partner_contractor_id === "" ? "Unassigned" : app.associated_partner_contractor_id}</Typography>
        <Typography variant="body1"><span className={spanClasses}>Address:</span> {app.property[0].address}</Typography>
        <Typography variant="body1"><span className={spanClasses}>Insurance:</span> {app.insuranceProvider}</Typography>
        {isExpanded &&
          <Fragment>
            <Typography variant="body1"><span className={spanClasses}>Recommendation:</span> {app.recommendFilingClaim}</Typography>
            <Typography variant="body1">
              <span className={spanClasses}>Hail locations:</span>
              {app.hailLocations.map((loc, i) => {
                return <span>{loc}{i === app.hailLocations.length - 1 ? "" : ","} </span>
              })}
            </Typography>
          </Fragment>
        }
      </StyledBody1Cont>
      <StyledBody1Cont xs={12} md={4} container flexDirection={"column"} justifyContent={"space-evenly"} className="p-t-18 p-b-18 p-l-45 border-l-LIGHT_BLUE">
        <Typography variant="body1"><span className={spanClasses}>Approval likelihood:</span> {app.approvalLikelihood}</Typography>
        <Typography variant="body1"><span className={spanClasses}>Filed:</span> {app.filingStatus}</Typography>
        <Typography variant="body1"><span className={spanClasses}>Claim approval:</span> {app.claimOutcome}</Typography>
        {isExpanded &&
          <Fragment>
            <Typography variant="body1"><span className={spanClasses}>Replacement value:</span> {app.replacementValue}</Typography>
            <Typography variant="body1"><span className={spanClasses}>Adjustment date:</span> {moment(app.adjsDate).format('MMMM DD, YYYY')}</Typography>
            <Typography variant="body1"><span className={spanClasses}>Build date:</span> {moment(app.buildDate).format('MMMM DD, YYYY')}</Typography>
            <Typography variant="body1"><span className={spanClasses}>Appointment status:</span> {app.appointmentStatus}</Typography>
          </Fragment>
        }
        <Grid2 xs={12}>
          <AdminButton
            classNames={"m-t-10"}
            text={isExpanded ? "collapse details" : "view all details"}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </Grid2>
      </StyledBody1Cont>
    </Grid2 >
  );
}

export default ActiveApp;