import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Fragment } from "react";
import styled from "styled-components";
import moment from "moment";
import { COLORS } from "../../../../constants/colors";
import DateImg from "../../../../assets/Date_Black.webp";
import AdminSelect from "./AdminSelect";

const borderwidth = 2;
const paddingLR = 5;

const classnames = [
  "grey-background",
];
const spanClasses = `
font-size-16
text-font-fam-colfax-medium
uppercase
p-l-10
`;

const sharedStyles = `
border: ${borderwidth}px solid ${COLORS.LIGHT_BLUE};
border-radius: 5px;
padding: 5px ${paddingLR}px;
font-size: 16px;
font-family: Colfax Regular !important;
width: calc(100% - ${(borderwidth + paddingLR) * 2}px );
overflow-wrap: anywhere;

&:disabled {
  border: ${borderwidth}px solid ${COLORS.GREY};
  color: ${COLORS.BLACK};
}
`;

const StyledTextarea = styled.textarea`
${sharedStyles}
resize: none;
`;

const StyledInput = styled.input`
${sharedStyles}
`;

const CalImg = styled.img`
width: 16px;
height: auto;
`;

const StyledDatePicker = styled(KeyboardDatePicker)`
${sharedStyles}
  height: 33px;
  background-color: ${COLORS.GREY} !important;
  input {
    font-size: 16px !important;
    background-color: ${COLORS.GREY};
    padding-left: 5px !important;
  }
  fieldset {
    border-radius: 5px !important;
    ${(props) => (props.disableborder === "true" && `
    border: none;
    `)}
    }
  }
  button {
    padding: 0;
  }
`;


export const AdminInput = ({ classNames, value, onChange, type, isDisabled, label, selectOptions }) => {
  return (
    <Fragment>
      {type === "textarea" ?
        <Fragment>
          {label && <span className={spanClasses}>{label}</span>}
          <StyledTextarea disabled={isDisabled ? isDisabled : false} value={value} onChange={onChange} className={[...classnames, classNames]} />
        </Fragment>
        : type === "calendar" ?
          <Fragment>
            {label && <span className={spanClasses}>{label}</span>}

            <MuiPickersUtilsProvider utils={MomentUtils}>
              <StyledDatePicker
                fullWidth
                disableborder={String(isDisabled)}
                disabled={isDisabled ? isDisabled : false}
                autoOk
                variant="inline"
                className="bg-white"
                inputVariant="outlined"
                value={value}
                format="MMMM DD, YYYY"
                onChange={onChange}
                minDate={value === "" ? moment().format() : ""}
                keyboardIcon={isDisabled ? "" : <CalImg src={DateImg} alt="" />}
              />
            </MuiPickersUtilsProvider>
          </Fragment>
          : type === "select" ?
            <Fragment>
              {label && <span className={`${spanClasses} -m-l-10`}>{label}</span>}
              <AdminSelect
                value={value}
                onChange={onChange}
                isDisabled={isDisabled}
              />
            </Fragment>
            :
            <Fragment>
              {label && <span className={spanClasses}>{label}</span>}
              <StyledInput type={type} disabled={isDisabled ? isDisabled : false} value={value} onChange={onChange} className={[...classnames, classNames]} />
            </Fragment>
      }
    </Fragment>
  )
}