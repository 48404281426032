import React from "react";
import { StyledLabel } from "./StyledElements";
import { TextField } from "@mui/material";

export const InputField = ({ text, placeholder, textValue, change, type, isDisabled, isRequired }) => {

  return (
    <>
      {text === "none" ? "" :
        <StyledLabel>{text}<span className="text-red" >{!isRequired ? "" : "*"}</span></StyledLabel>
      }
      <TextField
        fullWidth
        required
        variant="outlined"
        value={textValue}
        placeholder={placeholder}
        onChange={change}
        type={type}
        disabled={isDisabled}
      />
    </>
  );
};

export default InputField;
