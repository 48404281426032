import React, { Fragment } from "react";
import styled from "styled-components";
import { COLORS } from "../../../constants/colors";
import { StyledLabel } from "../../Common/StyledElements";
import { useInspection } from "../Provider";

const StyledInput = styled.textarea`
  height: 74px;
  width: calc(100% - 60px);
  border: 1px solid ${COLORS.BLUE};
  border-radius:10px;
  font-family: Colfax Regular;
  font-size: 16px;
  padding: 24px 30px;
  &::placeholder {
    color: ${COLORS.LIGHT_BLUE};
    font-style: italic;
  }
  &:focus-visible {
    border: 2px solid ${COLORS.BLUE};
    outline: none;
  }

  @media (max-width: 960px) {  
    font-size: 16px;
    padding: 15px;
    width: calc(100% - 30px);
  }
`;

export const AdditionalComments = ({ showPropertyMgName }) => {
  const { state, addAdditionalComments } = useInspection();
  const characters = state.additionalComments.trim().length;
  const charsLeft = characters >= 50 ? 0 : 50 - characters;
  const label = showPropertyMgName ?
    <StyledLabel>Short summary of findings {characters > 0 ? `(${charsLeft} characters left)` : "(50 character minimum)"} <span className="text-red">*</span></StyledLabel>
    :
    <StyledLabel>Additional comments</StyledLabel>;

  return (
    <Fragment>
      {label}
      <StyledInput
        type={"text"}
        rows="5"
        placeholder="use this box to tell us why the filing status is incomplete, why homeowner declined to file, or any other context..."
        value={state.additionalComments}
        onChange={(e) => addAdditionalComments(e.target.value)}
      />
    </Fragment>
  );
};
