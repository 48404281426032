import React, { useState } from "react";
// import { isMobile } from "react-device-detect";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
// import { MenuItem } from "@mui/material";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputAdornment from "@material-ui/core/InputAdornment";
import styled from "styled-components";
import withLocalContext from "../store/withLocalContext";
import { addRatingParams } from "../store/actions";
import { getRatingParams } from "../store/selectors";
import time from "../../assets/Time_Black.webp";
import { COLORS } from "../../constants/colors";

const StyledFormControl = styled(FormControl)`
  border-radius: 8px;
  min-width: 237px;
  width: 100%;

  .MuiSelect-root {
    span:after {
      content: "Age of roof";
      color: #000000;
    }
  }

  > div > .MuiOutlinedInput-notchedOutline {
    ${props => props.isoutlined === "true" ? "" : `
    border-color: transparent !important;
    `}
  }

  .MuiOutlinedInput-adornedStart {
    padding-left: 5px;
  }

  .MuiSelect-outlined.MuiSelect-outlined {
    padding-left: 0 !important;
    line-height: 75px;
    font-size: 19px !important;
    @media (max-width: 960px) {
      line-height: 45px;
      font-size: 16px !important;
    }
  }
  @media (max-width: 960px) {  
    min-width: 250px;
    max-width: 250px;
  }
`;

const TimeImg = styled.img`
width: 31px;
height: auto;
@media (max-width: 600px) {  
  width: 16px;
}
`;

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
 color: black !important;
 font-size: 35px;
 top: 20px;
 @media (max-width: 959px) {
  top: 12px;
  font-size: 25px;
 }
`;

const firstLiStyle = {
  opacity: 1,
  backgroundColor: COLORS.GREY
}

export const options = [
  {
    description: ["Note: roof age may be less", <br key={1} />, "than the home’s age"],
    value: 0,
  },
  {
    description: "less than 1 year",
    value: 1,
  },
  {
    description: "1-3 years",
    value: 3,
  },
  {
    description: "4-6 years",
    value: 6,
  },
  {
    description: "7-9 years",
    value: 9,
  },
  {
    description: "10+ years",
    value: 10,
  }
];

export const RoofAgeSelect = ({ outline, context: { dispatch, state } }) => {
  const [isOpen, setIsOpen] = useState(false);

  // const OptionType = isMobile ? "option" : MenuItem;
  const OptionType = MenuItem;
  const onChangeEvt = (val) => {
    dispatch(
      addRatingParams({
        ...getRatingParams(state),
        roofAge: val.target.value,
      })
    );
  };

  return (
    <StyledFormControl isoutlined={String(outline)} variant="outlined" className="bg-white">
      <Select
        // native={isMobile}
        open={isOpen}
        value={getRatingParams(state).roofAge}
        IconComponent={StyledExpandMoreIcon}
        onClose={() => {
          setIsOpen(false);
        }}
        onOpen={() => {
          setIsOpen(true);
        }}
        onChange={onChangeEvt}
        style={{
          textAlign: "left",
          color: getRatingParams(state).roofAge === "" ? "#B6C8DD" : "inherit",
        }}
        startAdornment={
          <InputAdornment position="start" className="m-l-10 p-r-5">
            <TimeImg src={time} alt="" />
          </InputAdornment>
        }
      >
        {options.map((opt, i) => (
          <OptionType style={i === 0 ? firstLiStyle : {}} value={opt.value} key={i} disabled={i === 0 ? true : false}>
            {opt.description}
          </OptionType>
        ))}
      </Select>
    </StyledFormControl >
  );
};

export default withLocalContext(RoofAgeSelect);
