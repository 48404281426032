import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ROUTES } from "../../constants/routes";
import { submitOutOfArea, postError } from "../../services";
import { HailScaleCheckbox } from "../Common/HailScaleCheckbox";
import InputField from "../Common/InputField";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const StyledParagraph = styled(Typography)`
  font-size: 20px !important;
  line-height: 35px !important;
  padding: 0 10px;
  @media (max-width: 600px) {
    font-size: 16px !important;
    line-height: 22px !important;
  }
`;

const OutOfAreaModalContent = ({ zipcode, address, closeModal, deviceData }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const [isChecked, setIsChecked] = useState(false);
  const [email, setEmail] = useState(false);

  const setCheckBox = (checkValue) => {
    setIsChecked(checkValue);
  };

  const SubmitSignUp = async () => {
    if (!email) {
    } else {
      const payload = {
        email: email,
        promos: isChecked,
        address: address,
        zipcode: zipcode,
      };
      try {
        await submitOutOfArea(payload);
      } catch (err) {
        console.log('error', err);
        postError({ error: err, device: deviceData });
        navigate(ROUTES.ERROR);
      }
      closeModal();
    }
  };

  const paragraph = `Hailscale is activating service in your area soon.  Enter your email to be notified when we are live in ${zipcode}.`
  return (
    <Grid container justifyContent="center" className={`${isMedium ? "p-l-80 p-r-80 p-b-24" : ""} text-align-center`}>
      <Typography variant="h2" className="m-b-12">Coming Soon!</Typography>
      <StyledParagraph>{paragraph}</StyledParagraph>
      <Grid item container xs={11} className={isMedium ? "" : "text-align-left"}>
        <HailScaleCheckbox setCheckBox={setCheckBox} label="Send me promos and hailscale announcements" />
      </Grid>
      <Grid item container xs={12} wrap="nowrap" spacing={3} className={isMedium ? "m-t-20 m-b-36" : "m-t-10 m-b-10"}>
        <Grid item>
          <InputField
            placeholder={"your email..."}
            change={(e) => setEmail(e.target.value)}
            text="none"
          />
        </Grid>
        <Grid item>
          <Button
            onClick={() => SubmitSignUp()}
          >Submit</Button>
        </Grid>
      </Grid>
      <Button
        variant="tan"
        onClick={() => closeModal()}
      >No thanks</Button>
    </Grid>
  );
}

export default OutOfAreaModalContent;