import React from "react";
import Grid from "@material-ui/core/Grid";
import GaugeOmeter from "../../GaugeOmeter";

export const RatingDial = () => {

  return (
    <Grid justifyContent="center" container className="p-b-5">
      <Grid item xs={12}>
        <GaugeOmeter />
      </Grid>
    </Grid>
  );
};

export default RatingDial;
