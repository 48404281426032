import get from "lodash/get";
import moment from "moment";

export const getReport = (store) => get(store, "report", {});
export const getRatingParams = (store) => get(store, "ratingParams", {});
export const getAddressParam = (store) =>
  get(getRatingParams(store), "address", "");
export const getRoofAgeParam = (store) =>
  get(getRatingParams(store), "roofAge", "");
export const getIsAuthorized = () => {
  const authorizedUser = JSON.parse(localStorage.getItem("authorization"));
  if (authorizedUser) {
    return moment().isBefore(authorizedUser.expiresIn);
  }
  return false;
};
