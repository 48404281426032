import React, { useState } from "react";
import { Grid } from "@material-ui/core";

export const Upload = () => {
  const [fileData, setFileData] = useState([]);
  const headerArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  var obj_csv = {
    size: 0,
    dataFile: []
  };

  function readImage(input) {
    if (input.files && input.files[0]) {
      let reader = new FileReader();
      reader.readAsBinaryString(input.files[0]);
      reader.onload = function (e) {
        obj_csv.size = e.total;
        obj_csv.dataFile = e.target.result
        parseData(obj_csv.dataFile)

      }
    }
  }

  function parseData(data) {
    let csvData = [];
    let lbreak = data.split("\n");
    lbreak.forEach(res => {
      csvData.push(res.split(","));
    });
    console.table(csvData);
    setFileData(csvData);
  }

  return (
    <div style={{ paddingTop: 100 }}>
      <input type="file" id="uploadfile" onChange={(e) => readImage(e.target)} />
      {fileData.length > 0 &&
        <Grid container>
          <Grid container item xs={12}>
            {headerArr.map(header => {
              return <Grid item>{fileData[0][header]}</Grid>
            })}
          </Grid>
          {fileData.map((data, i) => {
            return i !== 0 &&
              <Grid container item xs={12}>
                {headerArr.map(header => {
                  return <Grid item>{data[header]}</Grid>
                }
                )}
              </Grid>
          })}
        </Grid>

      }
    </div>
  );
}

export default Upload;