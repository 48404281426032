import React from "react";
import { useInspection } from "../Provider";
import BasicSelect from "../../Common/HSCSelect";

export const ROOF_PITCHES = [
  {
    description: "Walkable",
    value: "Walkable"
  },
  {
    description: "Steep",
    value: "Steep"
  },
  {
    description: "Steep and Tall",
    value: "Steep and Tall"
  }
];

export const RoofPitch = () => {
  const { state, addRoofPitch } = useInspection();

  return (
    <BasicSelect
      text="Roof Pitch"
      label="Choose..."
      selectValue={state.roofPitch}
      change={(val) => addRoofPitch(val.target.value)}
      listMap={ROOF_PITCHES}
    />
  );
};
