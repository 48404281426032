import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getPropertyManagers } from "../../../services";
import { useAppointment } from "../Provider";
import { InputLabel, Box } from '@mui/material';
import { StyledLabel, StyledFormControl, StyledSelect, StyledMenuItem } from "../../Common/StyledElements";
import styled from "styled-components";

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
 color: black !important;
 font-size: 35px;
 @media (max-width: 959px) {
  font-size: 25px;
 }
`;

export const PropertyManager = () => {
  const { state, addPropertyManager } = useAppointment();
  const [propertyManagers, setPropertyManager] = useState([]);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    fetchPropertyManagers();
  }, []);

  const fetchPropertyManagers = async () => {
    try {
      const res = await getPropertyManagers();
      setPropertyManager([
        { _id: "not_applicable", companyNameTradeName: "Not Applicable" },
        ...res.data.sort((a, b) => a.companyNameTradeName.toLowerCase().localeCompare(b.companyNameTradeName.toLowerCase())),
        { _id: "other", companyNameTradeName: "Other" },
      ]);
    } catch (err) {
      setHasError(true);
    }
  };

  return (
    <Box>
      <StyledLabel>
        Property Manager<span className="text-red">*</span>
      </StyledLabel>
      <StyledFormControl fullWidth>
        <InputLabel id={`simple-select-property-manager`}>property manager...</InputLabel>
        {hasError ? (
          <Typography
            variant="subtitle2"
            className="text-align-left text-red m-b-8"
          >
            There was an issue fetching property managers, please reload page or
            try again later.
          </Typography>
        ) : (
          <StyledSelect
            labelId="simple-select-property-manager"
            id="property-manager-select"
            IconComponent={StyledExpandMoreIcon}
            label={"property manager..."}
            value={state.companyNameTradeName}
            onChange={(val) => addPropertyManager(val.target.value)}
          >
            {propertyManagers.map((opt, i) => (
              <StyledMenuItem
                style={
                  {
                    border: `1px solid`,
                    borderBottom: i === propertyManagers.length - 1 ? '1px solid' : 'none',
                    borderBottomRightRadius: i === propertyManagers.length - 1 ? 10 : 0,
                    borderBottomLeftRadius: i === propertyManagers.length - 1 ? 10 : 0,
                  }
                }
                value={opt}
                key={opt.companyNameTradeName} >
                {opt.companyNameTradeName}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        )
        }
      </StyledFormControl >
    </Box>
  );
};
