import React, { Fragment } from "react";
import { ROUTES } from "../../constants/routes.js";
import withLocalContext from "../store/withLocalContext.js";
import Grid from "@material-ui/core/Grid";
import { Grid as Gridsx } from '@mui/material';
import Typography from "@mui/material/Typography";
import MainPageBG from "./Background.js";
import AddressLookupInput from "../AddressLookupInput";
import RoofAgeSelect from "../RoofAgeSelect";
import SubmitReportBtn from "./SubmitReportBtn";
import { getAddressParam, getRoofAgeParam } from "../store/selectors.js";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { postError } from "../../services";
import styled from "styled-components";
import { COLORS } from "../../constants/colors.js";
import { useNavigate } from "react-router-dom";

const StyledHeroText = styled(Typography)`
  margin: auto;
  margin-bottom: 36px;
  font-family: Colfax Bold;
  text-align: left;
  max-width: 1000px;
  line-height: 50px !important;
  display: flex;
  flex-direction: column;
  @media (max-width: 960px) {
    align-items: center;
    text-align: center;
    padding: 0;
    max-width: fit-content;
    margin: auto;
    font-size: 20px !important;
    line-height: 25px !important;
  }
`;

const StyledPrivacy = styled(Typography)`
  font-size: 24px !important;
  color: ${COLORS.WHITE};
  font-style: italic;
  text-align: left;
  margin: auto;
  max-width: 1000px;
  margin-top: 17px;
  @media (max-width: 959px) {
    font-size: 16px !important;
      max-width: 250px;
  }
`;

export const TopSection = ({ context: { state } }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  const runReport = async () => {
    try {
      if (getAddressParam(state) && getRoofAgeParam(state)) {
        navigate(ROUTES.LOADING_REPORT);
      }
    } catch (err) {
      console.log("error: ", err);
      postError({ error: err, device: state.deviceData });
      navigate(ROUTES.ERROR);
    }
  }

  const HeroText = <Fragment><span className={`text-font-fam-colfax-bold ${isMedium ? "font-size-45" : "m-b-10 font-size-30"}`}>We detect hail damage.</span><span className={`text-font-fam-colfax-regular ${isMedium ? "font-size-45" : "font-size-20 width-280"}`}>Get your home’s damage rating in <span onClick={() => runReport()}>10 seconds...</span ></span></Fragment>;

  return (
    <div>
      <MainPageBG>
        <Grid
          className="top_section"
          container
          justifyContent="center"
          style={{ maxWidth: 1070 }}
        >
          <Grid
            item
            container
            justifyContent={isMedium ? "flex-start" : "center"}
            xs={12}
          >
            <StyledHeroText
              className={`text-white`}
              variant="h1"
              ismedium={String(isMedium)}
            >
              {HeroText}
            </StyledHeroText>
          </Grid>
          <Grid
            className={`m-t-24 ${isMedium ? "" : "max-width-320"}`}
            item
            xs={12}
            container
            justifyContent="flex-start"
          >
            <Gridsx className={`p-t-8 p-b-8`} item xs={12} md={6} sx={{ maxWidth: "510px", ".MuiFormControl-root": { maxWidth: "100%" } }}>
              <AddressLookupInput />
            </Gridsx>
            <Gridsx className={`p-t-8 p-b-8`} item xs={8} md={3} sx={{ ".MuiFormControl-root": { maxWidth: "100%" } }}>
              <RoofAgeSelect />
            </Gridsx>
            <Grid className="p-t-8 p-b-8" item xs={4} md={3}>
              <SubmitReportBtn />
            </Grid>
            <Grid container item xs={12} justifyContent="flex-start">
              <StyledPrivacy>Privacy Pledge: Hailscale will never sell or market your data.</StyledPrivacy>
            </Grid>
          </Grid>
        </Grid>
      </MainPageBG>
    </div >
  );
};

export default withLocalContext(TopSection);