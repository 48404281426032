import React from "react";
import withAdminContext from "../../../store/withAdminContext";
import CardWrapper from "../../Card/CardWrapper";
import ManageClaimStatusApp from "./ManageClaimStatusApp";

export const EManageClaimStatus = ({ context: { state } }) => {
  return (
    <div>
      {state.activeAppointments.awaitingAdjsDate.appointments.map(app => {
        return <CardWrapper key={app._id} stage={app.appointmentStage} child={<ManageClaimStatusApp app={app} />} />
      })}
      {state.activeAppointments.awaitingAdjsOutcome.appointments.map(app => {
        return <CardWrapper key={app._id} stage={app.appointmentStage} child={<ManageClaimStatusApp app={app} />} />
      })}
    </div>
  );
}

export default withAdminContext(EManageClaimStatus);