import React, { Fragment, useState } from "react";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import { COLORS } from "../../constants/colors";
import { useInspection } from "./Provider";
import { removePhotoChunks } from "../../services";

const StyledPaper = styled(Paper)`
  border-radius: 10px;
  outline: none;
  width: 100%;
  overflow: scroll;
  max-height: calc(100vh);
  background-color: #00000000 !important;
  box-shadow: none !important;
  `;

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000 !important;
  background-color: #0E5CBFB0;
`;

const StyledIconBtn = styled(IconButton)`
  padding: 0;
`;

const StyledSVG = styled(CloseIcon)`
  font-size: 50px;
  color: ${COLORS.WHITE} !important;
`;

const StyledRemoveIcon = styled(CloseIcon)`
  font-size: 20px;
  width: 25px;
  color: ${COLORS.BLACK} !important;
  cursor: pointer;
  &:hover {
    font-size: 25px;
  }
`;

const StyledImgDiv = styled.div`
  background-image: url(${(props) => (props.image)});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: calc(100vh - 100px);
  width: calc(100vw - 50px);
`;

const StyledImageCon = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 14px;
  div {
    display: flex;
    gap: 14px;
    cursor: pointer;

    img {
      width: 50px;
      height: 50px;
      border-radius: 2px;
      object-fit: cover;
    }
  }
`;

export const PhotoModal = ({ image, index }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { state, addUploadPhotos } = useInspection();
  const removePhoto = async () => {
    let name = state.images[index].name.replace(" ", "_");
    await removePhotoChunks({ chunk: name });
    state.images.splice(index, 1);
    addUploadPhotos(state.images);
  };

  return (
    <Fragment>
      <StyledImageCon>
        <StyledRemoveIcon onClick={() => removePhoto()} />
        <div onClick={() => setIsModalOpen(true)}>
          <img alt="" src={image.url} />
          <p>{image.name}</p>
        </div>
      </StyledImageCon>
      <StyledModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="p-l-12 p-r-12"
      >
        <StyledPaper elevation={4}>
          <Grid container item justifyContent="space-between" alignItems="center">
            <Grid item xs={12} style={{ direction: "rtl" }}>
              <StyledIconBtn
                aria-label="menu"
                onClick={() => setIsModalOpen(false)}
              >
                <StyledSVG />
              </StyledIconBtn>
            </Grid>
          </Grid>
          <StyledImgDiv image={image.url} />
        </StyledPaper>
      </StyledModal>
    </Fragment>
  );
};

export default PhotoModal;