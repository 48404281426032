import React, { createContext, useContext, useReducer } from "react";

export const APP_TYPES = {
  ADD_ID: "addId",
  ADD_APPOINTMENT_STAGE: "addAppointmentStage",
  ADD_NAME: "addName",
  ADD_DATE: "addDate",
  ADD_TIME: "addTime",
  ADD_RATING: "addRating",
  ADD_HAIL_DURATION: "addHailDuration",
  ADD_HAIL_COUNT: "addHailCount",
  ADD_CONTRACTOR: "addContractor",
  ADD_DMG_AMOUNT: "addDmgAmount",
  ADD_REP: "addRep",
  ADD_ADDRESS: "addAddress",
  ADD_INSURANCE: "addInsurance",
  ADD_HAIL_LOCATIONS: "addHailLocations",
  ADD_RECOMMEND_FILING_CLAIM: "addRecommendFilingClaim",
  ADD_APPROVAL_LIKELIHOOD: "addApprovalLikelihood",
  ADD_FILED: "addFiled",
  ADD_REPLACEMENT_VALUE: "addReplacementValue",
  ADD_ADJS_DATE: "addAdjsDate",
  ADD_BUILD_DATE: "addBuildDate",
  ADD_APPOINTMENT_STATUS: "addAppointmentStatus",
  ADD_CLAIM_APPROVAL: "addClaimApproval",
  PREPOPULATE: "prepopulate",
};


const {
  ADD_ID,
  ADD_APPOINTMENT_STAGE,
  ADD_HAIL_DURATION,
  ADD_HAIL_COUNT,
  ADD_NAME,
  ADD_DATE,
  ADD_TIME,
  ADD_RATING,
  ADD_CONTRACTOR,
  ADD_DMG_AMOUNT,
  ADD_REP,
  ADD_ADDRESS,
  ADD_INSURANCE,
  ADD_HAIL_LOCATIONS,
  ADD_RECOMMEND_FILING_CLAIM,
  ADD_APPROVAL_LIKELIHOOD,
  ADD_FILED,
  ADD_REPLACEMENT_VALUE,
  ADD_ADJS_DATE,
  ADD_BUILD_DATE,
  ADD_APPOINTMENT_STATUS,
  ADD_CLAIM_APPROVAL,
  PREPOPULATE,
} = APP_TYPES;

const AdminAppointmentContext = createContext();

const adminAppointmentReducer = (state, action) => {
  switch (action.type) {
    case ADD_ID:
      return { ...state, id: action.payload };
    case ADD_APPOINTMENT_STAGE:
      return { ...state, appointmentStage: action.payload };
    case ADD_HAIL_DURATION:
      return { ...state, hailDuration: action.payload };
    case ADD_HAIL_COUNT:
      return { ...state, hailCount: action.payload };
    case ADD_NAME:
      return { ...state, name: action.payload };
    case ADD_DATE:
      return { ...state, date: action.payload };
    case ADD_TIME:
      return { ...state, time: action.payload };
    case ADD_RATING:
      return { ...state, rating: action.payload };
    case ADD_CONTRACTOR:
      return { ...state, contractor: action.payload };
    case ADD_DMG_AMOUNT:
      return { ...state, dmgAmount: action.payload };
    case ADD_REP:
      return { ...state, rep: action.payload };
    case ADD_ADDRESS:
      return { ...state, address: action.payload };
    case ADD_INSURANCE:
      return { ...state, insurance: action.payload };
    case ADD_HAIL_LOCATIONS:
      return { ...state, hailLocations: action.payload };
    case ADD_RECOMMEND_FILING_CLAIM:
      return { ...state, recommendFilingClaim: action.payload };
    case ADD_APPROVAL_LIKELIHOOD:
      return { ...state, approvalLikelihood: action.payload };
    case ADD_FILED:
      return { ...state, filed: action.payload };
    case ADD_REPLACEMENT_VALUE:
      return { ...state, replacementValue: action.payload };
    case ADD_ADJS_DATE:
      return { ...state, adjsDate: action.payload };
    case ADD_BUILD_DATE:
      return { ...state, buildDate: action.payload };
    case ADD_APPOINTMENT_STATUS:
      return { ...state, appointmentStatus: action.payload };
    case ADD_CLAIM_APPROVAL:
      return { ...state, claimApproval: action.payload };
    case PREPOPULATE:
      return { ...action.payload };

    default:
      return { ...state };


  };
}

export const INITIAL_APP_STATE = {
  id: "",
  appointmentStage: "",
  hailDuration: "",
  hailCount: "",
  name: "",
  date: "",
  time: "",
  rating: "",
  contractor: "",
  dmgAmount: "",
  rep: "",
  address: "",
  insurance: "",
  hailLocations: "",
  recommendFilingClaim: "",
  approvalLikelihood: "",
  filed: "",
  replacementValue: "",
  adjsDate: "",
  buildDate: "",
  appointmentStatus: "",
  claimApproval: ""
};

const AdminAppointmentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(adminAppointmentReducer, INITIAL_APP_STATE);

  const value = { state, dispatch };
  return (
    <AdminAppointmentContext.Provider value={value}>
      {children}
    </AdminAppointmentContext.Provider>
  );

}
const useAdminAppointment = () => {
  const context = useContext(AdminAppointmentContext);
  const { state, dispatch } = context;

  const addId = (payload) =>
    dispatch({ type: ADD_ID, payload });
  const addAppointmentStage = (payload) =>
    dispatch({ type: ADD_APPOINTMENT_STAGE, payload });
  const addHailDuration = (payload) =>
    dispatch({ type: ADD_HAIL_DURATION, payload });
  const addHailCount = (payload) =>
    dispatch({ type: ADD_HAIL_COUNT, payload });
  const addName = (payload) =>
    dispatch({ type: ADD_NAME, payload });
  const addDate = (payload) =>
    dispatch({ type: ADD_DATE, payload });
  const addTime = (payload) =>
    dispatch({ type: ADD_TIME, payload });
  const addRating = (payload) =>
    dispatch({ type: ADD_RATING, payload });
  const addContractor = (payload) =>
    dispatch({ type: ADD_CONTRACTOR, payload });
  const addDmgAmount = (payload) =>
    dispatch({ type: ADD_DMG_AMOUNT, payload });
  const addRep = (payload) =>
    dispatch({ type: ADD_REP, payload });
  const addAddress = (payload) =>
    dispatch({ type: ADD_ADDRESS, payload });
  const addInsurance = (payload) =>
    dispatch({ type: ADD_INSURANCE, payload });
  const addHailLocations = (payload) =>
    dispatch({ type: ADD_HAIL_LOCATIONS, payload });
  const addRecommendFilingClaim = (payload) =>
    dispatch({ type: ADD_RECOMMEND_FILING_CLAIM, payload });
  const addApprovalLikelihood = (payload) =>
    dispatch({ type: ADD_APPROVAL_LIKELIHOOD, payload });
  const addFiled = (payload) =>
    dispatch({ type: ADD_FILED, payload });
  const addReplacementValue = (payload) =>
    dispatch({ type: ADD_REPLACEMENT_VALUE, payload });
  const addAdjsDate = (payload) =>
    dispatch({ type: ADD_ADJS_DATE, payload });
  const addBuildDate = (payload) =>
    dispatch({ type: ADD_BUILD_DATE, payload });
  const addAppointmentStatus = (payload) =>
    dispatch({ type: ADD_APPOINTMENT_STATUS, payload });
  const addClaimApproval = (payload) =>
    dispatch({ type: ADD_CLAIM_APPROVAL, payload });
  const prepopulate = (payload) =>
    dispatch({ type: PREPOPULATE, payload });

  return {
    state,
    addId,
    addAppointmentStage,
    addHailDuration,
    addHailCount,
    addName,
    addDate,
    addTime,
    addRating,
    addContractor,
    addDmgAmount,
    addRep,
    addAddress,
    addInsurance,
    addHailLocations,
    addRecommendFilingClaim,
    addApprovalLikelihood,
    addFiled,
    addReplacementValue,
    addAdjsDate,
    addBuildDate,
    addAppointmentStatus,
    addClaimApproval,
    prepopulate
  }

}
export { useAdminAppointment, AdminAppointmentProvider };