import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "../LandingPage";
import ReportLoading from "../ReportLoading";
import Report from "../ReportPage";
import LoginPage from "../LoginPage";
import NotFound from "../NotFound";
import Error from "../Error";
import { ROUTES, ADMIN_ROUTES } from "../../constants/routes";
import Inspection from "../Inspection";
import CrunchAddresses from "../CrunchAddresses";
import Upload from "../Upload";
import ScheduleForm from "../ScheduleForm";
import ScheduleSuccess from "../ScheduleSuccess";
import OurInspectors from "../OurInspectors";
import HowItWorks from "../HowItWorks";
import FAQ from "../FAQ";
import AboutUs from "../AboutUs";
import PrivacyPolicy from "../PrivacyPolicy";
import DoNotSell from "../DoNotSell";
import CookieSettings from "../CookieSettings";
import SingleArticle from "../SingleArticle";
import Monitoring from "../Monitoring";
import Admin from "../Admin";
import AppManagement from "../Admin/Components/AppManagement";
import AppHistory from "../Admin/Components/AppHistory";
import CustomerProfiles from "../Admin/Components/CustomerProfiles";
import PartnerProfiles from "../Admin/Components/PartnerProfiles";
import Reports from "../Admin/Components/Reports";
import Properties from "../Admin/Components/Properties";
import Appointments from "../Admin/Components/Appointments";
import APartnerNeeded from "../Admin/Components/Appointments/APartnerNeeded";
import AActive from "../Admin/Components/Appointments/AActive";
import BFieldRepNeeded from "../Admin/Components/Appointments/BFieldRepNeeded";
import CMissingInspection from "../Admin/Components/Appointments/CMissingInspection";
import DHomeownerToFile from "../Admin/Components/Appointments/DHomeownerToFile";
import EManageClaimStatus from "../Admin/Components/Appointments/EManageClaimStatus";
// import EMissingAdjustmentDate from "../Admin/Components/Appointments/EMissingAdjustmentDate";
// import FMissingAdjustmentOutcome from "../Admin/Components/Appointments/FMissingAdjustmentOutcome";
import PartnerProfileChild from "../Admin/Components/PartnerProfiles/PartnerProfileChild";
import PartnerProfileList from "../Admin/Components/PartnerProfiles/PartnerProfileList";
import Sitemap from "../Sitemap";
import Unsubscribe from "../Unsubscribe";

const ComponentRoutes = () => (
  <Routes>
    <Route path={ROUTES.LOGIN_IN} element={<LoginPage />} />
    <Route path={ROUTES.LANDING_PAGE} element={<LandingPage />} end />
    <Route path={ROUTES.LOADING_REPORT} element={<ReportLoading />} />
    <Route path={ROUTES.REPORT} element={<Report />} />
    <Route path={ROUTES.CRUNCH_ADDRESSES} element={<CrunchAddresses />} />
    <Route path={ROUTES.UPLOAD} element={<Upload />} />
    <Route path={ROUTES.INSPECTION} element={<Inspection />} />
    <Route path={ROUTES.LEGACY} element={<Inspection />} />
    <Route path={ROUTES.MNRC} element={<Inspection />} />
    <Route path={ROUTES.TRINITY_EXTERIORS} element={<Inspection />} />
    <Route path={ROUTES.RESULTS_CONTRACTING} element={<Inspection />} />
    <Route path={ROUTES.BOELTER} element={<Inspection />} />
    <Route path={ROUTES.SCHEDULE_FORM} element={<ScheduleForm />} />
    <Route path={ROUTES.SCHEDULE_SUCCESS} element={<ScheduleSuccess />} />
    <Route path={ROUTES.REPORT_ERROR} element={<div>Error loading report</div>} />
    <Route path={ROUTES.OUR_INSPECTORS} element={<OurInspectors />} />
    <Route path={ROUTES.HOW_IT_WORKS} element={<HowItWorks />} />
    <Route path={ROUTES.FAQ} element={<FAQ />} />
    <Route path={`${ROUTES.ARTICLES}/:id`} element={<SingleArticle />} end />
    <Route path={ROUTES.ABOUT_US} element={<AboutUs />} />
    <Route path={ROUTES.MONITORING} element={<Monitoring />} />
    <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
    <Route path={ROUTES.UNSUBSCRIBE} element={<Unsubscribe />} />
    <Route path={ROUTES.DO_NOT_SELL} element={<DoNotSell />} />
    <Route path={ROUTES.COOKIE_SETTINGS} element={<CookieSettings />} />
    <Route path={ROUTES.ERROR} element={<Error />} />
    <Route path="*" element={<Error />} />
    <Route path={ROUTES.SITEMAP} element={<Sitemap />} />
    <Route element={<NotFound />} />
    <Route path={ROUTES.ADMIN} element={<Admin />}>
      <Route path={ADMIN_ROUTES.APP_MANAGEMENT.route} element={<AppManagement />} />
      <Route path={ADMIN_ROUTES.APPOINTMENTS.route} element={<Appointments />}>
        <Route path={ADMIN_ROUTES.ACTIVE.route} element={<AActive />} />
        <Route path={ADMIN_ROUTES.PARTNER_NEEDED.route} element={<APartnerNeeded />} />
        <Route path={ADMIN_ROUTES.FIELD_REP_NEEDED.route} element={<BFieldRepNeeded />} />
        <Route path={ADMIN_ROUTES.MISSING_INSPECTION.route} element={<CMissingInspection />} />
        <Route path={ADMIN_ROUTES.HOMEOWNER_TO_FILE.route} element={<DHomeownerToFile />} />
        <Route path={ADMIN_ROUTES.MANAGE_CLAIM_STATUS.route} element={<EManageClaimStatus />} />
        {/* <Route path={ADMIN_ROUTES.MISSING_ADJUSTMENT_DATE.route} element={<EMissingAdjustmentDate />} />
        <Route path={ADMIN_ROUTES.MISSING_ADJUSTMENT_OUTCOME.route} element={<FMissingAdjustmentOutcome />} /> */}
      </Route>
      <Route path={ADMIN_ROUTES.APP_HISTORY.route} element={<AppHistory />} />
      <Route path={ADMIN_ROUTES.CUSTOMERS.route} element={<CustomerProfiles />} />
      <Route path={ADMIN_ROUTES.PARTNERS.route} element={<PartnerProfiles />}>
        <Route exact path={`/admin/${ADMIN_ROUTES.PARTNERS.route}`} element={<PartnerProfileList />} />
        <Route path=":slug" element={<PartnerProfileChild />} />
      </Route>
      <Route path={ADMIN_ROUTES.REPORTS.route} element={<Reports />} />
      <Route path={ADMIN_ROUTES.PROPERTIES.route} element={<Properties />} />
    </Route>
  </Routes>
);
export default ComponentRoutes;