import React, { useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import withAdminContext from "../../../../store/withAdminContext";
import styled from "styled-components";
import { STAGES } from "../../../../../../constants/appointment_stages";
import { StyledLink } from "../../../Common/AdminLink";
import { AdminInput } from "../../../Common/AdminInput";
import editBlack from "../../../../assets/edit_icon_black.png";
import cancel_icon from "../../../../assets/cancel_icon.png";
import { addActiveAppointments, addAppointmentNumbers } from "../../../../store/actions";
import { updateAppointment } from "../../../../../../services";
import moment from "moment";
import AdminTimeRange from "../../../Common/AdminTimeRange";
import { countAndSortAppointments } from "../../../AdminLoad";
// import save_icon from "../../../../assets/save_icon.png";

const StyledATag = styled.a`
${StyledLink}
margin-top: 1-px;
${(props) => props.isdisabled === "true" && `
  pointer-events: none;
`}
`;

const StyledBody1Cont = styled(Grid2)`

  p {
    margin: 5px 0;
  }
`;

const StyledEdit = styled.img`
  width: 27px;
  height: 27px;
  margin-right: -36px;
  margin-top: -17px;
  cursor: pointer;
`;

const StyledSaveCancelDiv = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 5px;
  width: 60px;
  margin-right: -36px;
  margin-top: -17px;
  img {
    width: 27px;
    height: 27px;
    cursor: pointer;
  }
`;

const PartnerNeededApp = ({ context: { dispatch, state }, app }) => {
  const timeSlot = app.timeSlot.split(" - ");
  const [canEdit, setCanEdit] = useState(false);
  const [fullName, setFullName] = useState(app.associated_owner_id.fullName);
  const [address, setAddress] = useState(app.associated_property_id.address);
  const [calendar, setCalendar] = useState(app.inspectionDate);
  const [startTime, setStartTime] = useState(timeSlot[0]);
  const [endTime, setEndTime] = useState(timeSlot[1]);
  const [contractor, setContractor] = useState(app.associated_partner_contractor_id === "" ? "none_selected" : app.associated_partner_contractor_id);

  const sendAppointmentForUpdate = async () => {

    let timeSlotforPayload = startTime + " - " + endTime;
    const owner = {
      ...app.associated_owner_id,
      fullName: fullName,
    };

    const property = {
      ...app.associated_property_id,
      address: address,
    };
    const payload = {
      ...app,
      owner: [owner],
      property: [property],
      timeSlot: timeSlotforPayload,
      inspectionDate: calendar,
      associated_partner_contractor_id: contractor
    }
    // update db
    let appRes = await updateAppointment(STAGES.PARTNER_NEEDED, payload)
    // update global state
    const appIndex = state.activeAppointments.findIndex((app) => app._id === payload._id);
    state.activeAppointments.splice(appIndex, 1);
    // update stage
    dispatch(addAppointmentNumbers(countAndSortAppointments([...state.activeAppointments, appRes.data[0]])));
    dispatch(addActiveAppointments([...state.activeAppointments, appRes.data[0]]));
  };

  const emailBody = `Hello ${contractor}!%0d%0aAn appointment has been scheduled:%0d%0aName: ${fullName}%0d%0aAddress: ${address}%0d%0aPhone Number: ${app.associated_owner_id.phoneNumber}%0d%0aEmail: ${app.associated_owner_id.email}%0d%0aAppointment Date: ${calendar}%0d%0aAppointment Time: ${startTime + " - " + endTime}%0d%0aRoof Rating: ${app.hscRating}%0d%0aRoof Age: ${app.associated_property_id.roof_age}%0d%0aShingle Type: ${app.associated_property_id.shingleType}%0d%0aInsurance Provider: ${app.insuranceProvider}%0d%0aOnSite Lite report attached.%0d%0a%0d%0aThank you!%0d%0a%0d%0aRegards,%0d%0aHailscale Admin`;


  return (
    <Grid2 container className="text-align-left">
      <StyledBody1Cont container flexDirection={"column"} justifyContent={"space-evenly"} xs={12} md={4} className="p-t-18 p-b-18 p-r-50">
        <AdminInput
          isDisabled={!canEdit}
          classNames="font-26-regular-black uppercase m-b-20"
          value={fullName}
          onChange={(val) => setFullName(val.target.value)}
        />
        <AdminInput
          isDisabled={!canEdit}
          type="textarea"
          classNames="uppercase"
          value={address}
          onChange={(val) => setAddress(val.target.value)}
        />
      </StyledBody1Cont>
      <StyledBody1Cont xs={12} md={4} container flexDirection={"column"} justifyContent={"space-evenly"} className="p-t-18 p-b-18 p-l-45 p-r-45 border-l-LIGHT_BLUE">
        <AdminInput
          isDisabled={!canEdit}
          type="calendar"
          classNames=""
          value={calendar}
          label="Date"
          onChange={(val) => {
            setCalendar(moment(val._d).format("YYYY-MM-DD"))
          }}
        />
        <AdminTimeRange
          label="Time"
          isDisabled={!canEdit}
          startTime={startTime}
          endTime={endTime}
          onChangeStart={(val) => setStartTime(val)}
          onChangeEnd={(val) => setEndTime(val)}
        />
      </StyledBody1Cont>
      <StyledBody1Cont xs={12} md={4} container justifyContent={"space-evenly"} className="p-t-18 p-b-18 p-l-45 border-l-LIGHT_BLUE">
        <Grid2 xs={8}>
          <AdminInput
            label={"contractor"}
            type="select"
            selectOptions={state.partnerNames}
            value={contractor}
            onChange={(val) => {
              setContractor(val)
            }}
            isDisabled={!canEdit}
          />
          <StyledATag
            href={`mailto:justus@hailscale.com?cc=justin@hailscale.com, &bcc=luke@hailscale.com&subject=Send to Contractor&body=${emailBody}`}
            onClick={() => sendAppointmentForUpdate()}
            isdisabled={canEdit && contractor !== "none_selected"}
          ><span>Send offer to<br></br>contractor</span></StyledATag>
        </Grid2>
        <Grid2 xs={4} container justifyContent={"flex-end"}>
          {!canEdit ?
            <StyledEdit
              onClick={() => setCanEdit(!canEdit)}
              src={editBlack}
              loading="lazy"
              alt=""
            />
            :
            <StyledSaveCancelDiv>
              {/* <img
                onClick={() => updateAppointment()}
                src={save_icon}
                loading="lazy"
                alt=""
              /> */}
              <img
                onClick={() => setCanEdit(!canEdit)}
                src={cancel_icon}
                loading="lazy"
                alt=""
              />
            </StyledSaveCancelDiv>
          }
        </Grid2>
      </StyledBody1Cont>
    </Grid2 >
  );
}
export default withAdminContext(PartnerNeededApp);