/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import withLocalContext from "../store/withLocalContext";
import { InspectionProvider } from "./Provider";
import {
  SubmittedBy,
  ShingleType,
  DmgAmount,
  RecommendFilingClaim,
  ClaimLikelihood,
  FilingStatus,
  HailDetectedLocation,
  InspectionDate,
  InsuranceProvider,
  FullName,
  AccessHighestPoint,
  RoofPitch,
  SidingType,
  AdditionalComments,
  Images,
  RetailBids,
  PropertyManager,
  // StormDates
} from "./Inputs";
import SubmitForm from "./SubmitForm";
import AddressSection from "./AddressSection";
import { PageView } from "../Tracking";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { getAppInspections, getContractors } from "../../services";
import { addAppInspections, addFieldReps } from "../store/actions";
import { ROUTES } from "../../constants/routes";
import InsuranceContact from "./InsuranceContact";
import { Navigate, useLocation } from "react-router-dom";
import { getIsAuthorized } from "../store/selectors";

const StyledGridContainer = styled(Grid)`
  height: fit-content;
`;

const StyledGridItem = styled(Grid)`
  margin-top: 20px !important;

  @media (max-width: 600px) {
    margin-top: 15px !important;
  }
`;

export const Inspection = ({ context: { dispatch, state } }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const [areAddressesLoading, setAreAddressesLoading] = useState(true);
  const [showPropertyMgName, setShowPropertyMgName] = useState(false);
  const location = useLocation();

  useEffect(() => PageView(), []);

  useEffect(() => {
    preLoadDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const preLoadDate = async () => {
    let url = window.location.pathname;
    let partner;

    switch (url) {
      case ROUTES.LEGACY:
        partner = 'legacy_restoration_llc';
        break;
      case ROUTES.MNRC:
        partner = 'minnesota_restoration_contractors_inc';
        break;
      case ROUTES.TRINITY_EXTERIORS:
        partner = 'trinity_exteriors';
        break;
      case ROUTES.RESULTS_CONTRACTING:
        partner = 'results_contracting';
        break;
      case ROUTES.BOELTER:
        partner = 'boelter';
        break;
      default:
        partner = 'hailscale';
        break;
    }
    const inspectionRes = await getAppInspections({ partner: partner });

    setAreAddressesLoading(false);
    dispatch(addAppInspections(inspectionRes.data));
    const fieldRepRes = await getContractors({ partner: partner });
    dispatch(addFieldReps(fieldRepRes.data));
  }

  const loadShowPropertyMgName = (value) => {
    if (value === "") {
      setShowPropertyMgName(false);
    } else {
      setShowPropertyMgName(true);
    }
  };

  return (
    (location.pathname !== ROUTES.INSPECTION || getIsAuthorized()) ?
      <InspectionProvider>
        <Grid
          container
          className={`bg-white ${isMedium ? "p-t-15 p-l-100 p-b-100" : "p-t-80"}`}
        >
          <Grid item xs={12} className={isMedium ? "m-b-70" : "m-b-10"}>
            <Typography variant="h2" className={`${isMedium ? "text-align-left" : ""} text-blue`}>Inspection Report</Typography>
          </Grid>
          <Grid
            container
            justifyContent="center"
            item
            xs={12}
            sm={7}
            className={`p-t-16 ${isMedium ? "p-l-16 p-r-16" : "p-l-32 p-r-32"}`}
            style={{ height: "fit-content" }}
          >
            <Grid item xs={12} className="m-b-25">
              <AddressSection loading={areAddressesLoading} propertyMgName={loadShowPropertyMgName} />
              {/* <StormDates /> */}
              <PropertyManager showPropertyMgName={showPropertyMgName} />
            </Grid>
            <StyledGridContainer item container xs={12} md={6}>
              <Grid item xs={12}>
                <FullName />
              </Grid>
              <StyledGridItem item xs={12}>
                <ShingleType />
              </StyledGridItem>
              <StyledGridItem item xs={12}>
                <RoofPitch />
              </StyledGridItem>
              <StyledGridItem item xs={12}>
                <AccessHighestPoint />
              </StyledGridItem>
              <StyledGridItem item xs={12}>
                <SidingType />
              </StyledGridItem>
              <StyledGridItem item xs={12}>
                <Images showPropertyMgName={showPropertyMgName} />
              </StyledGridItem>
            </StyledGridContainer>
            <StyledGridContainer item container xs={12} md={6} className={isMedium ? "p-l-30" : "p-t-15"}>
              <Grid item xs={12}>
                <InspectionDate />
              </Grid>
              <StyledGridItem item xs={12}>
                <DmgAmount />
              </StyledGridItem>
              <StyledGridItem item xs={12}>
                <HailDetectedLocation />
              </StyledGridItem>
            </StyledGridContainer>
          </Grid>
          <Grid
            container
            justifyContent="center"
            item
            xs={12}
            md={5}
            className={`m-t-50 p-t-16 ${isMedium ? "p-l-16" : "p-l-32 p-r-32"}`}
          >
            <Grid item container className={`bg-light-blue border-radius-10 ${isMedium ? "p-r-40 p-l-40 p-t-45 p-b-45 border-radius-right-none" : "p-a-40"}`}>
              <Grid item xs={12}>
                <Typography variant="h2" className="text-blue">Claim Status</Typography>
              </Grid>
              <Grid item xs={12} className="m-t-15">
                <InsuranceProvider />
              </Grid>
              <Grid item xs={12} className="m-t-30">
                <RecommendFilingClaim />
              </Grid>
              <Grid item xs={12} className="m-t-30">
                <ClaimLikelihood />
              </Grid>
              <Grid xs={12} item className="m-t-30">
                <FilingStatus />
              </Grid>
              <Grid xs={12} item className="m-t-30">
                <InsuranceContact />
              </Grid>
              {showPropertyMgName &&
                <Grid item xs={12} className="m-t-30">
                  <RetailBids />
                </Grid>
              }
              <Grid item xs={12} className="m-t-30">
                <AdditionalComments showPropertyMgName={showPropertyMgName} />
              </Grid>
              <Grid item xs={12} className="m-t-45">
                <SubmittedBy fieldReps={state.fieldReps} />
              </Grid>
            </Grid>
            <Grid
              xs={12}
              item
              container
              alignItems="flex-end"
              className={`m-t-60 ${isMedium ? "" : "m-b-60"}`}
            >
              <SubmitForm showPropertyMgName={showPropertyMgName} />
            </Grid>
          </Grid>
        </Grid>
      </InspectionProvider>
      :
      <Navigate to={ROUTES.LOGIN_IN} state={{ url: ROUTES.INSPECTION }} />
  );
};

export default withLocalContext(Inspection);
