/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Grid as Gridsx } from '@mui/material';
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";
import { Typography } from "@mui/material";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import withLocalContext from "../store/withLocalContext";
import { getRatingParams, getReport } from "../store/selectors";
import {
  FullName,
  PhoneNumber,
  Email,
  InsuranceProvider,
  ReferralCode,
  ShingleType,
  AppointmentDate,
  TimeSlot,
  // PropertyManager,
  // CertifyOwner,
} from "./Inputs";
import { AppointmentProvider } from "./Provider";
import SubmitForm from "./SubmitForm";
import { PageView } from "../Tracking";
import Address from "./Inputs/Address";
import BackBtn from "./Inputs/BackBtn.js";
import styled from "styled-components";
import { FreeAppointmentToolTip, OurCertifiedRoofersToolTip } from "../Tooltips/Tooltips";
import Stats from "./Stats";
import { RoofAge } from "./Inputs/RoofAge";
import { postHailScaleRating } from "../../services";
import { addReport } from "../store/actions";
import { ROUTES } from "../../constants/routes";
import { useNavigate } from "react-router-dom";

import Storm_Black from "../../assets/Storm_Black.webp";
import Report_Black from "../../assets/Report_Black.webp";
import { COLORS } from "../../constants/colors";

const MONITOR_ARR = [
  {
    icon: Storm_Black,
    title: "Real-time storm Alerts",
    content: "We will alert you within 24 hours of detecting a hail event that impacts your property (great for cabins and rental properties)."
  },
  {
    icon: Report_Black,
    title: "Quarterly Hail History Reports",
    content: "You will receive a hail history report each quarter detailing your up-to-date weather history."
  }
]

const StyledTimeContainer = styled(Grid)`
@media (max-width: 600px) {
  margin-right: -25px;
  margin-left: -25px;
  width: 100vw;
  max-width: 100vw;
  flex-basis: auto;
}
`;

const StyledGridItem = styled(Grid)`
@media (max-width: 600px) {
  margin-top: 15px !important;
}
`;

const StyledWhenInspect = styled(Typography)`
@media (max-width: 1280px) {
  font-size: 25px !important;
}
`;

export const ScheduleForm = ({ context: { state, dispatch } }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const [isLoading, setIsLoading] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    PageView();
  }, []);

  useEffect(() => {
    if (Object.keys(state.report).length > 0) {
      setCanEdit(true);
    } else {
      setCanEdit(false);
    }
  }, [state.report])

  useEffect(() => {
    if (state.ratingParams.roofAge && state.ratingParams.address)
      runParams();
  }, [state.ratingParams])


  async function runParams() {
    setIsLoading(true);
    const params = getRatingParams(state);


    const res = await postHailScaleRating(params);
    dispatch(addReport(res.data));
    setIsLoading(false);
  }

  return (
    <AppointmentProvider>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>Schedule Form</title>
        <link rel="canonical" href={`https://www.hailscale.com${ROUTES.SCHEDULE_FORM}`} />
        <meta name="description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Schedule Form" />
        <meta itemprop="description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta itemprop="image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.hailscale.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Schedule Form" />
        <meta property="og:description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta property="og:image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Schedule Form" />
        <meta name="twitter:description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta name="twitter:image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Meta Tags Generated via http://heymeta.com --> */}
      </Helmet>
      <Grid
        container
        className={`${isMedium ? "p-l-60 m-t-75" : "p-l-30 p-r-30 m-t-75"} fill-width bg-white`}
        spacing={isMedium ? 3 : 0}
      >
        <Grid item xs={12} className={`${isMedium ? "p-r-110" : ""} text-align-left`}>
          {state.report.hailscaleRating <= 7 ?
            <Fragment>
              <Typography variant="h2" className="text-blue">
                No inspection needed right now.
              </Typography>
              <Typography sx={{ fontSize: isMedium ? "35px !important" : "20px !important" }} className="m-b-30 text-blue">Enroll your address for free Hailscale Monitoring</Typography>
            </Fragment>
            :
            <Typography variant="h2" className="m-b-30 text-blue">
              Hailscale inspections are <FreeAppointmentToolTip classes={"text-blue"} />
            </Typography>
          }
        </Grid>
        <Grid container item xs={12}>
          <Stats />
        </Grid>
        <Grid item container xs={12} spacing={isMedium ? 4 : 0} className="m-b-20">
          <StyledGridItem item xs={12} md={7}>
            <Address />
          </StyledGridItem>
          <Grid item container xs={12} md={5} alignItems="flex-end">
            <RoofAge />
            {isLoading &&
              <Gridsx
                container
                alignItems={"center"}
                sx={{ height: isMedium ? "75px" : "45px", width: "fit-content" }}
              >
                <CircularProgress className="m-l-10" />
              </Gridsx>}
          </Grid>
        </Grid>
        <Grid
          xs={12}
          md={7}
          item
          container
          spacing={isMedium ? 3 : 0}
          className={`${isMedium ? "m-b-110 p-t-0" : "m-b-60"} ${canEdit ? "" : "pointer-events-none opacity-5"}`}
        >
          <StyledGridItem item xs={12} md={6}>
            <FullName />
          </StyledGridItem>
          <StyledGridItem item xs={12} md={6}>
            <Email />
          </StyledGridItem>
          <StyledGridItem item xs={12} md={6}>
            <PhoneNumber />
          </StyledGridItem>
          <StyledGridItem item xs={12} md={6}>
            <InsuranceProvider />
          </StyledGridItem>
          <StyledGridItem item xs={12} md={6}>
            <ReferralCode />
          </StyledGridItem>
          <StyledGridItem item xs={12} md={6}>
            <ShingleType />
          </StyledGridItem>
        </Grid>
        <StyledTimeContainer
          xs={12}
          md={5}
          item
          container
          spacing={isMedium ? 3 : 0}
          className={`bg-light-blue border-radius-10 ${isMedium ? "m-l-36 m-b-65 p-t-25 p-b-25 p-l-35 p-r-35 border-radius-right-none" : "p-a-12 m-b-45"} ${canEdit ? "" : "pointer-events-none opacity-5"}`}
        >
          {state.report.hailscaleRating <= 7 ?
            <Fragment>
              <Gridsx
                container
                item
                xs={12}
                justifyContent={"center"}
                sx={{
                  ">div": {
                    gap: "20px",
                    display: "flex",
                    justifyItems: "center"
                  },
                  padding: "0 !important",
                }}>
                <StyledWhenInspect className={`text-blue ${isMedium ? "" : "m-b-15"}`} variant="h2">Enroll in Monitoring</StyledWhenInspect>
                <Gridsx item xs={12} sx={{ marginY: "35px", display: isMedium ? "block" : "flex", flexDirection: isMedium ? "row" : "column" }}>
                  {MONITOR_ARR.map(item => (
                    <Gridsx
                      key={item.title}
                      sx={{
                        ">img": {
                          maxWidth: "80px",
                          maxHeight: "75px"
                        },
                        backgroundColor: COLORS.GREY,
                        borderRadius: "10px",
                        padding: "20px",
                        width: isMedium ? "50%" : "100%"
                      }}
                    >
                      <img src={item.icon} alt="" />
                      <Typography variant="h5">{item.title}</Typography>
                      <Typography variant="body2">{item.content}</Typography>
                    </Gridsx>
                  ))}
                </Gridsx>
                <div>
                  <Button
                    variant="outlined"
                    sx={{ width: "120px" }}
                    onClick={() => navigate(ROUTES.LANDING_PAGE)}
                  >Cancel</Button>
                  <Button
                    sx={{ width: "120px" }}
                    onClick={() => navigate(ROUTES.MONITORING)}
                  >Activate</Button>
                </div>
              </Gridsx>
            </Fragment>
            :
            <Fragment>
              <Grid item xs={12} className="p-a-0">
                <StyledWhenInspect className={`text-blue ${isMedium ? "" : "m-b-15"}`} variant="h2">When should we inspect?</StyledWhenInspect>
              </Grid>
              <Grid item xs={isMedium ? 10 : 12}>
                <AppointmentDate zipcode={state.report.zipCode ? state.report.zipCode : ""} />
              </Grid>
              <StyledGridItem item xs={isMedium ? 10 : 12} className="m-b-10">
                <TimeSlot />
              </StyledGridItem>
              <Gridsx sx={{ paddingRight: "9px !important" }} container item xs={6} md={"auto"}>
                <BackBtn />
              </Gridsx>
              <Gridsx sx={{ paddingLeft: "9px !important" }} container justifyContent="flex-start" item xs={6} md={4} xl={5} className={isMedium ? "" : "m-b-24"}>
                {Object.keys(state.report).length > 0 &&
                  <SubmitForm report={getReport(state)} />
                }
              </Gridsx>
            </Fragment>
          }

        </StyledTimeContainer>
      </Grid>
      {state.report.hailscaleRating > 7 &&
        <Grid item xs={12} className="p-x-50 text-align-left m-b-50">
          <Typography variant="h6" className="m-b-10">Our business model: How Hailscale makes money</Typography>
          <Typography variant="body2" sx={{ fontSize: "20px !important" }}>Hailscale is for-profit. We earn commissions from <OurCertifiedRoofersToolTip /> when they are hired by Hailscale clients. Our Certified Roofers fulfill exterior inspections at no-cost to homeowners that have qualified with Hailscale. Our clients are never obligated to hire Our Certified Roofers.</Typography>
        </Grid>
      }
    </AppointmentProvider>
  );
};

export default withLocalContext(ScheduleForm);
