import React, { useEffect, useState, Fragment } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, TextareaAutosize, useTheme, useMediaQuery, Button } from "@mui/material";
import AboutUsIntro from "./AboutUsIntro";
import { PageView } from "../Tracking";
import withLocalContext from "../store/withLocalContext";
import { submitContact, postError } from "../../services";
import styled from "styled-components";
import InputField from "../Common/InputField";
import { StyledLabel } from "../Common/StyledElements";
import { COLORS } from "../../constants/colors";
import { ROUTES } from "../../constants/routes";

const contact = {
  title: "Contact Us",
  details: [
    {
      title: "Email",
      content: "hello@hailscale.com"
    },
    {
      title: "Phone",
      content: "(612)-424-5420"
    },
    {
      title: "Address",
      content: "40 south 7th st. unit 207 Minneapolis, MN 55402"
    }
  ]
}

const StyledContact = styled(Typography)`
  font-size: 20px !important;
`;

const StyledTextField = styled(TextareaAutosize)`
  border: 1px solid ${COLORS.BLUE};
  border-radius: 10px;
  height: 223px;
  width: calc(100% - 28px);
  font-size: 24px;
  padding: 30px 14px;
  font-family: Colfax Regular;
  &::placeholder {
    color: ${COLORS.LIGHT_BLUE};
  }
  &:disabled {
    color: #00000033;
  }

  @media (max-width: 960px) {
    font-size: 15px;
  }
`;

const StyledActionBtnContainer = styled(Grid)`
padding-left: 33px;
padding-bottom: 5px;
> button {
  padding: 6px 32px;
}
@media (max-width: 959px) {
  padding-left: 0;
}
`;

export const AboutUs = ({ context: { state } }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const [notValid, setNotValid] = useState(true);
  const [disableFields, setDisableFields] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [contactDetails, setContactDetails] = useState({ name: "", email: "", message: "" });
  const navigate = useNavigate();
  useEffect(() => PageView(), []);

  useEffect(() => {
    updateContactDetails();
  })
  const updateContactDetails = () => {
    if (contactDetails.name && contactDetails.email && contactDetails.message) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
    if (isSubmitted) {
      setNotValid(true);
    }
  }

  const submitMessage = async () => {
    try {
      setIsSubmitted(true);
      setDisableFields(true);
      await submitContact(contactDetails);
    } catch (err) {
      postError({ error: err, device: state.deviceData });

      navigate(ROUTES.ERROR);
      console.log("error", err);
    }
    setNotValid(true);
  }

  return (
    <Fragment>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>About Us</title>
        <link rel="canonical" href={`https://hailscale.com${ROUTES.ABOUT_US}`} />
        <meta name="description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="About Us" />
        <meta itemprop="description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta itemprop="image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.hailscale.com/about-us" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="About Us" />
        <meta property="og:description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta property="og:image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us" />
        <meta name="twitter:description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta name="twitter:image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Meta Tags Generated via http://heymeta.com --> */}
      </Helmet>
      <Grid container>
        <AboutUsIntro />
        <Grid item container xs={12} className={`text-align-left ${isMedium ? "p-l-60" : "p-a-20"}`}>
          <Grid item xs={12} className="m-t-55">
            <Typography variant="h3" className="text-blue m-b-30">{contact.title}</Typography>
            {contact.details.map(detail => (
              <StyledContact key={detail.title} variant="body2" className="m-b-10" > <span className="text-font-fam-colfax-bold">{detail.title}: </span>{detail.content}</StyledContact>
            ))}
          </Grid>
          <Grid item xs={12} container className={`m-t-30 ${isMedium ? "m-b-115" : "m-b-45"}`}>
            <Grid item container xs={12} md={7}>
              <Grid item xs={6} className="p-r-10">
                <InputField
                  placeholder={"Your name..."}
                  text="Name"
                  change={(e) => setContactDetails({ ...contactDetails, 'name': e.target.value })}
                  isDisabled={disableFields}
                />
              </Grid>
              <Grid item xs={6} className="p-l-10">
                <InputField
                  placeholder={"Your email..."}
                  text="Email"
                  change={(e) => setContactDetails({ ...contactDetails, 'email': e.target.value })}
                  isDisabled={disableFields}
                />
              </Grid>
              <Grid item xs={12} className="m-t-30">
                <StyledLabel>Message<span className="text-red">*</span></StyledLabel>
                <StyledTextField
                  minRows={8}
                  maxRows={8}
                  placeholder="Your message..."
                  disabled={disableFields}
                  onChange={(e) => setContactDetails({ ...contactDetails, 'message': e.target.value })}
                ></StyledTextField>
              </Grid>
            </Grid>
            <StyledActionBtnContainer item container alignItems="flex-end" xs={12} md={2}>
              <Button
                onClick={() => submitMessage()}
                disabled={notValid}
              >Send</Button>
            </StyledActionBtnContainer>
            <Grid item container alignItems="flex-end" xs={12} md={2}>
              {isSubmitted ?
                <Typography variant="subtitle1" className="text-green">Your message has been submitted!</Typography>
                :
                ""
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment >
  );
}

export default withLocalContext(AboutUs);