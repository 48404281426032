import React from "react";
import { ROUTES } from "../../../constants/routes";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

export const BackBtn = () => {
  const navigate = useNavigate();
  return (
    <Button
      variant="grey"
      onClick={() => navigate(ROUTES.REPORT)}
    >Back to rating</Button>
  );
};

export default BackBtn;
