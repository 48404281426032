import React from "react";
import styled from "styled-components";
import { COLORS } from "../../constants/colors";

const StyledTag = styled.a`
  background-color: ${COLORS.BLUE};
  color: ${COLORS.WHITE};
  width: 232px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  text-decoration: none;
  font-family: 'Colfax Bold';
  font-size: 20px;
  margin: auto;
  box-shadow: 0 2px 4px 0 rgba(18, 48, 107, 0.29);
  &:hover {
    opacity: 0.8;
    background-color: ${COLORS.BLUE};
  }
`;

export const ATagBtn = ({ classNames = [], href, text }) => {

  return (
    <StyledTag
      className={[...classNames]}
      href={href}
    >
      {text}
    </StyledTag>
  );
};

export default ATagBtn;