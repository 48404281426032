import React from "react";
import { useAppointment } from "../Provider";
import BasicSelect from "../../Common/HSCSelect";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import styled from "styled-components";

const StyledAccessTimeIcon = styled(AccessTimeIcon)`
 color: black !important;
 font-size: 35px;
`;

export const TIME_SLOTS = [
  "8:00am - 11:00am",
  "11:00am - 2:00pm",
  "2:00pm - 5:00pm",
  "5:00pm - 8:00pm",
];

export const TimeSlot = () => {
  const { state, addTimeSlot } = useAppointment();

  return (
    <BasicSelect
      text="Time"
      label="select a time..."
      selectValue={state.timeSlot}
      change={(val) => addTimeSlot(val.target.value)}
      listMap={TIME_SLOTS}
      iconImg={StyledAccessTimeIcon}
    />
  );
};
