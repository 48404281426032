import { Grid } from "@material-ui/core"
import { useInspection } from "./Provider";
import { getInsuranceProviders } from "../../services";
import { useEffect, useState } from "react";

const InsuranceContact = () => {

  const { state } = useInspection();

  const [providers, setProviders] = useState([]);
  const [contactInfo, setContactInfo] = useState("");

  useEffect(() => {
    const getProviders = async () => {
      const insuranceProviders = await getInsuranceProviders();
      setProviders(insuranceProviders.data);
    }
    getProviders();
  }, [])

  useEffect(() => {
    if (providers.length > 0) {
      setContactInfo(providers.filter(item => item.provider_name === state.insuranceProvider)[0])
    }

  }, [state.insuranceProvider, providers])

  return (
    contactInfo ?
      <Grid container item xs={12} className="text-align-left">
        <Grid item xs={12}>
          <p className="font-size-20"><span className="text-font-fam-colfax-bold m-r-5">{contactInfo.provider_name} claim phone number:</span><a href={`tel:${contactInfo.phone_number}`}>{contactInfo.phone_number}</a></p>
        </Grid>
        <Grid item xs={12}>
          <p className="font-size-20"><span className="text-font-fam-colfax-bold m-r-5">{contactInfo.provider_name} claim website:</span><a target="_blank" rel="noopener noreferrer" href={contactInfo.url}>{contactInfo.url.substring(12)}</a></p>
        </Grid>
      </Grid>
      : null
  )
}

export default InsuranceContact;