/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@mui/material/Checkbox";
import { useInspection } from "../Provider";
import { StyledLabel } from "../../Common/StyledElements";
import styled from "styled-components";
import check from "../../../assets/check-solid.svg";
import HailLocationSelect from "../HailLocationSelect";

const PRIMARY_LOCATIONS = [
  "Shingles",
  "Valley Metals",
  "Soft Metals",
  "Window Wraps/Screens",
  "Gutters"
];

const SECONDARY_LOCATIONS = [
  "Soffit",
  "Fascia",
  "Mailbox",
  "Garage Door",
  "Light Fixtures",
  "A/C Unit",
  "Grill",
  "Deck",
  "Shed",
  "Metal Roof Slope(s)",
  "Down Spouts",
  "Siding",
];

const StyledCheckbox = styled(Checkbox)`
&.MuiCheckbox-root > .MuiIconButton-label > .MuiSvgIcon-root {
    color: transparent;
  }
  > svg {
    color: transparent;
  }
`;

const Image = styled.img`
width: 26px;
height: auto;
`;

const StyledSpan = styled.span`
  height: 36px;
  width: 36px;
  display: flex;
  background-color: white;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #0E5CBF !important;
`;

const StyledLineItem = styled(Typography)`
  font-size: 24px !important;
  @media (max-width: 960px) {  
    font-size: 16px !important;
  }
`;

export const HailDetectedLocation = () => {
  const { state, addHailLocations } = useInspection();

  const toggleLocation = (field) => {
    if (state.hailLocations.includes(field)) {
      addHailLocations(state.hailLocations.filter((loc) => loc !== field));
    } else {
      addHailLocations([...state.hailLocations, field]);
    }
  };

  return (
    <Fragment>
      <StyledLabel>Location of damage<span className="text-red">*</span></StyledLabel>
      <Grid item container className="border-blue border-radius-10">
        {PRIMARY_LOCATIONS.map((loc, i) => (
          <Grid
            container
            key={i}
            wrap="nowrap"
            className={`text-align-left p-a-12 cursor-pointer ${PRIMARY_LOCATIONS.length - 1 === i ? "border-bottom-blue" : "border-bottom-blue"}`}
            onClick={() => toggleLocation(loc)}
          >
            <Grid item>
              <StyledSpan>
                <StyledCheckbox
                  sx={{
                    '&:hover': { bgcolor: 'transparent' },
                  }}
                  checkedIcon={<Image src={check} alt="" />}
                  checked={state.hailLocations.includes(loc)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </StyledSpan>
            </Grid>
            <Grid item container alignContent="center" className="m-l-30">
              <Grid item>
                <StyledLineItem variant="body1">{loc}</StyledLineItem>
              </Grid>
            </Grid>
          </Grid>
        ))}
        {state.hailLocations.map((loc, i) => {
          return !PRIMARY_LOCATIONS.includes(loc) && (
            <Grid
              container
              key={i}
              wrap="nowrap"
              className={`text-align-left p-a-12 cursor-pointer border-bottom-blue`}
              onClick={() => toggleLocation(loc)}
            >
              <Grid item>
                <StyledSpan>
                  <StyledCheckbox
                    sx={{
                      '&:hover': { bgcolor: 'transparent' },
                    }}
                    checkedIcon={<Image src={check} alt="" />}
                    checked={state.hailLocations.includes(loc)}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </StyledSpan>
              </Grid>
              <Grid item container alignContent="center" className="m-l-30">
                <Grid item>
                  <StyledLineItem variant="body1">{loc}</StyledLineItem>
                </Grid>
              </Grid>
            </Grid>
          )
        }
        )}
        {
          SECONDARY_LOCATIONS.filter((loc) => !state.hailLocations.includes(loc)).length > 0 &&
          <HailLocationSelect
            selectValue={""}
            label="Other/Choose"
            listMap={SECONDARY_LOCATIONS.filter((loc) => !state.hailLocations.includes(loc))}
            change={(val) => toggleLocation(val.target.value)}
          />
        }
      </Grid>
    </Fragment>
  );
};