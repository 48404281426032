let baseHailscaleAPIUrl = "";
let href = window.location.href;
if (href.includes("develop")) {
  baseHailscaleAPIUrl = process.env.REACT_APP_HAIL_SCALE_API_DEVELOP;
} else {
  baseHailscaleAPIUrl = process.env.REACT_APP_HAIL_SCALE_API2;
}

export const POST_HAIL_SCALE_RATING = `${baseHailscaleAPIUrl}/report`;
export const AUTHORIZE = `${baseHailscaleAPIUrl}/authorize`;
export const GET_CONTRACTORS = `${baseHailscaleAPIUrl}/contractors`;
export const GET_PARTNERS = `${baseHailscaleAPIUrl}/get-partners`;
export const UPDATE_DIRECTORY = `${baseHailscaleAPIUrl}/update-directory`;
export const ADD_TO_DIRECTORY = `${baseHailscaleAPIUrl}/add-to-directory`;
export const DELETE_FROM_DIRECTORY = `${baseHailscaleAPIUrl}/delete-from-directory`;
export const INSPECTIONS = `${baseHailscaleAPIUrl}/inspections`;
export const APPOINTMENT = `${baseHailscaleAPIUrl}/appointment`;
export const APPOINTMENTHOME = `${baseHailscaleAPIUrl}/appointment-homeowner`;
export const APPOINTMENT_INSPECTIONS = `${baseHailscaleAPIUrl}/appointment-inspection`;
export const MONITORING = `${baseHailscaleAPIUrl}/monitoring`;
export const OUTOFAREA = `${baseHailscaleAPIUrl}/out-of-area`;
export const CONTACT = `${baseHailscaleAPIUrl}/contact`;
export const ADDRESS = `${baseHailscaleAPIUrl}/address`;
export const CHUNKS = `${baseHailscaleAPIUrl}/chunks`;
export const IP_ADDRESS = `${baseHailscaleAPIUrl}/ip-address`;
export const CHECK_IP_ADDRESS = `${baseHailscaleAPIUrl}/check-ip-address`;
export const CHECK_ADDRESS = `${baseHailscaleAPIUrl}/check-address`;
export const POST_BATCH_REPORT = `${baseHailscaleAPIUrl}/post-batch-report`;
export const GOOGLE_SHEET_REPORT = `${baseHailscaleAPIUrl}/google-sheet-report`;
export const GET_ZIPCODES = `${baseHailscaleAPIUrl}/zip-codes`;
export const GET_INSURANCE_PROVIDERS = `${baseHailscaleAPIUrl}/insurance_providers`;
export const GET_PROPERTY_MANAGERS = `${baseHailscaleAPIUrl}/property-managers`;
export const POST_CAMPAIGNS = `${baseHailscaleAPIUrl}/campaigns`;
export const LOG_ERROR = `${baseHailscaleAPIUrl}/log-error`;
export const UNSUBSCRIBE = `${baseHailscaleAPIUrl}/unsubscribe`;
export const ADDRESSLNGLAT = `${baseHailscaleAPIUrl}/address-lng-lat`;