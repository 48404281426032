import React, { Fragment, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import { Typography, TextField, /* Autocomplete */ } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import withLocalContext from "../store/withLocalContext";
import { addRatingParams } from "../store/actions";
import { getRatingParams } from "../store/selectors";
import { StyledLabel } from "../Common/StyledElements";

const StyledTextField = styled(TextField)`
  border-radius: 8px;
  > div > input {
    font-size: 24px !important;
    @media (max-width: 960px) {
      font-size: 16px !important;
    }
    &::placeholder {
      font-size: 24px;
      @media (max-width: 960px) {
        font-size: 16px !important;
      }
    }
  }
  .MuiOutlinedInput-inputAdornedEnd {
    padding-left: 5px !important;
  }
`;

export const AddressLookupInput = ({ context: { dispatch, state }, address }) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    options: {
      componentRestrictions: { country: ["us"] },
      types: ["address"],
    },
  });

  const getAddressDeets = (addy) => {
    placesService.getDetails(
      {
        placeId: addy.place_id,
      },
      (placeDetails) => {
        let addZipcode;
        placeDetails.address_components.map(component => {
          return component.types.map(type => {
            return type === "postal_code" &&
              (addZipcode = component.long_name)
          });
        });
        address(placeDetails.formatted_address);
        dispatch(
          addRatingParams({
            ...getRatingParams(state),
            address: {
              address: placeDetails.formatted_address,
              zipCode: addZipcode,
              shortAddress: `${placeDetails.address_components[0].long_name} ${placeDetails.address_components[1].long_name}`,
              place_id: addy.place_id,
              geoLocation: {
                lat: placeDetails.geometry.location.lat(),
                lng: placeDetails.geometry.location.lng(),
              },
            },
          })
        );
      }
    );
  };

  return (
    <Fragment>
      <StyledLabel>Address<span className="text-red" >*</span></StyledLabel>
      <Autocomplete
        className="address_input"
        open={isOpen}
        onOpen={() => {
          setIsOpen(true);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        getOptionSelected={(option, value) =>
          option.description === value.description
        }
        popupIcon={null}
        getOptionLabel={(option) => option.description}
        onChange={(evt, val) => {
          if (val) getAddressDeets(val);
        }}
        id="address-lookup"
        options={placePredictions}
        loading={isPlacePredictionsLoading}
        renderOption={(option) => (
          <Grid key={option.place_id} container alignItems="center">
            <Grid item>
              <FontAwesomeIcon icon={faMapMarkerAlt} color={"#1B479E"} />
            </Grid>
            <Grid className="m-l-20" item xs>
              <Typography variant="body2">{option.description}</Typography>
            </Grid>
          </Grid>
        )}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            className="bg-white text-font-fam-colfax-regular"
            placeholder="Your address..."
            variant="outlined"
            onChange={(evt) => getPlacePredictions({ input: evt.target.value })}
          />
        )}
      />
    </Fragment>
  );
};

export default withLocalContext(AddressLookupInput);