import ReactGA from "react-ga4";

export const initGA = () => {
  const TRACKING_ID = process.env.REACT_APP_GA4_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);
};

export const PageView = () => {
  ReactGA.send({ hitType: "pageview", page: `${window.location.pathname} ${window.location.search}` });
};

export const Event = (category, action, label = "") => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};
