import React, { useState } from "react";
import BasicSelect from "../../Common/HSCSelect";

export const TIME_SLOTS = [
  "8:00am - 11:00am",
  "11:00am - 2:00pm",
  "2:00pm - 5:00pm",
  "5:00pm - 8:00pm",
];

export const TimeSlot = ({ onChange }) => {
  const [slot, setSlot] = useState("");

  return (
    <BasicSelect
      label="select a time..."
      selectValue={slot}
      change={(e) => { onChange(e.target.value); setSlot(e.target.value) }}
      listMap={TIME_SLOTS}
      formSx={{ "label": { color: "black" } }}
    />
  );
};
