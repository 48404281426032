import React, { useContext } from 'react';
import { AdminContext } from './AdminCtx';

const withAdminContext = Component => props => {
  const context = useContext(AdminContext);

  return <Component context={context} {...props} />;
};

export default withAdminContext;
