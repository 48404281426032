import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Outlet } from "react-router-dom";

export const PartnerProfiles = () => {

  return (
    <Grid2 xs={12} container>
      <Outlet />
    </Grid2>
  );
}

export default PartnerProfiles;