import styled from "styled-components";
import { COLORS } from "../../../constants/colors";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const getPadding = (ismedium) => ismedium === "true" ? 26 : 8;

const StyledStatContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${COLORS.LIGHT_BLUE};
  border-radius: 10px;
  
  div{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 14px;

    img {
      width: 100%;
      object-fit: contain;
      max-width: 56px;
      max-height:45px;
      margin-right: 13px;
    }
    h6 {
      font-size: 30px;
      margin: 0;
      font-family: Colfax Bold;
      height: 30px;
    }
  }

  p {
    margin: 0;
    font-size: 20px;
    span {
      display: flex;
      max-width: 140px;
    }
  }
  ${props => props.ismedium === "true" ? `
    max-width: calc(200px - ${getPadding(props => props.ismedium) * 2}px);
    min-width: calc(200px - ${getPadding(props => props.ismedium) * 2}px);
    padding: 20px ${getPadding(props => props.ismedium) - 1}px;
    justify-content: center;
  `: `
    max-width: calc((100vw / 3) - 27px);
    min-width: calc((100vw / 3) - 27px);
    justify-content: flex-start;
    padding: 10px ${getPadding(props => props.ismedium) - 1}px;
    div {
      flex-direction: column;
      height: 75px;
      
      img {
        object-fit: contain;
        margin: 0;
        margin-bottom: 5px;
      }
    }
    p {
      font-size: 16px;
    }
  `}
`;

const SingleStat = ({ icon, stat, text }) => {

  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <StyledStatContainer ismedium={String(isMedium)}>
      <div>
        <img src={icon} alt="" />
        <h6>{stat}</h6>
      </div>
      <p>{text}</p>
    </StyledStatContainer>
  )
}

export default SingleStat;