import React from "react";
import GoogleMapReact from "google-map-react";
// import { MapContainer, TileLayer, WMSTileLayer, Marker } from "react-leaflet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

// https://mesonet.agron.iastate.edu/docs/nexrad_mosaic/
// https://mesonet.agron.iastate.edu/ogc/

const StyledLocation = styled.div`
height: 311px;
width: 100%;

> div > div {
  border-radius: 0px; 
}
@media (max-width: 600px) {
  height: 163px;
}
`;

export const NexRadImage = ({
  geoLocation: { lat, lng },
  // recentHailEvent: { utcLocalStormStart },
}) => {
  // const nearestFiveMinInterval = 5 - (moment(utcLocalStormStart).minute() % 5);
  // const newTime = moment(utcLocalStormStart)
  //   .add(nearestFiveMinInterval, "minutes")
  //   .format("YYYY-MM-DDTHH:mm");
  // const newDate = moment(utcLocalStormStart).add(
  //   nearestFiveMinInterval,
  //   "minutes"
  // );
  // const radarStation = station.substring(1);

  // const time = newDate.format("HHmm");
  // const month = newDate.format("MM");
  // const day = newDate.format("DD");
  // const year = newDate.format("YYYY");
  // return (
  //   <MapContainer
  //     center={[lat, lng]}
  //     zoom={20}
  //     scrollWheelZoom={false}
  //     style={{ width: "100%", height: "300px" }}
  //   >
  //     <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
  //     {/* <WMSTileLayer
  //       url="http://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0r-t.cgi?"
  //       format="image/png"
  //       transparent
  //       time={newTime}
  //       layers="nexrad-n0r-wmst"
  //       opacity={1}
  //     /> */}
  //     {/* <WMSTileLayer
  //       url="http://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0r.cgi?"
  //       format="image/png"
  //       transparent
  //       time={newTime}
  //       layers="nexrad-n0r-900913"
  //       opacity={1}
  //     /> */}
  //     {/* <WMSTileLayer
  //       url="http://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/ridge.cgi?"
  //       format="png"
  //       transparent
  //       layers="single-archive"
  //       opacity={1}
  //       sector={radarStation}
  //       prod="N0Q"
  //       year={year}
  //       month={month}
  //       day={day}
  //       time={time}
  //     /> */}
  //     <Marker
  //         key={'home'}
  //         position={[lat, lng]}
  //         onClick={() => {

  //         }}
  //       />
  //   </MapContainer>

  return (
    <StyledLocation>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
        defaultCenter={[lat, lng]}
        defaultZoom={19}
        options={{
          tilt: 0,
          mapTypeId: "satellite",
          mapTypeControl: false,
          streetViewControl: true,
          // gestureHandling: "none",
          // zoomControl: false,
          // scrollwheel: false,
          // fullscreenControl: false,
        }}
        overlayViewDivStyle={{ width: "0%" }}
      >
        <div style={{ width: "100%" }} lat={lat} lng={lng}>
          <FontAwesomeIcon style={{ borderRadius: 10 }} icon={faMapMarkerAlt} color={"#1B479E"} size="2x" />
        </div>
      </GoogleMapReact>
    </StyledLocation>
  );
};

export default NexRadImage;

// https://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0r-t.cgi?&REQUEST=GetMap&TRANSPARENT=true&FORMAT=image/png&BGCOLOR=0x000000&VERSION=1.1.1&LAYERS=nexrad-n0r-wmst&STYLES=default&CRS=EPSG:4326&SRS=EPSG:4326&TIME=2021-06-17T17:40:00Z&WIDTH=1600&HEIGHT=1600&BBOX=-93.45944098029149,44.50999686970849,-93.45674301970848,44.51269483029149
// https://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0r-t.cgi?&REQUEST=GetMap&TRANSPARENT=true&FORMAT=image/png&BGCOLOR=0x000000&VERSION=1.1.1&LAYERS=nexrad-n0r-wmst&STYLES=default&CRS=EPSG:4326&SRS=EPSG:4326&TIME=2021-06-17T17:44:00Z&WIDTH=1600&HEIGHT=1600&BBOX=-93.45944098029149,44.50999686970849,-93.45674301970848,44.51269483029149
// https://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0r-t.cgi?&REQUEST=GetMap&TRANSPARENT=true&FORMAT=image/png&BGCOLOR=0x000000&VERSION=1.1.1&LAYERS=nexrad-n0r-wmst&STYLES=default&CRS=EPSG:4326&SRS=EPSG:4326&TIME=2021-06-17T17:40:00Z&WIDTH=1675&HEIGHT=521&BBOX=-95.66510367833926,43.948247695579795,-91.06457877599551,45.379216933861045

// {
/* <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={{
          gestureHandling: "none",
          zoomControl: false,
          mapTypeControl: false,
          scrollwheel: false,
          fullscreenControl: false,
        }}
        overlayViewDivStyle={{ width: "0%" }}
      >
        <img
          style={{ position: "absolute", transform: "translate(-50%, -50%)" }}
          src={urlImg}
          alt="nexradimg"
        />
        <div
          style={{ width: "100%" }}
          lat={defaultProps.center.lat}
          lng={defaultProps.center.lng}
        >
          <FontAwesomeIcon icon={faMapMarkerAlt} color={"#1B479E"} size="2x" />
        </div>
      </GoogleMapReact> */
// }
