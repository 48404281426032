import Grid2 from '@mui/material/Unstable_Grid2';
import { Typography, Avatar } from '@mui/joy';
import React from "react";
import withAdminContext from '../../store/withAdminContext';
import styled from "styled-components";
import logo from "../../../../assets/hailscale_logo_white.svg";
import { COLORS } from "../../../../constants/colors.js";

const StyledHeader = styled(Grid2)`
  background-color: ${COLORS.BLUE};
  position: fixed;
  top: 0;
  width: 100vw;
  height: 122px;
  z-index: 100;
`;

const StyledImg = styled.img`
  max-width: 261px;
  position: absolute;
  left: 50%;
  top: calc(50% + 6px);
  transform: translate(-50%, -50%);
`;

export const Header = ({ context: { state } }) => {
  const user = 'Hailscale Admin';
  const userLength = user.split(" ");
  const initials = userLength.length > 1 ? userLength[0].substring(0, 1) + userLength[1].substring(0, 1) : userLength[0].substring(0, 2);

  return (
    <StyledHeader container xs={12}>
      <Grid2 xs={3} sx={{ position: "relative", borderRightColor: COLORS.LIGHT_BLUE, borderRightWidth: "1px", borderRightStyle: "solid" }}>
        <StyledImg src={logo} />
      </Grid2>
      <Grid2 xs={6} sx={{ paddingLeft: "100px", borderRightColor: COLORS.LIGHT_BLUE, borderRightWidth: "1px", borderRightStyle: "solid" }} container alignContent="center">
        <Typography level="h1" variant="solid" color="primary" >{state.headerTitleUrl.title}</Typography>
      </Grid2>
      <Grid2 xs={3} container alignContent="center" justifyContent="center">
        <Grid2 container justifyContent="space-around" alignItems="center">
          <Avatar sx={{
            "--Avatar-size": "71px",
            marginRight: "18px"
          }}
          >
            <Typography className="font-28-medium-blue">{initials}</Typography>
          </Avatar>
          <Grid2 textAlign={"left"}>
            <Typography sx={{ lineHeight: "19px" }} className="font-16-medium-white">
              Hello,
            </Typography>
            <Typography sx={{ lineHeight: "19px" }} className="font-16-medium-white">
              {user}
            </Typography>
          </Grid2>
        </Grid2>
      </Grid2>
    </StyledHeader>
  );
}

export default withAdminContext(Header);