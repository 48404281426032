import React from "react";
import withAdminContext from "../../../store/withAdminContext";
import { STAGES } from "../../../../../constants/appointment_stages";
import CardWrapper from "../../Card/CardWrapper";
import FieldRepNeededApp from "./FieldRepNeededApp";

export const BFieldRepNeeded = ({ context: { state } }) => {
  return (
    <div>
      {state.activeAppointments.fieldRepNeeded.appointments.map(app => {
        return app.appointmentStage === STAGES.FIELD_REP_NEEDED &&
          <CardWrapper key={app._id} stage={app.appointmentStage} child={<FieldRepNeededApp app={app} />} />
      })}
    </div>
  );
}

export default withAdminContext(BFieldRepNeeded);