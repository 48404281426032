import React from "react";
import Typography from "@mui/material/Typography";
import { useInspection } from "../Provider";
import styled from "styled-components";

const StyledName = styled(Typography)`
  text-align: left;
  font-family: Colfax Regular !important;
  span {
    font-family: Colfax Medium !important;
  }
`;

export const PropertyManager = ({ showPropertyMgName }) => {
  const { state } = useInspection();
  if (!showPropertyMgName) {
    return null;
  }
  return (
    <StyledName variant="h5">Property Manager: <span>{state.propertyManager}</span></StyledName>
  );
};
export default PropertyManager;