import React, { Fragment } from "react";
import { useAppointment } from "../Provider";
import Input from "../../Common/InputField";

export const ReferralCode = () => {
  const { state, addReferralCode } = useAppointment();
  return (
    <Fragment>
      <Input
        text="Referral Code (optional)"
        placeholder="referral code..."
        textValue={state.referralCode}
        change={(val) => addReferralCode(val.target.value)}
        type="text"
      />
    </Fragment>
  );
};

export default ReferralCode;
