import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import { PageView } from "../Tracking";
import { Grid } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import styled from "styled-components";
import { ROUTES } from "../../constants/routes";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useLocation, useNavigate } from "react-router-dom";
import { OUR_INSPECTORS, INTEGRITY } from "../../constants/inspectors";
import SingleInspector from "./SingleInspector";

const StyledSubTitle = styled(Typography)`
  font-size: 20px !important;
  @media (max-width: 600px) {
    font-size: 12px !important;
    line-height: 14px !important;
  }
`;

const StyledInsImg = styled.img`
  height: 150px;
  @media (max-width: 960px) {
    height: 70px;
  }
`;

export const OurInspectors = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => PageView(), []);
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (location.hash) {
      const partner = document.getElementById(location.hash.replace("#", ""));
      window.scrollTo(0, partner.offsetTop - 120)
    }
  })

  return (
    <Fragment>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>Our Inspectors</title>
        <link rel="canonical" href={`https://www.hailscale.com${ROUTES.OUR_INSPECTORS}`} />
        <meta name="description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Our Inspectors" />
        <meta itemprop="description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta itemprop="image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.hailscale.com/our-inspectors" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Our Inspectors" />
        <meta property="og:description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta property="og:image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Our Inspectors" />
        <meta name="twitter:description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta name="twitter:image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Meta Tags Generated via http://heymeta.com --> */}

        {OUR_INSPECTORS.map(item => <script key={item.title} src={item.review.elfsight.script} defer></script>)}
      </Helmet>
      <Grid container justifyContent="center">
        <Grid item container xs={12} className={isMedium ? "p-r-60 p-l-60 p-t-50" : "p-l-25 p-r-25 p-t-25"}>
          <Grid item xs={12}>
            <Typography variant="h2" className={`text-blue ${isMedium ? "p-b-75" : "p-b-25"}`}>Our Certified Roofers</Typography>
          </Grid>
          <Grid container item xs={12} className="p-l-20 p-r-20 m-b-60">
            <Grid item xs={12} className="text-align-left m-b-20">
              <StyledSubTitle variant="subtitle1">Hailscale is a proprietary weather data application that informs you regarding hail damage on your property.</StyledSubTitle>
            </Grid>
            <Grid item xs={12} className="text-align-left">
              <StyledSubTitle variant="subtitle1">Hailscale partners with local contractors that can help homeowners with everything from inspections to full home improvement projects.</StyledSubTitle>
            </Grid>
          </Grid>
          {OUR_INSPECTORS.map(ins => (
            <SingleInspector key={ins.title} ins={ins} />
          ))}
        </Grid>
        <Grid item container xs={12} className={isMedium ? "p-l-45 p-r-45" : "p-l-25 p-r-25"}>
          <Grid item xs={12} className="p-t-75 p-b-75">
            <Typography variant="h2" className="text-blue">Roofer Integrity at Hailscale</Typography>
          </Grid>
          <Grid item container spacing={3}>
            {INTEGRITY.map(item => (
              <Grid
                key={item.title}
                item
                xs={12}
                md={6}
                className={`text-blue ${isMedium ? "m-b-70 p-l-30 p-r-30" : "m-b-30"}`}
              >
                <StyledInsImg src={item.icon} alt={item.title} className="m-b-22" />
                <Typography variant="h5" className="m-b-22">{item.title}</Typography>
                <Typography variant="body2" className="margin-auto">{item.paragraph.map((item, i) => <span key={i}>{item}</span>)}</Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item className="m-b-65">
          <Button
            onClick={() => navigate(ROUTES.LANDING_PAGE)}
          >Back to Home</Button>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default OurInspectors;