export const STAGES = {
  PARTNER_NEEDED: "partner_needed",
  FIELD_REP_NEEDED: "field_rep_needed",
  INSPECTION_OVERDUE: "inspection_overdue",
  AWAITING_INSPECTION: "awaiting_inspection",
  HOMEOWNER_TO_FILE: "homeowner_to_file",
  AWAITING_ADJS_DATE: "awaiting_adjs_date",
  AWAITING_ADJS_OUTCOME: "awaiting_adjs_outcome",
  READY_TO_BE_CLOSED: "ready_to_be_closed",
  APPOINTMENT_COMPLETE: "appointment_complete",
}; 