import { Fragment, useState } from "react";
import dayjs from 'dayjs';
import DateImg from "../../assets/Date_Black.webp";
import styled from "styled-components";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { findFirstAvailableDate } from "./findFirstAvailableDate";

const CalImg = styled.img`
${props => props.ismedium === "true" ? `
`: `
  z-index: 1;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
`}
  width: 26px;
  height: auto;
`;

const CustomIcon = ({ isMedium }) => {
  return (<CalImg ismedium={String(isMedium)} src={DateImg} alt="keyboard icon" />)
}
const CalendarPicker = ({ onChange, sxStyles = {} }) => {
  const [selectedDate, setSelectedDate] = useState(findFirstAvailableDate(dayjs()));
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Fragment>
      {!isMedium && <CustomIcon isMedium={isMedium} />}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          shouldDisableDate={(date) =>
            date.day() === 0 ||
            date.format("MM/DD/YYYY") === dayjs().format("MM/DD/YYYY") ||
            date.format("MM/DD/YYYY") ===
            dayjs().add(1, "days").format("MM/DD/YYYY")
          }
          disablePast
          slots={{ openPickerIcon: CustomIcon }}
          format="dddd, MMMM DD"
          value={dayjs(selectedDate)}
          onChange={(val) => { setSelectedDate(val); onChange(val) }}
          sx={sxStyles}
        />
      </LocalizationProvider>
    </Fragment>
  )
}

export default CalendarPicker;