export const reducer = (state = {}, action) => {
  switch (action.type) {
    case "change_header_title_url":
      return { ...state, headerTitleUrl: action.payload };
    case "add_active_appointments":
      return { ...state, activeAppointments: action.payload };
    case "add_appointment_numbers":
      return { ...state, appointmentNumbers: action.payload };
    case "add_partner_names":
      return { ...state, partnerNames: action.payload };
    case "are_appointments_loading":
      return { ...state, areAppointmentsLoading: action.payload };
    case "add_partners":
      return { ...state, partners: action.payload };
    default:
      return state;
  }
};
