import React, { Fragment } from "react";
import { useInspection } from "../Provider";
import BasicSelect from "../../Common/HSCSelect";

export const LIKELIHOOD_OPTIONS = [
  {
    description: "1 - Unlikely, but worth it",
    value: 1,
  },
  { description: "2", value: 2 },
  { description: "3 - Going to be tough", value: 3 },
  { description: "4", value: 4 },
  { description: "5 - Coin flip", value: 5 },
  { description: "6", value: 6 },
  { description: "7 - Looks promising", value: 7 },
  { description: "8", value: 8 },
  { description: "9", value: 9 },
  {
    description: "10 - I'd be surprised if they did not",
    value: 10,
  },
];

export const ClaimLikelihood = () => {
  const { state, addLikelihood } = useInspection();

  return (
    <Fragment>
      {state.recommendFilingClaim === "No" ?
        ""
        :
        < BasicSelect
          text={"Probability for approved claim"}
          label="Choose..."
          selectValue={state.approvalLikelihood}
          change={(val) => addLikelihood(val.target.value)}
          listMap={LIKELIHOOD_OPTIONS}
        />
      }
    </Fragment>
  );
};
