import Typography from "@mui/material/Typography";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../../../constants/colors";
import withAdminContext from "../../store/withAdminContext";
import CardWrapper from "../Card/CardWrapper";
import { AdminButton } from "../Common/AdminButton";
import PersonComponent from "./PersonComponent";

const PartnerProfileChild = ({ context: { state } }) => {
  const navigate = useNavigate();
  if (state.partners.length === 0) {
    navigate("/admin/partner-profiles");
  }

  const [fieldRepDirectory, setFieldRepDirectory] = useState([]);
  const [contactInfoDirectory, setContactInfoDirectory] = useState([]);
  const [partnerContractor, setPartnerContractor] = useState("");
  const [newRep, setNewRep] = useState(false);
  const [newContactInfo, setNewContactInfo] = useState(false);

  useEffect(() => {
    state.partners.map(partner => (
      state.headerTitleUrl.url.includes(partner._id) &&
      loadDirectory(partner)
    ))
    // eslint-disable-next-line
  }, [])

  const loadDirectory = (partner) => {
    setPartnerContractor(partner._id);
    setContactInfoDirectory([...partner.contactInfo]);
    setFieldRepDirectory([...partner.fieldReps]);
  }

  const removePersonLine = (fieldRep) => {
    if (fieldRep) {
      setFieldRepDirectory([...fieldRepDirectory.slice(0, fieldRepDirectory.length - 1)])
      setNewRep(false);
    } else {
      setContactInfoDirectory([...contactInfoDirectory.slice(0, contactInfoDirectory.length - 1)])
      setNewContactInfo(false);
    }
  }

  const modifyPerson = (details, index) => {

    if (details.type === 'add') {
      if (details.fieldRep) {
        let newRepDirectory = fieldRepDirectory.slice(0, fieldRepDirectory.length - 1);
        setFieldRepDirectory([...newRepDirectory, details.details]);
        setNewRep(false);
      } else {
        let newContactInfoDirectory = contactInfoDirectory.slice(0, contactInfoDirectory.length - 1);
        setContactInfoDirectory([...newContactInfoDirectory, details.details]);
        setNewContactInfo(false);
      }
    } else if (details.type === 'update') {
      if (details.fieldRep) {
        let newRepDirectory = fieldRepDirectory;
        newRepDirectory.splice(index, 1, details.details);
        setFieldRepDirectory([...newRepDirectory]);
      } else {
        let newContactInfoDirectory = contactInfoDirectory;
        newContactInfoDirectory.splice(index, 1, details.details);
        setContactInfoDirectory([...newContactInfoDirectory]);
      }

    } else if (details.type === 'delete') {
      if (details.fieldRep) {
        let newRepDirectory = fieldRepDirectory;
        newRepDirectory.splice(index, 1);
        setFieldRepDirectory([...newRepDirectory]);
      } else {
        let newContactInfoDirectory = contactInfoDirectory;
        newContactInfoDirectory.splice(index, 1);
        setContactInfoDirectory([...newContactInfoDirectory]);
      }
    }
  }
  const styles = {
    fontWeight: "bold",
    paddingLeft: "12px"
  }

  const subTitleStyles = {
    marginTop: "30px !important",
    marginBottom: "-10px !important",
    color: COLORS.BLUE
  }

  const partnerContent = <Grid2 container>
    <Grid2 xs={12}>
      <Typography className="text-align-left p-l-10" variant="h3">Staff Directory</Typography>
    </Grid2>
    <Grid2 container xs={12} className="text-align-left -m-b-30">
      <Grid2 xs={3}>
        <Typography sx={styles} variant="h5">Name:</Typography>
      </Grid2>
      <Grid2 xs={3}>
        <Typography sx={styles} variant="h5">Title:</Typography>
      </Grid2>
      <Grid2 xs={3}>
        <Typography sx={styles} variant="h5">Email address:</Typography>
      </Grid2>
      <Grid2 xs={3}>
        <Typography sx={styles} variant="h5">PhoneNumber:</Typography>
      </Grid2>
    </Grid2>

    <Grid2 container xs={12} className="text-align-left">
      <Grid2 xs={12}>
        <Typography sx={{ ...styles, ...subTitleStyles }} variant="h6">Contact Info</Typography>
      </Grid2>
      {contactInfoDirectory.map((item, i) => (
        <PersonComponent
          key={i}
          item={item}
          modifyPerson={(val) => modifyPerson(val, i)}
          cancelPerson={(val) => removePersonLine(val)}
          partnerContractor={partnerContractor}
        />
      ))}

      <Grid2 xs={12} sx={{ marginTop: "20px" }} container justifyContent={"flex-end"}>
        <AdminButton
          text={"Add new contact info"}
          isDisabled={newContactInfo}
          onClick={() => {
            setContactInfoDirectory([...contactInfoDirectory, { fullName: "", phoneNumber: "", email: "", isAdd: true }])
            setNewContactInfo(true)
          }}
        />
      </Grid2>
    </Grid2>

    <Grid2 container xs={12} className="text-align-left">
      <Grid2 xs={12}>
        <Typography sx={{ ...styles, ...subTitleStyles }} variant="h6">Field Reps</Typography>
      </Grid2>
      {fieldRepDirectory.map((item, i) => (
        <PersonComponent
          key={i}
          item={item}
          fieldRep
          modifyPerson={(val) => modifyPerson(val, i)}
          cancelPerson={(val) => removePersonLine(val)}
          partnerContractor={partnerContractor}
        />
      ))}

      <Grid2 xs={12} sx={{ marginTop: "20px", marginBottom: "20px" }} container justifyContent={"flex-end"}>
        <AdminButton
          text={"Add new field rep"}
          isDisabled={newRep}
          onClick={() => {
            setFieldRepDirectory([...fieldRepDirectory, { fullName: "", phoneNumber: "", email: "", isAdd: true }])
            setNewRep(true)
          }}
        />
      </Grid2>
    </Grid2>
  </Grid2>;

  return (
    <Grid2 xs={12} container rowSpacing={2}>
      <CardWrapper
        classNames={"fill-width p-l-20 p-r-65 p-b-25"}
        child={partnerContent}
      />
    </Grid2>
  );
}

export default withAdminContext(PartnerProfileChild);