
export const classnames = [
  "border-BLUE-2",
  "border-radius-10",
  "font-size-16",
  "text-blue",
  "text-font-fam-colfax-medium",
  "p-t-12",
  "p-b-12",
  "p-l-24",
  "p-r-24",
];