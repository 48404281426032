import johnsonHome from "../assets/homeowner_reviews/johnsonHome.webp";
import johnsonHeadshot from "../assets/homeowner_reviews/johnsonHeadshot.webp";
import griswoldHome from "../assets/homeowner_reviews/griswoldHome.webp";
import griswoldHeadshot from "../assets/homeowner_reviews/griswoldHeadshot.webp";
import olsonHome from "../assets/homeowner_reviews/olsonHome.webp";
import olsonHeadshot from "../assets/homeowner_reviews/olsonHeadshot.webp";

export const HOME_OWNER_REVIEWS = [
  {
    image: johnsonHome,
    quote: "Hailscale did an initial evaluation of our roof. They were confident they could facilitate maximizing our insurance benefits to replace our roof. From start to finish, they were by our side and worked directly with our insurance company... We could not be happier we chose Hailscale to help us!",
    reviewLink: "https://goo.gl/maps/yTJbaz6nyTXQVyUD8",
    owner: {
      name: "Katy Johnson",
      headshot: johnsonHeadshot,
      location: "Minnetonka",
      yearRestored: "2022"
    }
  },
  {
    image: griswoldHome,
    quote: "Hailscale was easy to use and the automated process kept me in the loop without any confusion for next steps. Hailscale’s roofer was on time for the inspection and came back for the insurance adjustment. Our new roof was installed in August!",
    reviewLink: "https://goo.gl/maps/puYMMGirJRCDMNvr6",
    owner: {
      name: "Ben Griswold",
      headshot: griswoldHeadshot,
      location: "Minneapolis",
      yearRestored: "2022"
    }
  },
  {
    image: olsonHome,
    quote: "I wanted to know the hail damage to my roof but a few years ago I promised my wife I would not go on the roof anymore. It’s not worth risking my life. Hailscale told me the condition of my roof in seconds, allowed me to get updates and even scheduled an inspection with a contractor that led to my insurance company installing a new roof!",
    reviewLink: "https://goo.gl/maps/wm6tSZUXeos1KAy76",
    owner: {
      name: "Erik Olson",
      headshot: olsonHeadshot,
      location: "St. Paul",
      yearRestored: "2022"
    }
  }
];
