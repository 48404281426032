import React, { Fragment, useEffect, useState } from "react";
import { Grid, useTheme, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import { ROUTES } from "../../constants/routes";
import { SOCIALS } from "../../constants/socials";
import fb from "../../assets/social_media/fb.webp";
import instagram from "../../assets/social_media/instagram.webp";
import twitter from "../../assets/social_media/twitter.webp";
import linkedin from "../../assets/social_media/linkedin.webp";
import { MENU_ARR } from "../../constants/menu";
import { Link } from "react-router-dom";

const Links = [
  // {
  //   text: "Do Not Sell My Personal Information",
  //   route: ROUTES.DO_NOT_SELL
  // },
  // {
  //   text: "Privacy Policy",
  //   route: ROUTES.PRIVACY_POLICY
  // },
  // {
  //   text: "Cookie Settings",
  //   route: ROUTES.COOKIE_SETTINGS
  // },
  {
    text: "Contact Us",
    route: ROUTES.ABOUT_US
  }
];

const SocialMedia = [
  {
    icon: fb,
    link: SOCIALS.FACEBOOK,
  },
  {
    icon: instagram,
    link: SOCIALS.INSTAGRAM,
  },
  {
    icon: twitter,
    link: SOCIALS.TWITTER,
  },
  // {
  //   icon: tiktok,
  //   link: SOCIALS.TIKTOK,
  // },
  {
    icon: linkedin,
    link: SOCIALS.LINKEDIN,
  }
];

const StyledFooter = styled.footer`
  text-align: left; 
  padding-top: 40px; 
  padding-bottom: 20px; 
  ${(props) => props.isFixed === "true" ? `
    position: fixed;
    bottom: 0;
    `: `
    position: static
    `}`;

const StyledLink = styled.a`
color: black;
margin-right: 5px;
&:hover {
  opacity: 0.6;
}
`;

const StyledImg = styled.img`
width: 46px;
height: 46px;
object-fit: cover;
margin: 0 6px;
  @media (max-width: 960px) {  
    width: 32px;
    height: 32px;
  }
`;

const StyledDivider = styled.span`
font-weight: 600; 
font-size: 24px;
  @media (max-width: 600px) {  
    font-weight: 600; 
    font-size: 16px;
  }
`;

const Footer = () => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const [isFixed, setIsFixed] = useState(true);
  const slug = window.location.pathname;

  const tagline = <p style={{ display: "none" }}>
    We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action.
  </p>

  useEffect(() => {
    if (slug !== "/error") {
      Object.values(ROUTES).map(rt => {
        if (rt === slug) {
          setIsFixed(false);
          return rt;
        }
        return rt;
      });
    };
  });

  if (slug.includes('login') || slug.includes('admin')) {
    return null;
  }

  return (
    <StyledFooter
      isFixed={String(isFixed)}
      className="box-shadow-none text-black bg-light-grey"
      id="hsc_footer"
    >
      <Grid
        className="fill-height p-r-40 p-l-40"
        container
      >
        <Grid item container alignContent="flex-start" md={8} xs={12}>
          <Grid item container xs={12} className="m-b-35">
            {MENU_ARR.map(item => (
              <Link key={item.title} to={item.link} className="m-r-20 m-t-10 text-blue text-font-fam-colfax-medium">{item.title}</Link>
            ))}
          </Grid>
          <Grid item className="m-b-10">
            {Links.map((link, i) => (
              <Fragment key={i}>
                <StyledLink className="text-font-fam-colfax-medium" href={link.route}>{link.text}</StyledLink>
                {Links.length - 1 === i ?
                  ""
                  : < StyledDivider className="text-font-fam-colfax-medium"> | </StyledDivider>
                }
              </Fragment>
            ))}
          </Grid>
          <Grid item>
            {tagline}
            <p className={`text-font-fam-colfax-light ${isMedium ? "m-a-0" : "m-b-20"}`}>
              Hailscale is committed to ensuring digital accessibility for individuals with disabilities. We are continuously working to improve the accessibility of our web experience for everyone, and we welcome feedback and accommodation requests.
            </p>
            {tagline}
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignContent="flex-start"
          justifyContent={isMedium ? "flex-end" : "center"}
          md={4}
          xs={12}
        >
          <Grid item>
            {SocialMedia.map((social, i) => (
              <a key={i} href={social.link} target="_blank" rel="noopener noreferrer">
                <StyledImg src={social.icon} alt={social.link} />
              </a>
            ))}
          </Grid>
          <Grid item>
            <p className='m-t-20 text-font-fam-colfax-regular'>© Hailscale 2022 - all rights reserved</p>
          </Grid>
        </Grid>
      </Grid>
    </StyledFooter>
  );
};

export default Footer;
