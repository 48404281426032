import React, { Fragment } from "react";
import { useAppointment } from "../Provider";
import Input from "../../Common/InputField.js";

export const Email = () => {
  const { state, addEmail } = useAppointment();
  return (
    <Fragment>
      <Input
        text="Email"
        placeholder="your email..."
        textValue={state.email}
        isRequired
        change={(val) => addEmail(val.target.value)}
        type="email"
      />
    </Fragment>
  );
};

export default Email;
