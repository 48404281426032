import React from "react";
import { Grid } from "@material-ui/core";
import { Button, Typography } from "@mui/material";
import { getAddressParam, getRoofAgeParam } from "../store/selectors";
import { postIpAddress } from "../../services";
import { ROUTES } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { COLORS } from "../../constants/colors";
import greenHouse from "../../assets/House_icon_green.webp";
import tanHouse from "../../assets/House_icon_tan.webp";

const StyledParagraph = styled(Typography)`
  font-size: 20px !important;
  line-height: 35px !important;
  padding: 21px 10px;
  max-width: 430px;
  @media (max-width: 960px) {
    font-size: 16px !important;
    line-height: 22px !important;
  }
`;

const StyledTitle = styled(Typography)`
color: ${COLORS.RED};
margin-bottom: 12px;
`;

const StyledSubTitle = styled(Typography)`
  font-size: 27px !important;
  margin-bottom: 25px;
`;

const StyledImg = styled.img`
  width: 51px; 
  height: 49px;
@media (max-width: 960px) {
  width: 36px; 
  height: 34px;
}
`;

export const IPWarningModalContent = ({ dailyCount, closeModal, modalState, ipAddress }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  const SubmitRating = () => {
    closeModal();
    postIpAddress({ dailyCount: dailyCount + 1, ipAddress: ipAddress });
    if (getAddressParam(modalState) && getRoofAgeParam(modalState)) {
      navigate(ROUTES.LOADING_REPORT);
    }
  };

  const paragraph = `${8 - dailyCount} address${dailyCount === 7 ? '' : 'es'} remaining today`;
  const paragraphTwo = `Users can check up to 8 addresses everyday. You have ${8 - dailyCount} left until you reach today’s limit.`;
  let houses = [];

  let count = 0;
  while (count < 8) {
    if (count < dailyCount) {
      houses.push(<StyledImg key={count} src={greenHouse} alt="" />);
    } else {
      houses.push(<StyledImg key={count} src={tanHouse} alt="" />);
    }
    count++;
  }

  return (
    <Grid container justifyContent="center" className={`${isMedium ? "p-b-24" : ""} text-align-center`}>
      <StyledTitle variant="h2">Thanks for using Hailscale!</StyledTitle>
      <StyledSubTitle variant="body2">{paragraph}</StyledSubTitle>
      <div>
        {[...houses]}
      </div>
      <StyledParagraph>{paragraphTwo}</StyledParagraph>
      <Grid item container xs={12} justifyContent="center">
        <Button
          onClick={() => SubmitRating()}
        >Continue to my rating
        </Button>
        <Button
          variant="outlined"
          className={`${isMedium ? "m-l-15" : "m-t-15"}`}
          onClick={() => closeModal()}
        >Back to home
        </Button>
      </Grid>
    </Grid>
  );
}

export default IPWarningModalContent;