import React from "react";
import { Grid, Typography, Checkbox } from "@mui/material";
import styled from "styled-components";
import check from "../../assets/check-solid.svg";

const StyledCheckbox = styled(Checkbox)`
&.MuiCheckbox-root > .MuiIconButton-label > .MuiSvgIcon-root {
    color: transparent;
  }
  > svg {
    color: transparent;
  }
`;

const Image = styled.img`
width: 20px;
height: auto;
`;

const StyledSpan = styled.span`
  height: 23px;
  width: 23px;
  display: flex;
  background-color: white;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #0E5CBF !important;
`;

export const HailScaleCheckbox = ({ setCheckBox, label }) => {


  return (
    <Grid item container xs={12} className="m-t-24">
      <Grid item xs={1} container alignItems="center">
        <StyledSpan>
          <StyledCheckbox
            sx={{
              '&:hover': { bgcolor: 'transparent' },
            }}
            checkedIcon={<Image src={check} alt="" />}
            inputProps={{ "aria-label": "primary checkbox" }}
            onChange={(event) => setCheckBox(event.target.checked)}
          />
        </StyledSpan>
      </Grid>
      <Grid item xs={11} className="p-l-15">
        <Typography variant="body2">{label}</Typography>
      </Grid>
    </Grid>
  );
}

export default HailScaleCheckbox;