import { Box } from "@mui/material";
import { COLORS } from "../../constants/colors";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const HamburgerLine = ({ id }) =>
  <Box
    id={id}
    component={"div"}
    sx={{
      position: "absolute",
      height: "4px !important",
      width: "100%",
      borderRadius: "50px",
      backgroundColor: COLORS.BLUE,
      transition: "all 0.3s",
      transform: "rotate(0deg)"
    }}
  />;

export const DynamicHamburger = ({ isHamburger }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  const burgerStyles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: `${isMedium ? "30px" : "18px"} !important`,
    width: `${isMedium ? "50px" : "31px"} !important`,
    position: "relative",
    alignItems: "center"
  };

  const hideBurger = isHamburger ? {
    "#ham1": {
      top: "0%"
    },
    "#ham2": {
      top: "50%"
    },
    "#ham3": {
      top: "100%"
    }
  } : {
    "#ham1": {
      transform: "rotate(45deg)",
      top: "50%"
    },
    "#ham2": {
      width: "0 !important",
      top: "50%"
    },
    "#ham3": {
      transform: "rotate(-45deg)",
      top: "50%"
    }
  };

  return (
    <Box
      component={"div"}
      sx={{ ...burgerStyles, ...hideBurger }}
    >
      <HamburgerLine id={"ham1"} />
      <HamburgerLine id={"ham2"} />
      <HamburgerLine id={"ham3"} />
    </Box>
  )
};