import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../../../constants/colors";
import { STAGES } from "../../../../../constants/appointment_stages";
import { AdminAppointmentProvider } from "../Provider";

const StyledDiv = styled.div`
border: solid 3px ${(props) => (props.borderColor)};
padding: 12px 50px;
margin: 45px;
`;

export default function CardWrapper({ child, stage, classNames }) {


  let border_color;
  switch (stage) {
    case STAGES.PARTNER_NEEDED:
      border_color = COLORS.RED
      break;
    case STAGES.FIELD_REP_NEEDED:
      border_color = COLORS.YELLOW
      break;
    case STAGES.AWAITING_INSPECTION:
      border_color = COLORS.GREEN
      break;
    case STAGES.INSPECTION_OVERDUE:
      border_color = COLORS.RED
      break;
    case STAGES.HOMEOWNER_TO_FILE:
      border_color = COLORS.TAN
      break;
    case STAGES.AWAITING_ADJS_DATE:
      border_color = COLORS.BLUE
      break;
    case STAGES.AWAITING_ADJS_OUTCOME:
      border_color = COLORS.BLUE
      break;
    default:
      border_color = COLORS.BLACK
      break;
  }

  return (
    <AdminAppointmentProvider>
      <StyledDiv borderColor={border_color} className={`bg-light-grey border-radius-29 ${classNames}`}>
        {child}
      </StyledDiv>
    </AdminAppointmentProvider>
  );
}