import * as React from 'react';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import withAdminContext from '../../store/withAdminContext';
import { COLORS } from '../../../../constants/colors';
import downArrow from "../../assets/downArrow.svg"

const AdminSelect = ({ context: { state }, isDisabled, onChange, value }) => {

  return (
    <Select
      disabled={isDisabled}
      defaultValue={value}
      indicator={isDisabled ? "" : <img alt="" src={downArrow} />}
      onChange={onChange}
      key={value}
      sx={{
        padding: 0,
        minWidth: 134,
        width: 'fit-content',
        border: 'none',
        [`& .${selectClasses.indicator}`]: {
          transition: '0.2s',
          color: COLORS.BLUE,
          [`&.${selectClasses.expanded}`]: {
            transform: 'rotate(-180deg)',
          },
        },
      }}
    >
      <Option value="none_selected">None Selected</Option>
      {state.partnerNames.map(partner => {
        return <Option
          key={partner._id}
          value={partner._id}>
          {partner.tradeName}
        </Option>
      })}
    </Select>
  );
}

export default withAdminContext(AdminSelect);