export const reducer = (state = {}, action) => {
  switch (action.type) {
    case "add_report":
      return { ...state, report: action.payload };
    case "add_logged_in":
      return { ...state, loggedIn: action.payload };
    case "add_rating_params":
      return { ...state, ratingParams: action.payload };
    case "add_zipcodes":
      return { ...state, zipcodes: action.payload };
    case "add_articles":
      return { ...state, articles: action.payload };
    case "add_device_data":
      return { ...state, deviceData: action.payload };
    case "add_app_inspection":
      return { ...state, appInspections: action.payload };
    case "add_field_reps":
      return { ...state, fieldReps: action.payload };
    default:
      return state;
  }
};
