import React from "react";
import { useInspection } from "../Provider";
import BasicSelect from "../../Common/HSCSelect";

const CLAIM_FILED = [
  "Yes",
  "No",
  "TBD / Follow-up needed"
];

export const RecommendFilingClaim = () => {
  const { state, addRecommendFilingClaim } = useInspection();

  return (
    <BasicSelect
      text="Do You Recommend Filing a Claim?"
      label="Choose..."
      selectValue={state.recommendFilingClaim}
      change={(val) => addRecommendFilingClaim(val.target.value)}
      listMap={CLAIM_FILED}
    />
  );
};
