export const changeHeaderTitleUrl = (payload) => ({
  type: "change_header_title_url",
  payload,
});

export const addActiveAppointments = (payload) => ({
  type: "add_active_appointments",
  payload,
});

export const addAppointmentNumbers = (payload) => ({
  type: "add_appointment_numbers",
  payload,
});

export const addPartnerNames = (payload) => ({
  type: "add_partner_names",
  payload,
});
export const addPartners = (payload) => ({
  type: "add_partners",
  payload,
});

export const areAppointmentsLoading = (payload) => ({
  type: "are_appointments_loading",
  payload,
});