import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getInsuranceProviders } from "../../../services";
import { useInspection } from "../Provider";
import { InputLabel, Box } from '@mui/material';
import { StyledLabel, StyledFormControl, StyledSelect, StyledMenuItem } from "../../Common/StyledElements";
import styled from "styled-components";

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
 color: black !important;
 font-size: 35px;
 @media (max-width: 959px) {
  font-size: 25px;
 }
`;

export const InsuranceProvider = () => {
  const { state, addInsuranceProvider } = useInspection();
  const [insuranceProviders, setInsuranceProviders] = useState([]);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    fetchInsuranceProviders();
  }, []);

  const fetchInsuranceProviders = async () => {
    try {
      const res = await getInsuranceProviders();
      setInsuranceProviders([
        ...res.data.sort((a, b) => a.provider_name.toLowerCase().localeCompare(b.provider_name.toLowerCase())),
        { provider_name: "Other" },
      ]);
    } catch (err) {
      setHasError(true);
    }
  };

  return (
    <Box>
      <StyledLabel>
        Insurance company<span className="text-red">*</span>
      </StyledLabel>
      <StyledFormControl fullWidth>
        <InputLabel id={`simple-select-insurance`}>insurance...</InputLabel>
        {hasError ? (
          <Typography
            variant="subtitle2"
            className="text-align-left text-red m-b-8"
          >
            There was an issue fetching insurance providers, please reload page or
            try again later.
          </Typography>
        ) : (
          <StyledSelect
            labelId="simple-select-insurance"
            id="insurance-select"
            IconComponent={StyledExpandMoreIcon}
            label={"insurance..."}
            value={state.insuranceProvider}
            onChange={(val) => addInsuranceProvider(val.target.value)}
          >
            {insuranceProviders.map((opt, i) => (
              < StyledMenuItem
                style={
                  {
                    border: `1px solid`,
                    borderBottom: i === insuranceProviders.length - 1 ? '1px solid' : 'none',
                    borderBottomRightRadius: i === insuranceProviders.length - 1 ? 10 : 0,
                    borderBottomLeftRadius: i === insuranceProviders.length - 1 ? 10 : 0,
                  }
                }
                value={opt.provider_name}
                key={opt.provider_name} >
                {opt.provider_name}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        )
        }
      </StyledFormControl >
    </Box>
  );
};
