import React, { Fragment } from "react";
import { useInspection } from "../Provider";
import Input from "../../Common/InputField";

export const FullName = () => {
  const { state, addFullName } = useInspection();
  return (
    <Fragment>
      <Input
        text="Homeowner name"
        placeholder="homeowner name..."
        textValue={state.fullName}
        change={(val) => addFullName(val.target.value)}
        type="text"
      />
    </Fragment>
  );
};
export default FullName;