import React from "react";
import { useInspection } from "../Provider";
import BasicSelect from "../../Common/HSCSelect";

const HIGHEST_POINT = [
  "Yes",
  "No"
];

export const AccessHighestPoint = () => {
  const { state, addAccessHighestPoint } = useInspection();

  return (
    <BasicSelect
      text="Did you access the highest point of the roof?"
      label="Choose..."
      selectValue={state.accessHighestPoint}
      change={(val) => addAccessHighestPoint(val.target.value)}
      listMap={HIGHEST_POINT}
    />
  );
};
