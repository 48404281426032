import React from "react";
import Button from "@mui/material/Button";
import { Event } from "../Tracking";
import { useScreenWidth } from "../Common/useScreenWidth";

export const ReportBtn = ({ onSubmit }) => {
  const onSubmitEvent = () => {
    Event("Generate Report", "onClick");
    onSubmit();
  };

  const isMedium = useScreenWidth();
  return (
    <Button
      sx={{
        fontFamily: "Colfax Bold",
        fontSize: `${isMedium ? "30px" : "16px"} !important`,
        padding: `0 ${isMedium ? "20px" : "5px"}`,
        width: "fit-content",
        boxShadow: "0 5px 8px 0 rgba(18, 48, 107, 0.29)",
      }}
      onClick={onSubmitEvent}
    >See results</Button>
  );
};

export default ReportBtn;
