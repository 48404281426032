import React from "react";
import { useAppointment } from "../Provider";
import BasicSelect from "../../Common/HSCSelect";

export const SHINGLE_TYPES = [
  {
    description: "Asphalt (most common)",
    value: "Asphalt"
  },
  {
    description: "Cedar Shake",
    value: "Cedar Shake"
  },
  {
    description: "Metal",
    value: "Metal"
  },
  {
    description: "Other",
    value: "Other"
  },
  {
    description: "Not Sure",
    value: "Not Sure"
  },
];

export const ShingleType = () => {
  const { state, addShingleType } = useAppointment();

  return (
    <BasicSelect
      text="Roof Type"
      label="your roof type..."
      selectValue={state.shingleType}
      change={(val) => addShingleType(val.target.value)}
      listMap={SHINGLE_TYPES}
    />
  );
};
