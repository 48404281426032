import React, { Fragment, useEffect, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { COLORS } from "../../../../constants/colors";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../../constants/routes";
import withAdminContext from "../../store/withAdminContext";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

const StyledNumberPhrase = styled.p`
  width: 100%;
  lineHeight: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  cursor: pointer;
  margin-bottom: 8px;
`;

export const AppManagement = ({ context: { state } }) => {
  const [height, setHeight] = useState(0);
  const navigate = useNavigate();

  const AppManageArr = [
    {
      color: COLORS.RED,
      title: "Contractor Assignment Needed",
      appointments: state.activeAppointments.partnerNeeded.count,
      route: ADMIN_ROUTES.PARTNER_NEEDED.route
    },
    {
      color: COLORS.YELLOW,
      title: "Field Rep Assignment Needed",
      appointments: state.activeAppointments.fieldRepNeeded.count,
      route: ADMIN_ROUTES.FIELD_REP_NEEDED.route
    },
    {
      color: COLORS.GREEN,
      title: "Missing Inspection Report",
      appointments: state.activeAppointments.inspectionOverdue.count,
      route: ADMIN_ROUTES.MISSING_INSPECTION.route
    },
    {
      color: COLORS.TAN,
      title: "Waiting on Homeowner to File Claim",
      appointments: state.activeAppointments.homeownerToFile.count,
      route: ADMIN_ROUTES.HOMEOWNER_TO_FILE.route
    },
    {
      color: COLORS.BLUE,
      title: "Manage Claim Status",
      appointments: state.activeAppointments.awaitingAdjsDate.count + state.activeAppointments.awaitingAdjsOutcome.count + state.activeAppointments.readyToBeClosed.count,
      route: ADMIN_ROUTES.MANAGE_CLAIM_STATUS.route
    },
    {
      color: COLORS.LIGHT_BLUE,
      title: "All Active Appointments",
      appointments: state.activeAppointments.activeApps,
      route: ADMIN_ROUTES.ACTIVE.route
    }
  ];

  let CheckCount = AppManageArr[0].appointments + AppManageArr[1].appointments + AppManageArr[2].appointments + AppManageArr[3].appointments + AppManageArr[4].appointments;
  let check = CheckCount === AppManageArr[5].appointments;

  useEffect(() => {
    setHeight((window.innerHeight - 122 - 100 - 48) / 2);
  }, [])

  return (
    <div style={{ position: "fixed" }}>
      <Grid2 sx={{ padding: "50px" }} xs={12} container>
        {AppManageArr.map((category, i) => {
          return <Grid2
            key={i}
            xs={4}
            sx={{ padding: "10px" }}
          >
            <Grid2
              onClick={() => navigate(`/admin/appointments/${category.route}`)}
              sx={{
                border: `5px solid ${category.color}`,
                maxHeight: "290px",
                height: height + "px",
                width: "100%",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingRight: "28px",
                position: "relative",
                cursor: "pointer",
                transition: "0.2s",
                "&:hover": {
                  transform: "scale(1.02)"
                }
              }}
              className="grey-background"
              container
              alignContent={"space-between"}
            >
              <Grid2 sx={{ width: "100%" }}>
                <Typography sx={{ width: "100%", maxWidth: "150px" }} className="text-align-left p-l-28 font-24-regular-black">{category.title}</Typography>
              </Grid2>
              <Grid2 sx={{ width: "100%" }}>
                {category.route === ADMIN_ROUTES.MANAGE_CLAIM_STATUS.route &&
                  <Fragment>
                    <StyledNumberPhrase>
                      Missing Adjustment Date<span className="font-18-medium-black m-l-5">{state.activeAppointments.awaitingAdjsDate.count}</span>
                    </StyledNumberPhrase>
                    <StyledNumberPhrase>
                      Missing Adjustment Outcome<span className="font-18-medium-black m-l-5">{state.activeAppointments.awaitingAdjsOutcome.count}</span>
                    </StyledNumberPhrase>
                    <StyledNumberPhrase>
                      Post Adjustment<span className="font-18-medium-black m-l-5">{state.activeAppointments.readyToBeClosed.count}</span>
                    </StyledNumberPhrase>
                  </Fragment>
                }
                <Typography
                  sx={{
                    width: "100%",
                    lineHeight: "40px"
                  }}
                  className="text-align-right font-46-regular-black"
                >
                  {category.appointments}
                </Typography>
              </Grid2>

            </Grid2>
          </Grid2>
        })}
      </Grid2>
      <span id="getMe" className={check ? "font-26-medium-green" : "font-26-medium-red"}>Count Check: {CheckCount}</span>
    </div >
  );
}

export default withAdminContext(AppManagement);