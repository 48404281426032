import React, { useEffect, Fragment } from "react";
import { Helmet } from "react-helmet";
import { Grid } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { PageView } from "../Tracking";
import { ROUTES } from "../../constants/routes";
import styled from "styled-components";
import { StyledDiv } from "../Common/StyledElements";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import withLocalContext from "../store/withLocalContext";
import { COLORS } from "../../constants/colors";
import { useNavigate } from "react-router-dom";
import { OurCertifiedRoofersToolTip } from "../Tooltips/Tooltips";
import ReactDOM from 'react-dom';
import { Button } from "@mui/material";

const StyledImg = styled.img`
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1 / 0.65;
  border-radius: 10px;
`;

const StyledCredit = styled(Typography)`
  text-align: left;
  color: ${COLORS.LIGHT_BLUE};
  font-size: 14px !important;
  margin-top: 14px;
  @media (max-width: 600px) {
    font-size: 12px !important;
    margin-top: 5px;
  }
`;

const StyledTitle = styled(Typography)`
  font-size: 35px !important;
  @media (max-width: 959px) {
    font-size: 24px !important;
  }
`;

export const SingleArticle = ({ context: { state } }) => {
  useEffect(() => PageView(), []);
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  let slug = window.location.pathname.split("/");

  let title;
  let image;
  let photographer_name;
  let content;

  state.articles.forEach(article => {
    if (article.slug === slug[2]) {
      title = article.title.rendered;
      image = article.acf.image;
      photographer_name = article.acf.photographer_name;
      content = article.content.rendered;
    }
  });

  useEffect(() => {
    const stringToFind = "Our Certified Inspectors";
    const article = document.getElementById("article_content");
    // const foundEls = document.getElementsByClassName(classesToFind);


    [...article.children].map(item => {
      // ReactDOM.render(<OurCertifiedRoofersToolTip />, item)
      if (item.innerHTML.includes(stringToFind)) {
        const splitHTML = item.innerHTML.split(stringToFind);
        item.innerHTML = "";

        const firstSpan = document.createElement("span");
        firstSpan.innerText = splitHTML[0];

        const secondSpan = document.createElement("span");
        ReactDOM.render(<OurCertifiedRoofersToolTip classes={"text-blue"} />, secondSpan);

        const thirdSpan = document.createElement("span");
        thirdSpan.innerText = splitHTML[0];

        item.append(firstSpan, secondSpan, thirdSpan);

      }
      return item;
    })
  });

  return (
    <Fragment>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>{title}</title>
        <link rel="canonical" href={`https://www.hailscale.com/articles/${slug[2]}`} />
        <meta name="description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content={title} />
        <meta itemprop="description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta itemprop="image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.hailscale.com" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta property="og:image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta name="twitter:image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Meta Tags Generated via http://heymeta.com --> */}
      </Helmet>
      <Grid item container xs={12} className={`single-article ${isMedium ? "p-l-60 p-r-60" : "p-l-24 p-r-24"} m-t-75`}>
        <Grid item xs={12} className={`m-t-75 ${isMedium ? "m-b-35" : "m-b-18"}`}>
          <StyledTitle variant="h1" className="text-blue">Resource - {title}</StyledTitle>
        </Grid>
        <Grid item xs={12} md={5}>
          <StyledImg src={image} alt={title} />
          <StyledCredit>Photo Credit {photographer_name} on Unsplash</StyledCredit>
        </Grid>
        <Grid item xs={12} md={7} className={isMedium ? "p-l-40" : ""}>
          <StyledDiv id="article_content" dangerouslySetInnerHTML={{ __html: content }} />
        </Grid>
        <Grid item xs={12} direction={isMedium ? "row" : "column"} justifyContent={isMedium ? "center" : "flex-start"} alignItems={isMedium ? "flex-start" : "center"} container className={isMedium ? "m-t-60 m-b-60" : "m-t-45 m-b-45"}>
          <Button
            sx={isMedium ? { marginRight: "15px" } : { marginBottom: "15px" }}
            onClick={() => navigate(ROUTES.LANDING_PAGE)}
          >Check my Roof</Button>
          <Button
            variant="tan"
            sx={isMedium ? { marginLeft: "15px" } : { marginTop: "15px" }}
            onClick={() => navigate(ROUTES.FAQ)}
          >Back to FAQs</Button>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default withLocalContext(SingleArticle);