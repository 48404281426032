import React, { Fragment, useState } from "react";
import moment from 'moment';
import Typography from "@mui/material/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@mui/material/Button";
import Grid from "@material-ui/core/Grid";
import { useInspection } from "./Provider";
import { submitInspection } from "../../services";
import { addPhotoChunk } from "../../services";

export const SubmitForm = ({ showPropertyMgName }) => {
  const { state, resetForm } = useInspection();
  const { submittedBy, shingleType } = state;
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const [isValid, setIsValid] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => submittedBy && shingleType;

  const submitForm = async (e) => {
    const isValid = validateForm();
    if (isValid) {
      const payload = {
        formatDate: moment(state.inspectionDate).format("dddd, MMMM Do YYYY"),
        ...state,
      };

      const _id = state._id;
      const images = state.images;
      delete payload.isHSCLoading;
      delete payload._id;

      try {
        setIsSubmitting(true);
        if (images.length > 0) {
          let image_array = [];

          for (let a = 0; a < images.length; a++) {
            let image = images[a];
            let chunks = [];
            let timestamp = moment().format('YMDHHmmssSS');
            let name = image.name;

            let moreChunks = true;
            let count = 1;
            for (let i = 0; i < image.url.length; i += 100000) {
              let string = images[a].url.substr(i, 100000);
              let id = name + "-" + timestamp + "-" + String(a + 1) + "-" + String(count);
              chunks.push(id);

              if (Math.ceil(image.url.length / 100000) - count === 0) {
                moreChunks = false;
              }

              const chunkPayload = {
                _id: id,
                data: string,
                associatedAppId: _id,
                moreChunks: moreChunks
              };
              await addPhotoChunk(chunkPayload);
              count++;
            }
            image_array.push({ name: image.name, chunks: chunks });
            timestamp = "";
          }
          payload.images = image_array;
        }

        await submitInspection(_id, payload);
        setIsSubmitting(false);
        setisSuccess(true);

        // const size = new TextEncoder().encode(JSON.stringify(state)).length
        // const kiloBytes = size / 1024;
        // const megaBytes = kiloBytes / 1024;
      } catch (err) {
        console.log('we have an error', err)
        setHasError(true);
      }
    } else {
      setIsValid(false);
    }
  };

  let form_check = true;
  if (
    state.address
    && state.submittedBy
    && state.shingleType
    && state.dmgAmount
    && state.filingStatus
    && state.recommendFilingClaim
    && state.inspectionDate
    && state.insuranceProvider
    && state.roofPitch
    && state.accessHighestPoint
    && state.sidingType
    && (state.hailLocations.length > 0 || state.dmgAmount === "No damage")
    && state.images.length <= 5
  ) {
    form_check = false;
  }

  if (showPropertyMgName) {
    if (
      state.additionalComments.length >= 50
      && state.images.length >= 3
    ) {
      form_check = false;
    }
  }

  return (
    <Fragment>
      <>
        {!isSuccess && (
          <Grid item xs={12} md={3} className={isMedium ? "m-r-22" : ""}>
            <Button
              className={`${isSuccess ? "bg-light-blue" : ""}`}
              onClick={submitForm}
              disabled={form_check}
            >
              Submit
            </Button>

          </Grid>
        )}
        {!isValid && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" className="m-t-20 text-align-left text-red">
              Please fill out all fields. Thanks!
            </Typography>
          </Grid>
        )}
        {hasError && (
          <Typography variant="body2" className="m-b-8 text-red">
            There was an error submitting your Inspection please try again later.
            Apologize for the inconvience!
          </Typography>
        )}
      </>
      {isSuccess && (
        <>
          <Grid item xs={12} container>
            <div>

              <Button
                fullWidth
                onClick={submitForm}
              >
                Update Report
              </Button>
            </div>
            <div className="m-l-20">
              <Button
                fullWidth
                variant="brown"
                onClick={() => resetForm()}
              >
                Create New Report
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            {!isSubmitting &&
              <Typography style={{ maxWidth: "200px", marginLeft: "-32px" }} className="m-t-20 text-green text-font-fam-colfax-light">
                This form has been submitted!
              </Typography>
            }
          </Grid>
        </>
      )}
      {isSubmitting && <CircularProgress />}
    </Fragment>
  );
};

export default SubmitForm;
