function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() + " ";
    }
  );
}
const slug = window.location.pathname;
const splitSlug = slug.split("/");
let headerTitleUrl = { title: "", url: slug };
if (splitSlug.length >= 3) {
  headerTitleUrl.title = splitSlug[splitSlug.length - 1].split("-").map(str => { return toTitleCase(str) })
}

export const activeAppointments = {
  partnerNeeded: { count: 0, appointments: [] },
  fieldRepNeeded: { count: 0, appointments: [] },
  inspectionOverdue: { count: 0, appointments: [] },
  awaitingInspection: { count: 0, appointments: [] },
  homeownerToFile: { count: 0, appointments: [] },
  awaitingAdjsDate: { count: 0, appointments: [] },
  awaitingAdjsOutcome: { count: 0, appointments: [] },
  readyToBeClosed: { count: 0, appointments: [] },
  closedAppointments: { count: 0, appointments: [] },
  activeApps: 0
};

export const initialState = {
  headerTitleUrl: headerTitleUrl,
  activeAppointments: activeAppointments,
  partnerNames: [],
  areAppointmentsLoading: true,
  partners: []
};