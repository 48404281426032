import { Link } from "react-router-dom";
import styled from "styled-components";
import { classnames } from "./ButtonLinkClassnames";

export const StyledLink = styled(Link)`
  display: flex;
  text-align: center;
  width: fit-content;
  text-decoration: none;
  cursor: pointer;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  transition: 0.2s;
  &:hover {
    transform: scale(1.04);
  }

  ${(props) => (props.isdisabled === "false" && `
  pointer-events: none;
  color: #D9D9D9 !important;
  border-color: #D9D9D9 !important;
  `)}
`;

export const AdminLink = ({ text, href, classNames, onClick, isDisabled }) => {
  return <StyledLink isdisabled={String(isDisabled)} className={[...classnames, classNames]} onClick={onClick} to={href}><span>{text}</span></StyledLink>
}