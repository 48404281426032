import { extendTheme } from '@mui/joy/styles';
import { COLORS } from '../../constants/colors';

export const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          solidColor: "#ffffff",
          solidBg: undefined,
          solidHoverBg: undefined,
          solidActiveBg: undefined,
        }
      }
    }
  },
  fontFamily: {
    body: '"Colfax", var(--joy- fontFamily - fallback)',
    display: '"Colfax", var(--joy- fontFamily - fallback)',
  },
  typography: {
    h1: {
      fontSize: '26px !important',
    }
  },
  components: {
    JoyInput: {
      styleOverrides: {
        root: {
          height: "30px",
        }
      }
    },
    JoySelect: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          backgroundColor: COLORS.GREY,
          fontSize: 16,
          textAlign: "right"
        },
        listbox: {
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          border: `1px solid ${COLORS.LIGHT_BLUE}`,
          borderTop: "none"
        }
      },
    },
    JoyOption: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${COLORS.LIGHT_BLUE}`,
        },
      }
    }
  }
});