import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { useRef } from "react";
import styled from "styled-components";
import { COLORS } from "../../constants/colors";
import { MonitorProvider } from "./Provider";
import withLocalContext from "../store/withLocalContext";
import Storm_Black from "../../assets/Storm_Black.webp";
import Report_Black from "../../assets/Report_Black.webp";
import Form from "./MonitorForm";
import Share from "./Share";

const StyledH3 = styled(Typography)`
  font-size: 35px !important;
  @media (max-width: 960px) {
    font-size: 20px !important;
  }
`;

const StyledBlueBox = styled(Grid)`
  background-color: ${COLORS.LIGHT_BLUE};
  border-radius: 10px;
  text-align: center;
  padding: 30px;

  img {
    max-width: 142px;
    height: auto;
  }

  h3 {
    max-width: 230px;
  }

  p {
    font-size: 22px !important;
  }
  
  ${(props) => (props.first === 1 &&
    `
    padding: 30px 60px;
    img {
      max-width: 113px;
    }
    `
  )}

  @media (max-width: 960px) {
    padding: 20px;

    img {
      max-width: 100px;
    }

    p {
      font-size: 16px !important;
    }

    ${(props) => (props.first === 1 &&
    `
    img {
      max-width: 80px;
    }
    `
  )}
  }
`;

const StyleIntroContainer = styled(Grid)`
@media (max-width: 960px) {
  button {
    width: 100%;
  }
}
`;

const monitorPoints = [
  {
    icon: Storm_Black,
    title: "Real-time storm Alerts",
    point: "We will alert you within 24 hours of detecting a hail event that impacts your property (great for cabins and rental properties)."
  },
  {
    title: "Quarterly Hail History Reports",
    icon: Report_Black,
    point: "You will receive a hail history report each quarter detailing your up-to-date weather history."
  }
];

const Monitoring = ({ context: { state } }) => {

  const SignUpRef = useRef(null);
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  const goToSignUp = () => {
    window.scrollTo({
      top: SignUpRef.current.offsetTop - 100,
      behavior: "smooth"
    })
  };

  return (
    <Grid container className={isMedium ? "p-t-85 p-l-100 p-r-100" : "p-t-45 p-l-35 p-r-35"}>
      <Grid item xs={12}>
        <Typography variant="h2" className="text-blue m-b-47">What is Hail Monitoring</Typography>
      </Grid>
      <Grid container item xs={12} className="text-align-left">
        <Grid container item xs={12} md={4} className={`${isMedium ? "p-r-32" : "m-b-20"}`}>
          <StyleIntroContainer item xs={12}>
            <Typography variant="h3" className={`text-blue ${isMedium ? "m-b-50" : "m-b-20"}`}>Hailscale offers a free 24/7 monitoring service</Typography>
            <Typography variant="body2" className="m-b-35">
              Not every home is in need of an inspection or new roof just yet. However, we know it only takes one storm to change that. Hailscale wants to keep homeowners in the loop about the potential for damage with each passing hail event. Enroll your
              <span className="text-blue text-font-fam-colfax-medium"> home</span>,
              <span className="text-blue text-font-fam-colfax-medium"> cabin</span>, or
              <span className="text-blue text-font-fam-colfax-medium"> rental property </span>
              for free hail monitoring to stay up-to-date on severe weather impacting your property.
            </Typography>
            <Button
              onClick={goToSignUp}
            >Sign up for monitoring below</Button>
          </StyleIntroContainer>
        </Grid>
        <Grid item container xs={12} md={8}>
          {monitorPoints.map((point, i) => {
            return <Grid key={point.title} container item xs={12} md={6} className={`${isMedium ? "p-r-32" : "m-b-20"}`}>
              <StyledBlueBox first={i} container alignItems="flex-end" justifyContent="center" item xs={12}>
                <Grid item xs={12}>
                  <img src={point.icon} alt="" />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <Typography variant="h3">{point.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">{point.point}</Typography>
                </Grid>
              </StyledBlueBox>
            </Grid>
          })}
        </Grid>
      </Grid>
      <Grid container item xs={12} className={isMedium ? "m-t-150 m-b-100" : "m-t-50 m-b-60"}>
        <StyledH3 variant="h3" ref={SignUpRef} className={`text-blue text-align-left ${isMedium ? "m-b-55" : "m-b-15"}`}>Sign-up for Hail Monitoring</StyledH3>
        <MonitorProvider>
          <Form />
        </MonitorProvider>
      </Grid>
      <Grid item xs={12} className={!isMedium ? "m-b-30" : ""}>
        <Share />
      </Grid>
      <Grid item xs={12}>
        {/* <div className="elfsight-app-baa78a1d-cf00-4d97-b0f1-f893160bf34b" /> */}
      </Grid>
    </Grid>
  );
}

export default withLocalContext(Monitoring);