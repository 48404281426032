import { Grid } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import { PageView } from "../Tracking";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import track from "../../assets/Radar_Black.webp";
import rate from "../../assets/Rate_Black.webp";
import advise from "../../assets/Hands_Black.webp";
import Langley_NEXRAD from "../../assets/Langley_NEXRAD.webp";
import { COLORS } from "../../constants/colors";
import { NOAAToolTip } from "../Tooltips/Tooltips";
import { NWSToolTip } from "../Tooltips/Tooltips";
import { NEXRADToolTip } from "../Tooltips/Tooltips";
import { OurInspectorsToolTip } from "../Tooltips/Tooltips";
import { ROUTES } from "../../constants/routes";

const StyledBGContainer = styled(Grid)`
  padding: 0 15px;
  @media (max-width: 959px) {
    padding: 0;
    margin: 15px 0;
  }
`;

const StyledBackground = styled(Grid)`
  background-color: ${COLORS.LIGHT_BLUE};
  padding: 40px 50px;
  border-radius: 10px;
  @media (max-width: 600px) {
    padding: 40px;
  }
`;

const StyledImgContainer = styled(Grid)`
height: 75px;
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 35px;
  img {
    width: 75px;
  }

  @media (max-width: 600px) {
    margin-bottom: 17px;
  }
`;

const StyledTitle = styled(Typography)`
margin-bottom: 35px;
@media (max-width: 959px) {
font-size: 20px !important;
  margin-bottom: 17px;
}
`;

const StyledParagraph = styled(Typography)`
  color: ${COLORS.BLACK};
  text-align: left;
  line-height: 35px !important;
  font-family: Colfax Medium;
  @media (max-width: 959px) {
    font-family: Colfax Regular;
  }
`;

const StyledNEXRAD = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
`;

const StyledNEXRADPar = styled(Typography)`
  line-height: 35px !important;

  a {
    font-size: 16px;
    font-family: Colfax Medium;
  }
`;

const StyledNEXRADContainer = styled(Grid)`
padding: 0 15px;
padding-right: 9px;
@media (max-width: 959px) {
  display: none;
}
`;

export const HowItWorks = () => {
  useEffect(() => PageView(), []);
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Fragment>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>How Hailscale Works</title>
        <link rel="canonical" href={`https://www.hailscale.com${ROUTES.HOW_IT_WORKS}`} />
        <meta name="description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="How Hailscale Works" />
        <meta itemprop="description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta itemprop="image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.hailscale.com/how-it-works" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="How Hailscale Works" />
        <meta property="og:description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta property="og:image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="How Hailscale Works" />
        <meta name="twitter:description" content="We track every hail storm the area, rate your roof damage using weather data, and advise you on the best course of action." />
        <meta name="twitter:image" content="https://www.hailscale.com/hsc_logo.webp" />

        {/* <!-- Meta Tags Generated via http://heymeta.com --> */}
      </Helmet>
      <Grid container className={isMedium ? "p-t-50 p-r-45 p-l-45" : "p-t-45 p-r-25 p-l-25"}>
        <Grid item xs={12} className={isMedium ? "m-b-40" : "m-b-10"}>
          <Typography variant="h2" className="text-blue">How Hailscale Works</Typography>
        </Grid>
        <Grid item container xs={12} className="text-black">
          <StyledBGContainer item container xs={12} md={4}>
            <StyledBackground item container xs={12} alignContent="flex-start">
              <StyledImgContainer item container xs={12}>
                <img src={track} alt="track" />
              </StyledImgContainer>
              <Grid item xs={12}>
                <StyledTitle variant="h5">TRACK</StyledTitle>
              </Grid>
              <Grid item xs={12}>
                <StyledParagraph variant="body2">
                  It starts with <NEXRADToolTip classes={"text-black"} /> Radar–a network of 160 high-resolution doppler radar towers brought fully online in 2008. The NEXRAD system is operated by the National Weather Service (<NWSToolTip classes={"text-black"} />) and National Oceanic and Atmospheric Administration (<NOAAToolTip classes={"text-black"} />) and covers nearly the entire United States. NEXRAD detects all types of weather activity with exceptional precision and accuracy, from light showers to severe thunderstorms, tornadoes, and hail.
                </StyledParagraph>
              </Grid>
            </StyledBackground>
          </StyledBGContainer>
          <StyledBGContainer item container xs={12} md={4}>
            <StyledBackground item container xs={12} alignContent="flex-start">
              <StyledImgContainer item xs={12}>
                <img src={rate} alt="rate" />
              </StyledImgContainer>
              <Grid item xs={12}>
                <StyledTitle variant="h5">RATE</StyledTitle>
              </Grid>
              <Grid item xs={12}>
                <StyledParagraph variant="body2">
                  Hailscale works with key data partners like Dynamic Weather Solutions to mine and review all severe weather activity from the <NWSToolTip classes={"text-black"} /> archives in the areas we serve. After entering your address and the age of your roof, we cross-reference your geolocation with all corresponding data contained in the <NEXRADToolTip classes={"text-black"} /> archives.
                </StyledParagraph>
              </Grid>
            </StyledBackground>
          </StyledBGContainer>
          <StyledBGContainer item container xs={12} md={4}>
            <StyledBackground item container xs={12}>
              <StyledImgContainer item xs={12}>
                <img src={advise} alt="advise" />
              </StyledImgContainer>
              <Grid item xs={12}>
                <StyledTitle variant="h5">ADVISE</StyledTitle>
              </Grid>
              <Grid item xs={12}>
                <StyledParagraph variant="body2">
                  After a property owner provides the age of their roof, Hailscale uses our proprietary algorithm to analyze all weather activity within the lifespan of the roof. This assessment takes into account hail size and duration, as well as storm event data such as speed and direction of approach. Additionally, Hailscale uses a patented weather statistic known as the Derived Hail Index to measure storm intensity and further refine our individual ratings. DHI is a proprietary measure developed by Dynamic Weather Solutions.
                </StyledParagraph>
              </Grid>
            </StyledBackground>
          </StyledBGContainer>
        </Grid>
        <Grid item container xs={12} style={isMedium ? { paddingRight: 15, margin: "85px 0" } : { padding: "0 30px", margin: "55px 0" }}>
          <StyledNEXRADContainer item container xs={4}>
            <StyledNEXRAD src={Langley_NEXRAD} alt="NEXRAD Image" />
          </StyledNEXRADContainer>
          <Grid item xs={12} md={8} className={`text-align-left ${isMedium ? "p-l-60" : ""}`}>
            <Typography variant="h3" className={`text-blue ${isMedium ? "m-b-30" : "m-b-20"}`}>How does the rating scale work?</Typography>
            <StyledNEXRADPar variant="body2">
              Once our servers have crunched the numbers, we present your roof's entire weather history in an easy to read report. This report includes a couple of key statistics–most notably, the Hailscale rating. This rating informs you whether or not it's time to take action on your roof. A rating above 7.1 indicates that Hailscale believes a roof inspection is warranted.
            </StyledNEXRADPar>
            <Typography variant="h3" className={`text-blue ${isMedium ? "m-b-30 m-t-40" : "m-b-20 m-t-40"}`}>What is the process?</Typography>
            <StyledNEXRADPar variant="body2">
              Hailscale works with top-notch roof inspectors in your neighborhood to offer free roof inspections to any homeowner with a rating at or above 7.1. You can learn more about <OurInspectorsToolTip classes={"text-blue"} />.
            </StyledNEXRADPar>
            <StyledNEXRADPar variant="body2" className="m-t-20">
              If your Hailscale rating is between 7.1 and 3.5, we advise homeowners to continue monitoring their roofs. The best way to monitor your roof is to allow Hailscale to do it for you. Simply sign up for our free monitoring service and adjust your communication preferences. Hailscale will never send you junk mail or sell your data.
            </StyledNEXRADPar>
          </Grid>
        </Grid>
      </Grid >
    </Fragment>
  );
}

export default HowItWorks;