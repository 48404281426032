import dayjs from "dayjs";

export const findFirstAvailableDate = (date) => {
  let tempDate = date;
  let isAvailable = false;
  while (!isAvailable) {
    if (tempDate.day() === 0 ||
      (tempDate.format("MM/DD/YYYY") === dayjs().format("MM/DD/YYYY")) ||
      (tempDate.format("MM/DD/YYYY") === dayjs().add(1, "days").format("MM/DD/YYYY"))) {
      tempDate = dayjs(tempDate).add(1, "d");
    } else {
      isAvailable = true;
    }
  }
  return tempDate;
}