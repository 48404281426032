import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useInspection } from "../Provider";
import { InputLabel, Box } from '@mui/material';
import { StyledLabel, StyledFormControl, StyledSelect, StyledMenuItem } from "../../Common/StyledElements";
import styled from "styled-components";

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
 color: black !important;
 font-size: 35px;
 @media (max-width: 959px) {
  font-size: 25px;
 }
`;

export const SubmittedBy = ({ fieldReps }) => {
  const { state, addContractor } = useInspection();
  return (
    <Box>
      <StyledLabel>
        Sign and submit (field rep name)<span className="text-red">*</span>
      </StyledLabel>
      <StyledFormControl fullWidth>
        <InputLabel id={`simple-select-submitted-by`}>contractor...</InputLabel>
        <StyledSelect
          labelId="simple-select-submitted-by"
          id="submitted-by-select"
          IconComponent={StyledExpandMoreIcon}
          label={"contractor..."}
          value={state.submittedBy}
          onChange={(val) => addContractor(val.target.value)}
        >
          {fieldReps.map((opt, i) => (
            <StyledMenuItem
              style={
                {
                  border: `1px solid`,
                  borderBottom: i === fieldReps.length - 1 ? '1px solid' : 'none',
                  borderBottomRightRadius: i === fieldReps.length - 1 ? 10 : 0,
                  borderBottomLeftRadius: i === fieldReps.length - 1 ? 10 : 0,
                }
              }
              value={opt}
              key={opt}>
              {opt}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </Box>
  );
};
