import styled from "styled-components";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { getPartners } from "../../../../services";
import CardWrapper from "../Card/CardWrapper";
import withAdminContext from "../../store/withAdminContext";
import { addPartners, changeHeaderTitleUrl } from "../../store/actions";
import { AdminLink } from "../Common/AdminLink";

const StyledPartnerCon = styled(Grid2)`
  min-height: 100px;
`;
const PartnerProfileList = ({ context: { state, dispatch } }) => {

  useEffect(() => {
    loadPartners();
    // eslint-disable-next-line
  }, [])

  const loadPartners = async () => {
    let partnerNamesRes = await getPartners({ onlyNames: false });
    dispatch(addPartners(partnerNamesRes.data));
  }

  return (
    <Grid2>
      {state.partners.map(partner => (
        <Grid2 xs={10} key={partner._id}>
          <StyledPartnerCon xs={12} container justifyContent="space-between">
            <CardWrapper
              child={
                <Grid2 xs={12} sx={{ minHeight: "150px", width: "450px" }} container alignContent="center" justifyContent="space-between">
                  <Typography variant="h3" className="text-align-left">{partner.tradeName}</Typography>
                  <AdminLink
                    text="View profile"
                    onClick={() => dispatch(changeHeaderTitleUrl({ title: partner.tradeName, url: `${window.location.pathname}/${partner._id}` }))}
                    href={`/admin/partner-profiles/${partner._id}`}
                  />
                </Grid2>
              }
            />
          </StyledPartnerCon>
        </Grid2>
      ))}
    </Grid2>
  );
}

export default withAdminContext(PartnerProfileList);