// import report from "../ReportPage/report";

// const localRatingParams = {
//   "roofAge": 6,
//   "address": {
//     "address": "2324 Hopkins Crossroad, Hopkins, MN 55305, USA",
//     "zipCode": "55305",
//     "shortAddress": "2324 Hopkins Crossroad",
//     "place_id": "ChIJO-ypjadKs1IRkJ7cjQyN-OE",
//     "geoLocation": {
//       "lat": 44.9595691,
//       "lng": -93.4213367
//     }
//   }
// };
const checkURL = () => {
  if (window.location.search) {
    console.log(window.location.search);
    //?address=2808+terrace%20dr.+Burnsville+MN+55344
  }

}
export const initialState = {
  zipcodes: [],
  articles: [],
  deviceData: {},
  report: {},
  url: checkURL(),
  // report: window.location.href.includes("localhost") ? report : {},
  // ratingParams: window.location.href.includes("localhost") ? localRatingParams : { roofAge: "" },
  ratingParams: { roofAge: "" },
  loggedIn: null,
  appInspections: [],
  fieldReps: [],
};
