// This can be used as a checkbox or radio group.
// If using as a checkbox set your array of options object to include 
// [{ value: "", description: "" }]
// If using as a radio group be sure to add singleSelect when calling the component
// and set your array of options object to include  
// [{ value: "", description: "", groupName: "" }]

import React from "react";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import check from "../../assets/check-solid.svg";
import { StyledLabel } from "./StyledElements";
import { COLORS } from "../../constants/colors";

const StyledContainer = styled(Grid)`
  border: 1px solid ${COLORS.BLUE};
  border-radius: 10px;
  max-height: ${(props) => (props.numofoptions * 64)}px;
  div {
    height: 63px;
    input[type=checkbox] {
      display: none;
    }
    label {
      font-size: 24px;
      padding-left: 50px;
      margin-bottom: -10px;
      @media (max-width: 960px) {
        font-size: 16px; 
      }
    }
  }

  .customCheckbox {
    z-index: -1;
    position: absolute;
    height: 23px;
    width: 23px;
    border: 1px solid ${COLORS.BLUE};
    border-radius: 2px;
  }

  input:checked ~ .customCheckbox {
    background-image: url(${check});
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
  }
`;

export const CustomCheckbox = ({ setCheckBox, options, singleSelect, title }) => {

  const setOptions = (data) => {
    if (singleSelect) {
      let els = document.getElementsByName(options[0].groupName);
      for (const el of els) {
        if (data !== el.id) {
          el.checked = false;
        }
      }
      setCheckBox(data)
    } else {
      setCheckBox(data)
    }
  }

  return (
    <Grid item container xs={12} className="m-t-24">
      <Grid item container xs={12}>
        <StyledLabel>{title}<span className="text-red">*</span></StyledLabel>
      </Grid>
      <StyledContainer item container xs={12} numofoptions={options.length}>
        {options.map((option, i) => (
          <Grid item container xs={12} alignContent="center" key={option.value} className={`p-a-22 ${options.length - 1 === i ? "" : "border-bottom-blue"}`} >
            <input onClick={(event) => setOptions(event.target.id)} type={"checkbox"} id={option.value} name={option.groupName} value={option.value} />
            <span className="customCheckbox"></span>
            <label htmlFor={option.value}>{option.description}</label><br />
          </Grid>
        ))
        }
      </StyledContainer >
    </Grid >
  );
}

export default CustomCheckbox;