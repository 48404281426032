import styled from "styled-components";
import { AdminInput } from "./AdminInput";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;
export const AdminInputAndTitle = ({ classNames, value, onChange, type, isDisabled, title }) => {
  return (
    <StyledDiv>
      {title && <span className="font-size-16 text-font-fam-colfax-medium uppercase m-r-3">{title}:</span>}
      <AdminInput
        classNames={classNames}
        value={value}
        onChange={onChange}
        type={type}
        isDisabled={isDisabled}
      />
    </StyledDiv>)
};