import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { getReport } from "../../store/selectors";
import withLocalContext from "../../store/withLocalContext";
import { useTheme, useMediaQuery } from "@material-ui/core";
import nextStepsBG from "../../../assets/nextStepsBG.webp";
import nextStepsBGMobile from "../../../assets/nextStepsBGMobile.webp";
import Inspect from "./Inspect";
import Monitor from "./Monitor";

export const NextSteps = ({ context: { state } }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  const { hailscaleRating } = getReport(state);
  const [inspect, setInspect] = useState();

  useEffect(() => {
    setInspect(hailscaleRating > 7.0);
  }, [setInspect, hailscaleRating]);

  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        backgroundImage: `url(${isMedium ? nextStepsBG : nextStepsBGMobile})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "right",
        height: "auto"
      }}
    >
      {inspect ? <Inspect /> : <Monitor />}
    </Grid>
  );
};

export default withLocalContext(NextSteps);