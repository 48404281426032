import BBB from "../assets/our_inspectors/BBB.webp";
import angi from "../assets/our_inspectors/angi.webp";
import google from "../assets/our_inspectors/google.webp";
import home from "../assets/our_inspectors/home.webp";
import map from "../assets/our_inspectors/map.webp";
import GAF from "../assets/our_inspectors/GAF.webp";
import OC from "../assets/our_inspectors/OC.webp";
import MNRC from "../assets/our_inspectors/MNRC.webp";
import Trinity from "../assets/our_inspectors/Trinity.webp";
import Results from "../assets/our_inspectors/Results.webp";
import Boelter from "../assets/our_inspectors/Boelter.webp";
import build from "../assets/our_inspectors/build.webp";
import boelterHero from "../assets/our_roofers/boelterHero.webp";
import boelterLogo from "../assets/our_roofers/boelterLogo.webp";
import trinityHero from "../assets/our_roofers/trinityHero.webp";
import trinityLogo from "../assets/our_roofers/trinityLogo.webp";
import resultsHero from "../assets/our_roofers/resultsHero.webp";
import resultsLogo from "../assets/our_roofers/resultsLogo.webp";
import { ROUTES } from "./routes";
import { OurCertifiedRoofersToolTip } from "../components/Tooltips/Tooltips";

export const OUR_INSPECTORS = [
  {
    image: MNRC,
    title: "MNRC",
    paragraph: "Founded in 2010 in Burnsville, MNRC quickly established themselves in the market, earning the certification of Master Elite from GAF, the leading roofing manufacturer in North America. In recognition of their continued commitment to performance, reliability, and service, GAF awarded MNRC the Triple Excellence Award in 2021. The Better Business Bureau rates MNRC at an A+ for customer satisfaction and business ethics.",
    review: {
      elfsight: {
        script: "https://apps.elfsight.com/p/platform.js",
        html: "elfsight-app-6f5fbb4a-690f-4191-a3c3-5314851c32e5"
      },
      reviewRating: 4.9,
      numberOfReviews: 337
    },
    qualities: [
      {
        icon: map,
        description: "Restoring since 2010"
      },
      {
        icon: GAF,
        description: "Master Elite Certified"
      },
      {
        icon: google,
        description: "reviews"
      },
      {
        icon: home,
        description: "Burnsville is Home"
      },
    ]
  },
  {
    image: Trinity,
    title: "Trinity Exteriors",
    name: "Trinity",
    paragraph: "With a home base in Eden Prairie, Trinity Exteriors has been serving the Twin Cities since 2007. Guided by service, quality, and integrity, Trinity has earned their reputation with thousands of satisfied clients. Trinity boasts the Platinum Preferred designation from Owens Corning, an A+ with the BBB, and a robust charitable network, including the Minnesota Military Appreciation Fund.",
    review: {
      elfsight: {
        script: "https://apps.elfsight.com/p/platform.js",
        html: "elfsight-app-c357979a-b23c-4af2-b69d-4ef521b5d5c5",
      },
      reviewRating: 4.8,
      numberOfReviews: 271
    },
    qualities: [
      {
        icon: map,
        description: "Restoring since 2007"
      },
      {
        icon: OC,
        description: "Platinum Certified"
      },
      {
        icon: google,
        description: "reviews"
      },
      {
        icon: home,
        description: "Eden Prairie is Home"
      },
    ],
    cutout: trinityHero,
    logo: trinityLogo,
    link: ROUTES.OUR_INSPECTORS
  },
  {
    image: Results,
    title: "Results Contracting",
    name: "Results",
    paragraph: "Specializing in storm restoration for 15 years, Results Contracting in Coon Rapids is an EPA Certified Renovator. Results proudly holds the Platinum Preferred designation from the premier shingle manufacturer, Owens Corning. Not only is Results committed to integrity and craftsmanship, they also sponsor local youth sports teams and military charities protecting veterans. The BBB rates Results at an A+ for customer satisfaction and business ethics.",
    review: {
      elfsight: {
        script: "https://apps.elfsight.com/p/platform.js",
        html: "elfsight-app-6fdc2495-21e1-4002-b726-bb1c87e0b3b0"
      },
      reviewRating: 4.6,
      numberOfReviews: 92
    },
    qualities: [
      {
        icon: map,
        description: "Restoring since 2009"
      },
      {
        icon: OC,
        description: "Platinum Certified"
      },
      {
        icon: google,
        description: "reviews"
      },
      {
        icon: home,
        description: "Coon Rapids is Home"
      },
    ],
    cutout: resultsHero,
    logo: resultsLogo,
    link: ROUTES.OUR_INSPECTORS
  },
  {
    image: Boelter,
    title: "Boelter Window, Siding & Roofing",
    name: "Boelter",
    paragraph: "Practically a household name in Southern Minnesota, Boelter Siding, Windows, & Roofing has been doing excellent work for three decades. Owens Corning recognized Boelter’s commitment to quality and service by awarding them Preferred Contractor status. Whether it is residential, commercial, or agricultural, Boelter is the go-to expert for restoration projects from Mankato to Rochester, and everywhere between.",
    review: {
      elfsight: {
        script: "https://apps.elfsight.com/p/platform.js",
        html: "elfsight-app-86f8fde3-311a-42c5-a4ca-937a2b196c63"
      },
      reviewRating: 4.5,
      numberOfReviews: 33
    },
    qualities: [
      {
        icon: map,
        description: "Restoring since 1990"
      },
      {
        icon: OC,
        description: "Platinum Certified"
      },
      {
        icon: angi,
        description: "reviews"
      },
      {
        icon: home,
        description: "Mankato is Home"
      },
    ],
    cutout: boelterHero,
    logo: boelterLogo,
    link: ROUTES.OUR_INSPECTORS
  }
];

export const INSPECTOR_CHECKBOXES = [
  "Twin Cities is Home",
  "Insurance Restoration Specialists",
  "Manufacturer Certified",
  "A+ BBB Rating"
];

export const INTEGRITY = [
  {
    icon: home,
    title: "Twin Cities is Home",
    paragraph: ["No storm chasers allowed. ", <OurCertifiedRoofersToolTip />, " are required to live, work, and play in the Twin Cities. In most cases, ", <OurCertifiedRoofersToolTip />, " have been dedicated to community support programs and give-back campaigns for over 10 years!"]
  },
  {
    icon: map,
    title: "Insurance Restoration Specialists",
    paragraph: ["Weather damage is easily missed. Insurance policies are easily misread. It takes a specialist to accurately detect damage and advise property owners. ", <OurCertifiedRoofersToolTip />, " specialize in insurance claims management and execution of restoration repair."]
  },
  {
    icon: build,
    title: "Manufacturer Certified",
    paragraph: ["Advance roofing systems require careful installation by elite technicians. ", <OurCertifiedRoofersToolTip />, " are licensed general contractors who are required to maintain the highest level certifications with the industry's top manufacturers: GAF and Owens Corning."]
  },
  {
    icon: BBB,
    title: "5 Year Record  & A+ Rating",
    paragraph: ["Reputation is everything. ", <OurCertifiedRoofersToolTip />, " must have a verifiable track record of 5+ years restoring roofs, windows, and siding in the Twin Cities. They are also required to earn and maintain an A+ rating with the Better Business Bureau."]
  },
];