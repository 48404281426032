import React from "react";
import { Popover } from "../Common/Popover";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import dayjs from "dayjs";

export const NOAAToolTip = ({ classes }) => {
  return (
    <Popover
      btnText={"NOAA"}
      bodyText={"The National Oceanic and Atmospheric Administration is a division of the U.S. Department of Commerce. NOAA’s database tracks and details severe weather events."}
      classes={classes}
      position={"top"}
    />
  );
}

export const NEXRADToolTip = ({ classes }) => {
  return (
    <Popover
      btnText={"NEXRAD"}
      bodyText={"Next Generation Weather Radar are Doppler radars that detect and produce over 100 different long-range and high-altitude observations, including precipitation, winds, and thunderstorms."}
      classes={classes}
      position={"top"}
    />
  );
}
export const NWSToolTip = ({ classes }) => {
  return (
    <Popover
      btnText={"NWS"}
      bodyText={"The National Weather Service collates and processes vast amounts of global weather data. Hailscale integrates this data to provide up-to-the-minute information for property owners."}
      classes={classes}
      position={"top"}
    />
  );
}

export const OurInspectorsToolTip = ({ classes }) => {
  return (
    <Popover
      btnText={"Our Certified Roofers"}
      bodyText={<span>Hailscale’s Roofers are well-trained insurance restoration specialists. They are local, maintain A+ BBB ratings, and carry the highest manufacturer certifications. <a className='text-blue font-size-16' href='/our-inspectors'>learn more...</a></span>}
      classes={classes}
      position={"top"}
    />
  );
}

export const OurCertifiedRoofersToolTip = ({ classes, isSingular }) => {
  return (
    <Popover
      btnText={isSingular ? "A Certified Roofer" : "Our Certified Roofers"}
      bodyText={<span>Hailscale partners with local General Contractors to perform roof inspections. <a className='text-blue font-size-16' href='/our-inspectors'>Learn more about our roofers</a></span>}
      classes={classes}
      position={"top"}
    />
  );
}

export const FreeAppointmentToolTip = ({ classes }) => {
  return (
    <Popover
      btnText={"free"}
      bodyText={<span>Hailscale inspection are always free, wheather we find damage or not. We will never ask for a credit card.
      </span>}
      classes={classes}
      position={"top"}
    />
  );
}


export const HighRiskDamageToolTip = ({ classes }) => {
  return (
    <Popover
      btnText={<InfoOutlinedIcon />}
      bodyText={
        <span>
          Ratings of 7.0 and above are considered high risk. Hailscale recommends an inspection after any hail event for properties scoring in the high risk range.
        </span>
      }
      classes={classes}
      position={"top"}
    />
  );
}
export const VerifiedHailDateLocationToolTip = ({ classes, stats }) => {

  return (
    <Popover
      btnText={<InfoOutlinedIcon />}
      bodyText={
        <span>
          According to the National Weather Service, NEXRAD Radar verified hail as large as {stats.hailSize}” on {dayjs(stats.localStormDate).format("MMMM DD, YYYY")}. An inspection is recommended to verify if this hail event has impacted the property.
        </span>
      }
      classes={classes}
      position={"top"}
    />
  );
}

export const InsuranceClaimEligibleToolTip = ({ classes }) => {
  return (
    <Popover
      btnText={<InfoOutlinedIcon />}
      bodyText={
        <span>
          The verified hail date falls within the 24 month claim window established by the state of Minnesota for filing weather related property damage claims. An inspection is recommended to determine if any hail damage warrants an insurance claim.
        </span>
      }
      classes={classes}
      position={"top"}
    />
  );
}

export const FreeInspectionToolTip = ({ classes }) => {
  return (
    <Popover
      btnText={<InfoOutlinedIcon />}
      bodyText={
        <span>
          Your property qualifies for Hailscale’s free inspection service because of its high risk rating. Property owners are never obligated to file an insurance claim or hire our Certified Roofers.
        </span>
      }
      classes={classes}
      position={"top"}
    />
  );
}