import React from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import withLocalContext from "../../store/withLocalContext";
import { getReport } from "../../store/selectors";
import NexRadImage from "../../NexRadImage";


const StyledParagraph = styled(Typography)`
text-align: left;
margin-top: 10px;
@media (max-width: 960px) {
  font-size: 12px !important;
}
`;

const StyledNexRad = styled(NexRadImage)`
  border-radius: 0px !important;
`;

const StyledSpan = styled.span`
  font-family: Colfax Bold;
`;

export const MapAddress = ({ context: { state } }) => {
  const { address, roofAge } = getReport(state);
  const year = roofAge === 1 ? 'year' : 'years';
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid
      item
      className={isMedium ? "p-r-55" : "p-r-30 p-l-30 p-t-20"}
      xs={12}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} style={{ position: 'relative' }}>
        <StyledNexRad geoLocation={state.report.geoLocation} />
      </Grid>
      <Grid item xs={12} style={{ marginTop: isMedium ? 20 : 10 }}>
        <StyledParagraph variant="body1">
          <StyledSpan>Address: </StyledSpan>{address}
        </StyledParagraph>
        <StyledParagraph variant="body1">
          <StyledSpan>Age of Roof: </StyledSpan>{roofAge} {year}
        </StyledParagraph>
      </Grid>
    </Grid>
  );
};

export default withLocalContext(MapAddress);
