import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

export const Reports = () => {

  return (
    <Grid2 style={{ paddingTop: 100 }} xs={9} container>
      Reports
    </Grid2>
  );
}

export default Reports;