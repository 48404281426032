import React from "react";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Grid, Paper, useMediaQuery, useTheme, IconButton, Modal } from "@mui/material";
import styled from "styled-components";
import { COLORS } from "../../constants/colors";

const StyledPaper = styled(Paper)`
  border-radius: 10px !important;
  outline: none;
  width: ${(props) => (props.ismedium === "true" ? props.modalwidth ? props.modalwidth : "600px" : props.modalwidth ? props.modalwidth : "100%")};
  margin: ${(props) => (props.ismedium === "true" ? "0 80px" : "0px")};
  overflow: scroll;
  max-height: calc(100vh - 100px);
  background-color: ${COLORS.GREY} !important;
  `;

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000 !important;
  background-color: #0E5CBFB0;
`;

const StyledIconBtn = styled(IconButton)`
  color: black; 
  margin-right: -24px; 
  margin-top: -24px;
  z-index: 1;
`;

const StyledSVG = styled(CloseRoundedIcon)`
  font-size: 50px !important;
  color: ${COLORS.BLUE};
`;

export const HailScaleModal = ({ isOpen, onClose, children, modalWidth }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="p-l-12 p-r-12"
    >
      <StyledPaper
        ismedium={isMedium.toString()}
        modalwidth={modalWidth}
        elevation={4}
        className="p-l-24 p-r-24 p-b-32 p-t-24 bg-light-grey"
      >
        <Grid container item justifyContent="space-between" alignItems="center">
          <Grid item xs={12} style={{ direction: "rtl" }}>
            <StyledIconBtn
              aria-label="menu"
              onClick={onClose}
            >
              <StyledSVG />
            </StyledIconBtn>
          </Grid>
        </Grid>
        {children}
      </StyledPaper>
    </StyledModal >
  );
};

export default HailScaleModal;
