import React, { Fragment, useState } from "react";
import Typography from "@mui/material/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid as Gridsx, Button } from '@mui/material';
import { useAppointment } from "./Provider";
import { submitAppointment, postError } from "../../services";
import { ROUTES } from "../../constants/routes";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export const SubmitForm = ({ report }) => {
  const {
    address,
    roofAge,
    zipCode,
    geoLocation,
    hailscaleRating,
    cumulateHailDurationOverRoofAge,
    cumulativeHailCountOverRoofAge,
    largestHailEvent,
    hailstrikeData: { at_point }
  } = report;
  const { state } = useAppointment();
  const {
    fullName,
    email,
    phoneNumber,
    insurance,
    shingleType,
    timeSlot,
    apptDate,
  } = state;

  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();


  const validateForm = () =>
    fullName &&
    email &&
    phoneNumber &&
    insurance &&
    shingleType &&
    timeSlot &&
    apptDate;

  const submitForm = async (e) => {
    const isValid = validateForm();
    if (isValid) {
      // most recent hail storm: globalState.report.hailstrikeData.at_point[0]
      const payload = {
        ...state,
        phoneNumber: state.phoneNumber.replace(/[(]|[)]|-/g, ""),
        mostRecentStorm: at_point[0],
        mostSignificantStorm: largestHailEvent,
        address,
        roofAge,
        geoLocation,
        hailscaleRating,
        cumulateHailDurationOverRoofAge,
        cumulativeHailCountOverRoofAge,
        zipCode,
        formatDate: moment(state.apptDate).format("dddd, MMMM Do YYYY")
      };
      try {
        setIsLoading(true);
        await submitAppointment({ appointments: [payload] });
        navigate({
          pathname: ROUTES.SCHEDULE_SUCCESS,
          state: { appt: payload },
        });
      } catch (err) {
        setIsLoading(false);
        setHasError(true);
        postError({ error: err, page: window.location.href, date: new Date() });
      }
    } else {
      setIsValid(false);
    }
  };

  return (
    <Fragment>
      <Gridsx
        container
        item
        xs={12}
        md={3}
        alignItems="center"
        justifyContent="space-around"
        sx={{
          minWidth: "fit-content",
          gap: "6px"
        }}
      >
        <Button
          className="min-width-100"
          onClick={submitForm}
          disabled={!state.insurance || !state.shingleType || !state.timeSlot || !state.apptDate || !state.phoneNumber || !state.email || !state.fullName}
        >
          Submit
        </Button>
        {isLoading && <CircularProgress />}
      </Gridsx>
      {!isValid && (
        <Gridsx item xs={12}>
          <Typography variant="subtitle2" className="m-t-8 m-b-8 text-red">
            Please fill out all fields. Thanks!
          </Typography>
        </Gridsx>
      )}
      {hasError && (
        <Typography variant="body2" className="m-b-8 text-red">
          There was an error submitting your appointment please try again later.
          Apologize for the inconvience!
        </Typography>
      )}
    </Fragment>
  );
};

export default SubmitForm;
