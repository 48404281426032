import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import roof from "../../assets/HSC_Full_hero_video.mp4";
import poster from "../../assets/hero_poster.webp";
import Gradient from "../BlueGradient";
import { ELEMENT_SIZES } from "../../constants/elementSizes";

const useStyles = makeStyles((theme) => ({
  video: {
    [theme.breakpoints.up("xs")]: {
      bottom: "0 !important",
    },
    [theme.breakpoints.up("sm")]: {
      bottom: "-20% !important",
    },
    [theme.breakpoints.up("md")]: {
      bottom: "-40% !important",
    },
    [theme.breakpoints.up("lg")]: {
      bottom: "-60% !important",
    },
  },
  wrapper: {
    height: `calc(70vh - ${ELEMENT_SIZES.headerHeight.lg}px) !important`,
  },
}));

const BGVid = styled.video`
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  z-index: -1;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  object-postition: top;
`;

const BGImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  z-index: -1;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  object-postition: top;
`;

const Content = styled(Grid)`
  position: relative;
  @media (max-width: 600px) {
    margin-top: 80px;
  }
`;

export const MainPageBG = (props) => {
  const classes = useStyles();

  return (
    <Gradient className={`${classes.wrapper}`} container alignContent='center'>
      <BGImg src={poster} alt="fallback image for hero" />
      <BGVid className={classes.video} style={{ pointerEvents: "none" }} autoPlay muted loop playsInline>
        <source src={roof} type={'video/mp4'} />
        <source src={roof} type={'video/ogg'} />
      </BGVid>
      <Content item container justifyContent="center">{props.children}</Content>
    </Gradient>
  );
};

export default MainPageBG;
