import { createTheme } from "@mui/material";
import { createTheme as oldCreateTheme } from "@material-ui/core";
import { COLORS } from "./constants/colors";

const defaultTheme = createTheme();
const oldDefaultTheme = oldCreateTheme();

export const theme = createTheme({
  palette: {
    tan: {
      main: COLORS.TAN
    }
  },
  typography: {
    h1: {
      fontSize: 45,
      fontFamily: "Colfax Bold",
    },
    h2: {
      fontFamily: "Colfax Bold",
      fontSize: 20,
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: 35,
      },
    },
    h3: {
      fontFamily: "Colfax Bold",
      fontSize: 20,
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: 30,
      },
    },
    h4: {
      fontSize: 24,
      fontFamily: "Colfax Bold",
    },
    h5: {
      fontFamily: "Colfax Medium",
      fontSize: 16,
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: 20,
      },
    },
    h6: {
      fontSize: 16,
      fontFamily: "Colfax Medium",
    },
    body1: {
      fontFamily: "Colfax Regular",
      fontSize: 15,
      lineHeight: "19px",
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: 16,
      },
    },
    body2: {
      [defaultTheme.breakpoints.up("xs")]: {
        fontFamily: "Colfax Regular",
        fontSize: 16,
        lineHeight: "30px",
      },
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: "30px",
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: 20,
      },
    },
    subtitle2: {
      [defaultTheme.breakpoints.up("xs")]: {
        fontSize: 16,
        fontFamily: "Colfax Bold",
        lineHeight: "16px",
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: `10px !important`,
          backgroundColor: `${COLORS.BLUE} !important`,
          color: `${COLORS.WHITE} !important`,
          padding: `0 14px !important`,
          height: 45,
          fontSize: 16,
          textTransform: "capitalize",
          fontFamily: "Colfax Medium !important",
          [defaultTheme.breakpoints.up("md")]: {
            fontSize: 20,
            height: 75,
          },
          "&:hover": {
            filter: "brightness(75%)",
            backgroundColor: COLORS.BLUE,
          },
          "&:disabled": {
            pointerEvents: "none",
            opacity: 0.7
          },
        },
        outlined: ({ ownerState }) => {
          let color = COLORS.BLUE;
          Object.keys(COLORS).map(item => {
            if (item.toLowerCase() === ownerState.color) {
              color = COLORS[item];
            }
            return item;
          });
          return {
            backgroundColor: 'transparent !important',
            border: `2px solid ${color} !important`,
            color: `${COLORS.BLACK} !important`,
            "&:hover": {
              backgroundColor: "transparent !important",
              border: `2px solid ${color} !important`,
              transform: "scale(1.03)"
            }
          };
        }
      },
      variants: [
        {
          props: { variant: "tan" },
          style: {
            backgroundColor: `${COLORS.TAN} !important`,
            color: `${COLORS.BLACK} !important`,
            "&:hover": {
              backgroundColor: `${COLORS.TAN} !important`,
            },
          }
        },
        {
          props: { variant: "green" },
          style: {
            backgroundColor: `${COLORS.GREEN} !important`,
            "&:hover": {
              backgroundColor: `${COLORS.GREEN} !important`,
            },
          }
        },
        {
          props: { variant: "lightBlue" },
          style: {
            backgroundColor: `${COLORS.LIGHT_BLUE} !important`,
            color: `${COLORS.BLUE} !important`,
            "&:hover": {
              backgroundColor: `${COLORS.LIGHT_BLUE} !important`,
            },
          }
        },
        {
          props: { variant: "grey" },
          style: {
            backgroundColor: `${COLORS.GREY} !important`,
            color: `${COLORS.BLACK} !important`,
            "&:hover": {
              backgroundColor: `${COLORS.GREY} !important`,
            },
          }
        }
      ]
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          height: 45,
          [defaultTheme.breakpoints.up("md")]: {
            height: 75,
          },
          "div": {
            borderRadius: 10,
            height: "100%",
            fontSize: 16,
            [defaultTheme.breakpoints.up("md")]: {
              fontSize: 24,
            },
          },
          "input": {
            "::placeholder": {
              color: `${COLORS.BLACK} !important`,
              opacity: 1.0,
            },
          }
        }
      }
    }
  }
});

export const oldTheme = oldCreateTheme({
  palette: {
    primary: {
      main: COLORS.BLUE,
    },
    secondary: {
      main: COLORS.LIGHT_BLUE,
    },
    dark: {
      main: COLORS.BLACK,
    },
    lightGrey: {
      main: COLORS.GREY
    }
  },
  overrides: {
    MuiAutocomplete: {
      popper: {
        zIndex: 4000,
      }
    },
    MuiInputAdornment: {
      root: {
        fontSize: "40px"
      }
    },
    MuiInputBase: {
      root: {
        borderRadius: "8px !important",
        color: COLORS.BLACK,
        fontWeight: "400",
        height: "45px",
        [oldDefaultTheme.breakpoints.up("md")]: {
          height: "75px",
        },
      },
      input: {
        color: COLORS.BLACK,
        opacity: "1",
        backgroundColor: "#fff",
        paddingLeft: "14px !important",
        fontSize: "15px !important",
        [oldDefaultTheme.breakpoints.up("md")]: {
          fontSize: "24px !important",
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: `${COLORS.BLUE} !important`,
        borderRadius: "9px !important"
      },
      input: {
        padding: "0 14px",
      }
    },
    MuiToggleButton: {
      select: {
        backgroundColor: "#0159c2 !important",
      },
    },
    MuiSelect: {
      root: {
        padding: "0 14px",
        fontSize: "16px",
        [oldDefaultTheme.breakpoints.up("md")]: {
          fontSize: "24px",
        },
      },
      select: {
        "&:focus": {
          background: "none !important",
        },
      },
      outlined: {
        borderRadius: "9px",
        paddingRight: "0 !important",
        [oldDefaultTheme.breakpoints.up("md")]: {
          paddingRight: "unset",
        },
      }
    },
    MuiMenuItem: {
      root: {
        border: `1px solid ${COLORS.BLUE}`,
        height: "43px",
        [oldDefaultTheme.breakpoints.up("md")]: {
          height: "63px",
        },
        "&:last-child": {
          borderRadius: "0 0 10px 10px",
        }
      }
    },
    MuiMenu: {
      list: {
        padding: "0",
      },
      paper: {
        borderRadius: "0 0 10px 10px"
      }
    }
  }
});
