import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import { COLORS } from '../../constants/colors';

const StyledSpan = styled.span`
display: inline;
cursor: pointer;
text-decoration: underline !important;
margin: 0 2px !important;
text-transform: inherit !important;
`;

export const Popover = ({ btnText, bodyText, position, classes }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const StyledTooltip = muiStyled(({ className, ...props }) => (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        {...props}
        classes={{ popper: className }}
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={bodyText}
        arrow
      />
    </ClickAwayListener>
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: COLORS.LIGHT_BLUE,
      color: "black",
      fontSize: "16px",
      fontFamily: "COLFAX REGULAR",
      padding: "30px"
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: `${COLORS.LIGHT_BLUE} !important`,
      overflow: "visible",
      position: "absolute !important",
      bottom: "-28px !important",
      width: "50px",
      height: "50px",
      zIndex: -1
    },
    [`& .${tooltipClasses.tooltipPlacementTop}`]: {
      marginBottom: "24px !important",
      marginLeft: "-10px !important"
    }
  }));

  return (
    <StyledTooltip>
      <StyledSpan className={`${classes} text-font-fam-colfax-bold`} variant='body2' onClick={handleTooltipOpen}>{btnText}</StyledSpan>
    </StyledTooltip>
  );
}

export default Popover;