import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { useAppointment } from "../Provider";

const StyledRadio = styled(FormControlLabel)`
  border: 1px solid #b0bdce !important;
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 12px;
  background-color: ${(props) =>
    props.isChecked ? "#0159c2" : "white"} !important;
  color: ${(props) => (props.isChecked ? "white" : "black")} !important;
  .MuiIconButton-colorSecondary {
    color: ${(props) => (props.isChecked ? "white" : "#0159c2")} !important;
  }
`;

export const UnavailableAtAppt = () => {
  const { state, addWillReschedule } = useAppointment();

  return (
    <FormControl fullWidth>
      <Typography variant="subtitle2" className="m-b-8 m-t-20">
        In the event that I am unable to be present at the appointment:
      </Typography>
      <RadioGroup
        value={state.willReschedule}
        onChange={(e) => addWillReschedule(e.target.value)}
        className="m-t-6"
      >
        <StyledRadio
          value={"false"}
          isChecked={state.willReschedule === "false"}
          control={<Radio />}
          label="Hailscale and its partner contractors are authorized to access my roof and perform the inspection at the date and time specified above."
          color="default"
        />
        <StyledRadio
          value={"true"}
          isChecked={state.willReschedule === "true"}
          control={<Radio />}
          label="I will reschedule the appointment."
          color="default"
        />
      </RadioGroup>
    </FormControl>
  );
};
