export const ROUTES = {
  INSPECTION: "/inspection",
  ADMIN: "/admin",
  CRUNCH_ADDRESSES: "/crunch-addresses",
  UPLOAD: "/upload",
  LOGIN_IN: "/login",
  LANDING_PAGE: "/",
  LOADING_REPORT: "/generating-report",
  REPORT: "/report",
  REPORT_ERROR: "/report-generation-error",
  SCHEDULE_FORM: "/schedule",
  SCHEDULE_SUCCESS: "/schedule-success",
  OUR_INSPECTORS: "/our-inspectors",
  ABOUT_US: "/about-us",
  HOW_IT_WORKS: "/how-it-works",
  ERROR: "/error",
  FAQ: "/faq",
  DO_NOT_SELL: "/do-not-sell-my-personal-information",
  PRIVACY_POLICY: "/privacy-policy",
  COOKIE_SETTINGS: "/cookie-settings",
  ARTICLES: "/articles",
  MONITORING: "/hail-monitoring",
  UNSUBSCRIBE: "/unsubscribe",
  SITEMAP: "/sitemap",
  LEGACY: "/bOglXCmG",
  MNRC: "/0OxGWshm",
  TRINITY_EXTERIORS: "/PYx5DpTB",
  RESULTS_CONTRACTING: "/cvom8nmd",
  BOELTER: "/0TeHqc8D",
};

export const ADMIN_ROUTES = {
  APP_MANAGEMENT: {
    route: "appointment-management",
    title: "Appointment Management",
    showInMenu: true
  },
  APP_HISTORY: {
    route: "appointment-history",
    title: "Appointment History",
    showInMenu: true
  },
  CUSTOMERS: {
    route: "customer-profiles",
    title: "Customer Profiles",
    showInMenu: true
  },
  PROPERTIES: {
    route: "property-profiles",
    title: "Properties",
    showInMenu: true
  },
  PARTNERS: {
    route: "partner-profiles",
    title: "Partner Profiles",
    showInMenu: true
  },
  REPORTS: {
    route: "reports",
    title: "Reports",
    showInMenu: true
  },
  APPOINTMENTS: {
    route: "appointments",
    title: "Appointments",
    showInMenu: false
  },
  ACTIVE: {
    route: "active",
    title: "All Active",
    showInMenu: false
  },
  PARTNER_NEEDED: {
    route: "partner-needed",
    title: "Partner Needed",
    showInMenu: false
  },
  FIELD_REP_NEEDED: {
    route: "field-rep-needed",
    title: "Field Rep Needed",
    showInMenu: false
  },
  MISSING_INSPECTION: {
    route: "missing-inspection",
    title: "Missing Inspection",
    showInMenu: false
  },
  HOMEOWNER_TO_FILE: {
    route: "homeowner-to-file",
    title: "Homeowner to File",
    showInMenu: false
  },
  MISSING_ADJUSTMENT_DATE: {
    route: "missing-adjustment-date",
    title: "Missing Adjustment Date",
    showInMenu: false
  },
  MISSING_ADJUSTMENT_OUTCOME: {
    route: "missing-adjustment-outcome",
    title: "Missing Adjustment Outcome",
    showInMenu: false
  },
  MANAGE_CLAIM_STATUS: {
    route: "manage-claim-status",
    title: "Manage Claim Status",
    showInMenu: false
  }
};