import { Box, Grid, Typography, useMediaQuery, useTheme, Button } from "@mui/material";
import Input from "../../Common/InputField";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import rightArrow from "../../../assets/rightArrow.webp";

const MONITOR_POINTS = [
  "Your damage rating is below 7.0 and is not considered high risk",
  "Your damage rating could change after the next big storm. Sign up for our free monitoring service, and we will email you the next time it hails at your location."
]

const Monitor = () => {
  const [appDetails, setAppDetails] = useState({ name: "", email: "" });
  const navigate = useNavigate();
  const bp1300 = useMediaQuery('(min-width:1300px)');
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container item xs={12} className={`text-align-left p-t-45 p-b-45 ${isMedium ? "p-l-85 p-r-60" : "p-l-35 p-r-35"}`} alignItems={"center"}>
      <Grid item xs={12}>
        <Typography variant="h4" className="text-white m-b-20" sx={isMedium ? {} : { fontSize: "24px" }}>
          No inspection needed right now:
        </Typography>
      </Grid>
      <Grid item xs={12} md={bp1300 ? 4 : 6}>
        <Box component={"ul"} sx={{ margin: 0 }}>
          {MONITOR_POINTS.map((item, i) => (
            <Box
              component={"li"}
              key={item.text}
              variant="body1"
              sx={{
                alignItems: "center",
                fontSize: "20px !important",
                marginBottom: i === 0 ? "22px" : 0
              }}
              className="text-white"
            >
              {item}
            </Box>
          ))}
        </Box>
      </Grid>
      <Grid container item xs={12} className="p-l-60" md={bp1300 ? 8 : 6} justifyContent={isMedium ? "center" : 'flex-start'} height={"min-content"}>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: isMedium ? "30px" : "20px" }} variant="h4" className={`${isMedium ? "" : "m-t-40"} text-align-left text-white m-b-20`}>
            Enroll your address for free Hailscale Monitoring.
          </Typography>
        </Grid>
        <Grid container item xs={12} justifyContent={"flex-start"} gap={"18px"} sx={{ maxWidth: bp1300 ? "unset" : isMedium ? "500px !important" : "unset" }} >
          <Grid item sx={{ "div": { height: isMedium ? "75px" : "45px" }, ">div": { width: "100%" }, position: "relative", width: isMedium ? "auto" : "100%" }}>
            <Input
              text="none"
              placeholder="full name..."
              textValue={appDetails.name}
              change={(val) => setAppDetails({ ...appDetails, name: val.target.value })}
              type="text"
            />
          </Grid>
          <Grid item sx={{ width: isMedium ? "350px" : "calc(100% - 95px)" }}>
            <Input
              text="none"
              placeholder="email..."
              textValue={appDetails.email}
              change={(val) => setAppDetails({ ...appDetails, email: val.target.value })}
              type="email"
            />
          </Grid>
          <Grid item>
            <Button
              variant="tan"
              sx={{ fontSize: "30px !important" }}
              onClick={() => navigate(ROUTES.MONITORING, { state: { ...appDetails } })}
              ariaLabel="Continue to monitoring"
            ><Box component={"img"} sx={{ height: "36px", objectFit: "contain" }} src={rightArrow} /></Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default Monitor;