import React from 'react';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { COLORS } from "../../constants/colors";
import { ROUTES } from "../../constants/routes";
import ATagBtn from '../Common/ATagBtn';

const StyledGrid = styled(Grid)`
height: calc(100% - 266px);
position: fixed;
@media (max-width: 960px) {
    height: calc(100% - 371px);
}
`;

const StyledTitle = styled(Typography)`
    color: ${COLORS.RED};
    margin: 30px 0;
`;

const StyledSubTitle = styled(Typography)`
    font-size: 20px !important;
    margin-bottom: 10px;
    @media (max-width: 960px) {
        padding: 0 30px;
    }
`;

export const Error = () => {

  return (
    <StyledGrid container justifyContent='center' alignContent='center'>
      <div>
        <StyledTitle variant='h1'>Something went wrong.</StyledTitle>
        <StyledSubTitle variant='body2'>We seem to have a bug, but we are working on it.</StyledSubTitle>
        <StyledSubTitle variant='body2'>Try going back to home and refreshing the page,<br />or come back in a few minutes.</StyledSubTitle>
        <ATagBtn text="Back to home" href={ROUTES.LANDING_PAGE} />
      </div>
    </StyledGrid>
  );
}

export default Error;