import React, { Fragment, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import styled from "styled-components";
import withLocalContext from "../store/withLocalContext";
import locate from "../../assets/Locate_Black.webp";
import { useInspection } from "./Provider";
import { CircularProgress } from "@material-ui/core";
import { COLORS } from "../../constants/colors";
import { getChunk } from "../../services";

const StyledTextField = styled(TextField)`
  border-radius: 8px;

  > div > input {
    font-size: 19px !important;
    @media (max-width: 960px) {
      font-size: 16px !important;
    }

    &::placeholder {
      ${(props) => (props.isloading === "true" ?
    `color: ${COLORS.LIGHT_BLUE} !important;`
    :
    `color: ${COLORS.BLACK} !important;`
  )}
    }
  }

  .MuiOutlinedInput-inputAdornedEnd {
    padding-left: 5px !important;
  }
  
  @media (max-width: 960px) {  
    max-width: 100%;
  }
`;

const LocateImg = styled.img`
width: 26px;
height: auto;
@media (max-width: 960px) {  
  width: 14px;
}
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${COLORS.LIGHT_BLUE};
  width: 26px !important;
  height: 26px !important;
  @media (max-width: 960px) {  
    width: 14px !important;
    height: 14px !important;
  }
`;

const StyledAutoComplete = styled(Autocomplete)`
  min-width: 470px;
  margin: auto;
  @media (max-width: 960px) {
    min-width: 100%;
  }
`;

export const AddressLookupInput = ({ context: { state }, loading, propertyMgNameValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [predictions, setPredictions] = useState([]);
  const {
    state: localState,
    prepopulate
  } = useInspection();

  const predictAddress = (value) => {
    const result = state.appInspections.filter(checkAddress);
    function checkAddress(address) {
      if (address.address && value.input)
        return address.address.toLowerCase().includes(value.input.toLowerCase());
    }
    setPredictions(result);
  };

  const setAddressDeets = async (addy) => {

    let image_array = [];
    if (addy.images.length > 0) {
      // Loop through images
      // images are composed of an array of arrays that have chunk ids
      for (let a = 0; a < addy.images.length; a++) {
        let image = addy.images[a];
        let name = image.name;
        // loop through array of chunk ids
        let imageStr = "";
        let imageArr = [];
        let moreChunks = true;
        let count = 0;
        while (moreChunks) {
          let chunkRes = await getChunk({ imageName: name, moreChunks: moreChunks, count: count })

          let idSplit = chunkRes.data.chunk._id.split("-");
          let chunkCount = idSplit[idSplit.length - 1];

          imageArr.push({ _id: chunkRes.data.chunk._id, chunk: chunkRes.data.chunk.data, chunkCount: Number(chunkCount) })
          count++;
          if (!chunkRes.data.moreChunks) {
            moreChunks = false;
          }
        }
        imageArr.sort((a, b) => { return a.chunkCount - b.chunkCount });
        imageArr.map(image => {
          return imageStr += image.chunk;
        })

        image_array.push({ name: name, url: imageStr });
      }
    }
    addy.images = image_array;
    propertyMgNameValue(addy.propertyManager)

    prepopulate({ ...addy })
  };

  return (
    <StyledAutoComplete
      className="address_input"
      disabled={loading}
      open={isOpen}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      getOptionSelected={(option, value) => {
        return value.address ? option.address === value.address : option === value;
      }
      }
      popupIcon={null}
      getOptionLabel={(option) => option.address ? option.address : option}
      onChange={(evt, val) => {
        if (val) setAddressDeets(val);
      }}
      id="address-lookup"
      options={predictions}
      value={localState.address}
      renderOption={(option) => (
        <Grid container alignItems="center">
          <Grid item>
            <FontAwesomeIcon icon={faMapMarkerAlt} color={"#1B479E"} />
          </Grid>
          <Grid className="m-l-20" item xs>
            <Typography variant="body2">{option.address}</Typography>
          </Grid>
        </Grid>
      )}
      renderInput={(params) => (
        <Fragment>
          <StyledTextField
            {...params}
            isloading={String(loading)}
            className="bg-white text-font-fam-colfax-regular"
            placeholder={loading ? "Loading addresses..." : "Enter street address..."}
            variant="outlined"
            onChange={(evt) => predictAddress({ input: evt.target.value })}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start" className="m-l-10 ">
                  {loading ?
                    <StyledCircularProgress />
                    :
                    <LocateImg src={locate} alt="" />
                  }
                </InputAdornment>
              ),
            }}
          />
        </Fragment>
      )}
    />
  );
};

export default withLocalContext(AddressLookupInput);
