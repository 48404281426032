import React, { Fragment, useEffect, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import moment from 'moment';
import Typography from "@mui/material/Typography";
import { AdminButton } from "../../../Common/AdminButton";
import styled from "styled-components";
import { AdminInputAndTitle } from "../../../Common/AdminInputAndTitle";
import AdminTimeRange from "../../../Common/AdminTimeRange";
import editBlack from "../../../../assets/edit_icon_black.png";
import cancel_icon from "../../../../assets/cancel_icon.png";
import save_icon from "../../../../assets/save_icon.png";
import { useAdminAppointment } from "../../../Card/Provider";
import { Name } from "../../../Card/Inputs";

const StyledBody1Cont = styled(Grid2)`
  p {
    margin: 5px 0;
  }
`;

const SharedStyled = `
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    transform: scale(1.07);
  }
`;

const StyledDiv = styled.div`
  position: absolute;
  right: 60px;
  width: 45px;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: flex-end;
`;

const StyledImg = styled.img`
  ${SharedStyled}
  width: 20px;
  height: 20px;
`;

export const ActiveApp = ({ app, partners }) => {
  console.log('app', app);
  const { state } = useAdminAppointment();

  const [isExpanded, setIsExpanded] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  let partnerContractor = "";
  for (let i = 0; i < partners.length; i++) {
    const partner = partners[i];
    if (partner._id === app.associated_partner_contractor_id) {
      partnerContractor = partner.tradeName;
    }
  }

  const initialState = {
    _id: app._id,
    fullName: app.associated_owner_id.fullName,
    appointmentStage: app.appointmentStage,
    inspectionDate: app.inspectionDate === "" ? "N/A" : moment(app.inspectionDate).format('MMMM DD, YYYY'),
    timeSlot: app.timeSlot,
    hscRating: app.hscRating,
    totalHailDurationOverRoofAge: app.totalHailDurationOverRoofAge,
    totalHailCountOverRoofAge: app.totalHailCountOverRoofAge,
    dmgAmount: app.dmgAmount,
    partnerContractor: partnerContractor,
    submittedBy: app.submittedBy,
    address: app.associated_property_id.address,
    insuranceProvider: app.insuranceProvider,
    recommendFilingClaim: app.recommendFilingClaim,
    hailLocations: app.hailLocations,
    approvalLikelihood: app.approvalLikelihood,
    filingStatus: app.filingStatus,
    claimOutcome: app.claimOutcome,
    replacementValue: app.replacementValue,
    adjsDate: app.adjsDate,
    buildDate: app.buildDate,
    appointmentStatus: app.appointmentStatus
  }

  useEffect(() => {

  });
  const [appDetails, setAppDetails] = useState(initialState);
  const timeSlot = appDetails.timeSlot.split(" - ");
  const [startTime, setStartTime] = useState(timeSlot[0]);
  const [endTime, setEndTime] = useState(timeSlot[1]);
  const spanClasses = "font-size-16 text-font-fam-colfax-medium uppercase";

  const updateAppDetails = () => {
    console.log(state);

  }

  return (
    <Grid2 container className="text-align-left">
      <StyledBody1Cont container flexDirection={"column"} justifyContent={"space-evenly"} xs={12} md={4} className="p-t-18 p-b-18 p-r-18">
        <Name
          canEdit={canEdit}
          value={app.associated_owner_id.fullName}
        />
        <Typography variant="body1">{app._id}</Typography>
        <Typography variant="body1">{app.appointmentStage}</Typography>
        <AdminInputAndTitle
          title="Date"
          isDisabled={!canEdit}
          type="calendar"
          classNames=""
          value={appDetails.inspectionDate}
          onChange={(val) => setAppDetails({ ...appDetails, inspectionDate: moment(val._d).format("YYYY-MM-DD") })}
          label="Date"
        />
        <AdminTimeRange
          label="Time"
          alignItems
          isDisabled={!canEdit}
          startTime={startTime}
          endTime={endTime}
          onChangeStart={(val) => setStartTime(val)}
          onChangeEnd={(val) => setEndTime(val)}
        />
        <Typography variant="body1"><span className={spanClasses}>Rating:</span> {app.hscRating}</Typography>
        {isExpanded &&
          <Fragment>
            <Typography variant="body1"><span className={spanClasses}>Hail duration:</span> {app.totalHailDurationOverRoofAge}</ Typography>
            <Typography variant="body1"><span className={spanClasses}>Hail storms:</span> {app.totalHailCountOverRoofAge}</Typography>
            <Typography variant="body1"><span className={spanClasses}>Damage amount:</span> {app.dmgAmount}</Typography>
          </Fragment>
        }
      </StyledBody1Cont>
      <StyledBody1Cont xs={12} md={4} container flexDirection={"column"} justifyContent={"space-evenly"} className="p-t-18 p-b-18 p-l-45 border-l-LIGHT_BLUE">
        <Typography variant="body1"><span className={spanClasses}>Contractor:</span> {partnerContractor === "" ? "Unassigend" : partnerContractor}</Typography>
        {/* <Typography variant="body1"><span className={spanClasses}>Contractor:</span> {app.associated_partner_contractor_id === "" ? "Unassigned" : app.associated_partner_contractor_id}</Typography> */}
        <Typography variant="body1" className="-m-t-5 m-b-15">Rep: {app.submittedBy === "" ? "Unassigned" : app.submittedBy}</Typography>
        <Typography variant="body1"><span className={spanClasses}>Address:</span> {app.associated_property_id.address}</Typography>
        <Typography variant="body1"><span className={spanClasses}>Insurance:</span> {app.insuranceProvider}</Typography>
        {isExpanded &&
          <Fragment>
            <Typography variant="body1"><span className={spanClasses}>Recommendation:</span> {app.recommendFilingClaim}</Typography>
            <Typography variant="body1">
              <span className={spanClasses}>Hail locations: </span>
              {app.hailLocations.map((loc, i) => {
                return <span key={i}>{loc}{i === app.hailLocations.length - 1 ? "" : ","} </span>
              })}
            </Typography>
          </Fragment>
        }
      </StyledBody1Cont>
      <StyledBody1Cont xs={12} md={4} container flexDirection={"column"} justifyContent={"space-evenly"} className="p-t-18 p-b-18 p-l-45 border-l-LIGHT_BLUE">
        <Typography variant="body1"><span className={spanClasses}>Approval likelihood:</span> {app.approvalLikelihood}</Typography>
        <Typography variant="body1"><span className={spanClasses}>Filed:</span> {app.filingStatus}</Typography>
        <Typography variant="body1"><span className={spanClasses}>Claim approval:</span> {app.claimOutcome}</Typography>
        {isExpanded &&
          <Fragment>
            <Typography variant="body1"><span className={spanClasses}>Replacement value:</span> {app.replacementValue}</Typography>
            <Typography variant="body1"><span className={spanClasses}>Adjustment date:</span> {app.adjsDate === "" ? "N/A" : moment(app.adjsDate).format('MMMM DD, YYYY')}</Typography>
            <Typography variant="body1"><span className={spanClasses}>Build date:</span> {app.buildDate === "" ? "N/A" : moment(app.buildDate).format('MMMM DD, YYYY')}</Typography>
            <Typography variant="body1"><span className={spanClasses}>Appointment status:</span> {app.appointmentStatus}</Typography>
          </Fragment>
        }
        <Grid2 xs={12}>
          <AdminButton
            classNames={"m-t-10"}
            text={isExpanded ? "collapse details" : "view all details"}
            onClick={() => setIsExpanded(!isExpanded)}
            isDisabled={false}
          />
        </Grid2>
      </StyledBody1Cont>
      <StyledDiv>
        {!canEdit ?
          <Fragment>
            <StyledImg
              onClick={() => setCanEdit(!canEdit)}
              src={editBlack}
              loading="lazy"
              alt=""
            />
          </Fragment>
          :
          <Fragment>
            <StyledImg
              onClick={() => updateAppDetails()}
              src={save_icon}
              loading="lazy"
              alt=""
            />
            <Grid2 xs={6}>
            </Grid2>
            <StyledImg
              onClick={() => setCanEdit(!canEdit)}
              src={cancel_icon}
              loading="lazy"
              alt=""
            />
          </Fragment>
        }
      </StyledDiv>
    </Grid2 >
  );
}

export default ActiveApp;