import React, { Fragment } from "react";
import { useAppointment } from "../Provider";
import Input from "../../Common/InputField";

export const PhoneNumber = () => {
  const { state, addPhoneNumber } = useAppointment();
  return (
    <Fragment>
      <Input
        text="Phone Number"
        placeholder="your phone number..."
        textValue={state.phoneNumber}
        isRequired
        change={(val) => addPhoneNumber(val.target.value)}
        type="tel"
      />
    </Fragment>
  );
};

export default PhoneNumber;
