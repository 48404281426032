import {
  Typography,
  // MenuItem,
  FormControl,
  Select
} from "@mui/material";
import { MenuItem } from "@material-ui/core";
import styled from "styled-components";
import { COLORS } from "../../constants/colors";

export const StyledLabel = styled(Typography)`
  font-size: 20px !important;
  font-family: Colfax Regular;
  text-align: left;
  margin-bottom: 16px !important;
  line-height: 22px !important;
  @media (max-width: 959px) {
    font-size: 16px !important;
    margin-bottom: 8px !important;
  }
  span {
    color: ${COLORS.RED};
  }
`;

export const StyledDiv = styled.div`
  text-align: left;
`;

export const StyledSelect = styled(Select)`
cursor: pointer !important;
border-radius: 10px !important;
text-align: left;
font-size: 24px !important;
background-color: white;
  > div {
    padding: 0 !important;
    line-height: 75px;
    padding-left: 15px !important; 
    @media (max-width: 960px) {
      line-height: 45px;
    }
  }
  > fieldset > legend > span {
    display: none;
  }

  @media (max-width: 960px) {
    font-size: 16px !important;
  }
`;

export const StyledFormControl = styled(FormControl)`

>label {
  color: ${COLORS.BLACK};
  opacity: 0.7 !important;
  font-family: Colfax Regular;
}
>label[data-shrink="true"] {
  // transition: all 0.3s;
  display: none;
}
>label[data-shrink="false"] {
  display: flex;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
  transition: all 0.3s;
  margin-left: 5px;
  font-size: 24px !important;
  @media (max-width: 959px) {
    font-size: 16px !important;
  }
 }
`;

export const StyledMenuItem = styled(MenuItem)`
font-size: 20px !important;
font-family: Colfax Regular !important;
height: 75px;
border-color: ${COLORS.BLUE} !important;
@media (max-width: 959px) {
  height: 45px;
  font-size: 16px !important;
}
`;