export const addReport = (payload) => ({
  type: "add_report",
  payload,
});

export const addLoggedIn = (payload) => ({
  type: "add_logged_in",
  payload,
});

export const addRatingParams = (payload) => ({
  type: "add_rating_params",
  payload,
});

export const addZipcodes = (payload) => ({
  type: "add_zipcodes",
  payload,
});

export const addArticles = (payload) => ({
  type: "add_articles",
  payload,
});

export const addDeviceData = (payload) => ({
  type: "add_device_data",
  payload,
});

export const addAppInspections = (payload) => ({
  type: "add_app_inspection",
  payload,
});

export const addFieldReps = (payload) => ({
  type: "add_field_reps",
  payload,
});