import React, { Fragment } from "react";
import { useInspection } from "../Provider";
import Input from "../../Common/InputField";

export const RetailBids = () => {
  const { state, addRetailBids } = useInspection();
  return (
    <Fragment>
      <Input
        text="Does the property need retail bids for non-storm damage? (please note)"
        placeholder="non-storm bids..."
        isRequired={false}
        textValue={state.retailBids}
        change={(val) => addRetailBids(val.target.value)}
        type="text"
      />
    </Fragment>
  );
};
export default RetailBids;