import React from "react";
import { useInspection } from "../Provider";
import BasicSelect from "../../Common/HSCSelect";

export const SIDING_TYPES = [
  {
    description: "Vinyl",
    value: "vinyl"
  },
  {
    description: "Composite",
    value: "composite"
  },
  {
    description: "Brick",
    value: "brick"
  },
  {
    description: "Stucco",
    value: "stucco"
  },
  {
    description: "Metal",
    value: "metal"
  },
  {
    description: "Aluminum",
    value: "aluminum"
  },
  {
    description: "Wood",
    value: "wood"
  }
];

export const SidingType = () => {
  const { state, addSidingType } = useInspection();

  return (
    <BasicSelect
      text="Siding type"
      label="Choose..."
      selectValue={state.sidingType}
      change={(val) => addSidingType(val.target.value)}
      listMap={SIDING_TYPES}
    />
  );
};
