import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { COLORS } from '../../constants/colors';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Storm_Black from "../../assets/Storm_Black.webp";
import Camera_Black from "../../assets/Camera_Black.webp";
import List_Black from "../../assets/List_Black.webp";
import Inspection_Black from "../../assets/Inspection_Black.webp";
import { OurCertifiedRoofersToolTip } from '../Tooltips/Tooltips';

const twentyFourSeven = <span key={0}><OurCertifiedRoofersToolTip isSingular={true} /> will work</span>;
const documentDamage = <span key={0}><OurCertifiedRoofersToolTip isSingular={true} /> will</span>;

const steps = [
  {
    icon: Storm_Black,
    title: "no-cost inspection",
    content: ["We arrange a free total", <br key={1} />, "exterior home inspection if", <br key={2} />, "your hail rating is 7.0 or above."]
  },
  {
    icon: Camera_Black,
    title: "we Document damage",
    content: [documentDamage, <br key={1} />, "document all hail damage to", <br key={2} />, "your roof, siding, and windows."]
  },
  {
    icon: List_Black,
    title: "File with insurance",
    content: ["Our team will help you file an", <br key={1} />, "insurance claim if damage is", <br key={2} />, "significant."]
  },
  {
    icon: Inspection_Black,
    title: "24/7 claim support",
    content: [twentyFourSeven, <br key={1} />, "directly with your insurance", <br key={2} />, "until restoration is complete."]
  }
];

const HowItWorks = () => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  const isStepOptional = (step) => {
    return "a" === "b";
    // return step === 2;
  };

  const isIdx = (i) => {
    return i % 2 === 0;
  }

  const height = 200;
  const padT = isMedium ? 100 : 50;
  const padB = 40;
  const newMid = 0.58 * 100;
  const medGrey = "#E5ECF4";

  const headerTitle = "Helping homeowners restore roofs, windows, and siding from hail storms";

  const MobileStartEndLines = ({ position }) => {
    return (
      <Grid
        sx={{
          maxWidth: "327px",
          width: "100%",
          margin: "auto",
          position: "absolute",
          height: position === "bottom" ? "130px" : "140px",
          [position]: 0
        }}
      >
        <Grid sx={{
          width: 5,
          top: 0,
          left: "18px",
          backgroundColor: COLORS.LIGHT_BLUE,
          height: "100%",
          position: "absolute"
        }} />
      </Grid>
    )
  }

  return (
    <Fragment>
      {isMedium && <Typography
        variant='h3'
        className='text-blue'
        sx={{
          fontFamily: "Colfax Regular !important",
          position: "absolute",
          zIndex: 10,
          top: 30
        }}
      >
        {headerTitle}
      </Typography>}
      <Grid container sx={{ position: "relative" }} justifyContent={"center"}>
        {!isMedium && <MobileStartEndLines position={"top"} />}
        <Box sx={{ width: '100%' }} className='Box'>
          <Stepper
            activeStep={-1}
            className='Stepper'
            orientation={isMedium ? "horizontal" : "vertical"}
          >
            {steps.map((label, i) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(i)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }

              const stepStyles = isMedium ? {
                paddingLeft: i === 0 ? "20px" : 0,
                paddingRight: steps.length - 1 === i ? "20px" : 0,
                paddingTop: `${padT}px`,
                paddingBottom: `${padB}px`,
                // width: "23%",
                backgroundColor: isIdx(i) ? medGrey : COLORS.GREY,
                position: "relative",
                "~ .MuiStepConnector-root": {
                  position: "relative",
                  height: height + (padT + padB),
                  background: `linear-gradient(90deg, ${isIdx(i) ? medGrey : COLORS.GREY} 0 50%, ${isIdx(i) ? COLORS.GREY : medGrey} 50% 100%)`,
                  display: "flex",
                  ".MuiStepConnector-line": {
                    position: "absolute",
                    top: `${newMid}%`,
                    translate: `transform(0, -${newMid}%)`,
                    width: "100%",
                    marginLeft: "15px",
                    border: `2.5px solid ${COLORS.LIGHT_BLUE}`
                  }
                }
              } : {
                paddingTop: `${padT}px`,
                paddingBottom: `${padB}px`,
                backgroundColor: isIdx(i) ? medGrey : COLORS.GREY,
                "~ .MuiStepConnector-root": {
                  maxWidth: "327px",
                  margin: "auto",
                  position: "relative",
                  background: `linear-gradient(180deg, ${isIdx(i) ? medGrey : COLORS.GREY} 0 50%, ${isIdx(i) ? COLORS.GREY : medGrey} 50% 100%)`,
                  ".MuiStepConnector-line": {
                    position: "absolute",
                    height: 264,
                    top: "50%",
                    left: "-146px",
                    transform: "translate(0, -48%)",
                    border: `2.5px solid ${COLORS.LIGHT_BLUE}`,
                  }
                }
              };

              const stepLabelStyles = isMedium ? {} : {
                margin: "auto",
                maxWidth: " 327px"
              }

              const stepLabelImgStyles = {
                "svg": {
                  height: 33,
                  width: 33,
                  border: `4px solid ${COLORS.LIGHT_BLUE}`,
                  borderRadius: "50%",
                  color: isIdx(i) ? COLORS.GREY : medGrey,
                  "text": {
                    fill: COLORS.BLACK,
                    fontFamily: "Colfax Medium"
                  }
                }
              };
              return (
                <Step
                  key={label.title}
                  {...stepProps}
                  className='Step'
                  sx={stepStyles}
                >
                  <StepLabel
                    {...labelProps}
                    className='StepLabel'
                    sx={{ ...stepLabelImgStyles, ...stepLabelStyles }}
                  >
                    <Grid container sx={{ height: height }}>
                      <Grid item xs={12} sx={{ height: `${100 - newMid}%`, position: isMedium ? "unset" : "relative", "img": { position: "absolute", left: "50%", transform: "translate(-50%, 0)" } }}>
                        <img src={label.icon} alt='' className='max-width-80 max-height-70' />
                      </Grid>
                      <Grid item xs={12} sx={{ height: `${newMid}%` }}>
                        <Typography variant='h4' sx={{ textTransform: "capitalize", textAlign: isMedium ? "left" : "center" }} className='text-blue font-size-24'>{label.title}</Typography>
                        <Typography variant='body1' sx={{ marginTop: "10px", textAlign: "center", color: COLORS.BLACK }}>{label.content}</Typography>
                      </Grid>
                    </Grid>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
        {!isMedium && <MobileStartEndLines position={"bottom"} />}
      </Grid>
    </Fragment >
  );
}

export default HowItWorks;