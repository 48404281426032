import { useEffect, useState } from "react";
import LinearProgress from '@mui/material/LinearProgress';

const ReviewContainer = ({ reviews }) => {

  const [loading, isLoading] = useState(true);

  useEffect(() => {
    const checkingReviewHeightCont = () => {
      const reviewsEl = document.getElementsByClassName(reviews);
      if (reviewsEl[0].clientHeight === 0) {
        setTimeout(() => {
          checkingReviewHeightCont();
        }, 500);
      } else {
        reviewsEl[0].style.marginTop = "-50px";
        isLoading(false);
      }
    }
    checkingReviewHeightCont();
  })

  return (
    <div>
      {loading &&
        <LinearProgress />
      }
      <div className={reviews} />
    </div>
  )
}

export default ReviewContainer;