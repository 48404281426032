export const COLORS = {
  BLUE: "#0E5CBF",
  LIGHT_BLUE: "#B6C8DD",
  WHITE: "#fff",
  GREEN: "#3B9400",
  YELLOW: "#E08B0A",
  RED: "#B04830",
  GREY: "#EEF2F7",
  BLACK: "#000000",
  TAN: "#D4C2AF"
}; 