/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import { postHailScaleRating, postError } from "../../services";
import { ROUTES } from "../../constants/routes";
import withLocalContext from "../store/withLocalContext";
import { addReport } from "../store/actions";
import { getRatingParams, getRoofAgeParam } from "../store/selectors";
import "../../stylesheets/Loader.scss";
import Logo from "../../assets/hailscale-logo-color.svg";
import Loader from "../../assets/loader.gif";
import { PageView } from "../Tracking";
import { useNavigate } from "react-router-dom";

const Background = styled(Grid)`
  max-width: 100%;
  background-color: #0E5CBF;
  overflow: hidden;
  position: relative;
  flex: 1;
`;

const Img = styled.img`
  width: 200px;
`;

export const ReportLoading = ({ context: { dispatch, state } }) => {
  const navigate = useNavigate();
  const [messageIdx, setMessageIdx] = useState(0);
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    PageView();
    window.scrollTo(0, 0);
    fetchReport();
  }, []);

  useEffect(() => {
    setInterval(() => {
      setMessageIdx(messageIdx + 1);
    }, 3000);
  }, [messageIdx]);

  const fetchReport = async () => {
    try {
      const params = getRatingParams(state);

      const res = await postHailScaleRating(params);
      dispatch(addReport(res.data));
      navigate(ROUTES.REPORT);
    } catch (err) {
      postError({ error: err, device: state.deviceData });
      navigate(ROUTES.ERROR);
    }
  };

  const messages = [
    `Reviewing storms in your area over the last ${getRoofAgeParam(
      state
    )} years...`,
    "Evaluating your home's location and pinpointing hail...",
    "Generating your roof's Hailscale Rating...",
  ];

  return (
    <Grid
      className={`fill-height ${isMedium ? "-m-t-100" : "-m-t-56"}`}
      container
      direction="column"
    >
      <Grid item container justifyContent="center" className="p-t-30 p-b-30">
        <Img src={Logo} alt="hailscale-logo" item />
      </Grid>
      <Background
        item
        className="p-l-35 p-r-35"
        container
        alignContent="center"
      >
        {/* <div className="loader" /> */}
        <Grid item xs={12}>
          <img
            src={Loader}
            alt="loader"
            style={{ height: "188px", marginTop: "25px", marginBottom: "22px" }}
          />
        </Grid>
        <Grid container justifyContent="center" item={12}>
          <Grid item xs={12} sm={5}>
            <Typography variant="h3" className="text-white">
              {messageIdx < 2 ?
                messages[messageIdx]
                :
                <span style={{ transitionDelay: 2 }}>Generating your roof's Hailscale Rating...</span>
              }
            </Typography>
          </Grid>
        </Grid>
      </Background>
    </Grid>
  );
};

export default withLocalContext(ReportLoading);
