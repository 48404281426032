import React from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "@mui/material";
import ArticleCard from "../FAQ/articleCard";
import { ROUTES } from "../../constants/routes";
import Typography from "@mui/material/Typography";
import withLocalContext from "../store/withLocalContext";
import { Link, useNavigate } from "react-router-dom";
import { useScreenWidth } from "../Common/useScreenWidth";

export const FAQ = ({ context: { state } }) => {
  const navigate = useNavigate();
  const isSm = useScreenWidth("sm");
  const isMedium = useScreenWidth();

  return (
    <Grid
      container
      item
      className={`m-b-30 ${isSm ? "m-t-24 p-l-110 p-r-110" : "p-l-20 p-r-20"}`}
      style={{ width: "100%" }}
    >
      <Grid item xs={12}>
        <Typography variant="h2" className="m-t-45 m-b-25"><Link className="text-black text-decoration-none" to={ROUTES.FAQ}>Additional Resources & Help</Link></Typography>
      </Grid>
      <Grid item container xs={12} className="text-align-left">
        {state.articles ?
          state.articles.map((element, i) => (
            state.articles.map((article) => {
              return (
                i + 1 === Number(article.acf.sort_order) ?
                  i <= 2 ?
                    <ArticleCard
                      key={article.slug}
                      article={article}
                    />
                    :
                    ""
                  :
                  ""
              )
            })
          ))
          : ""
        }
      </Grid>
      <Grid item xs={12} className={isMedium ? "m-t-30" : ""}>
        <Button
          onClick={() => navigate(ROUTES.FAQ)}
        >View all Resources</Button>
      </Grid>
    </Grid>
  );
};

export default withLocalContext(FAQ);
