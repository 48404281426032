export const longerLeadTimeZipcodes = [
  55007,
  55030,
  55036,
  55037,
  55051,
  55063,
  55067,
  55072,
  55371,
  55704,
  55707,
  55712,
  55718,
  55720,
  55726,
  55733,
  55735,
  55748,
  55749,
  55756,
  55757,
  55760,
  55767,
  55780,
  55783,
  55785,
  55787,
  55795,
  55797,
  55798,
  56083,
  56115,
  56132,
  56152,
  56157,
  56166,
  56169,
  56175,
  56180,
  56214,
  56224,
  56229,
  56239,
  56255,
  56258,
  56263,
  56264,
  56266,
  56283,
  56291,
  56292,
  56293,
  56294,
  56313,
  56314,
  56317,
  56318,
  56328,
  56330,
  56336,
  56338,
  56342,
  56344,
  56345,
  56347,
  56350,
  56353,
  56358,
  56359,
  56363,
  56364,
  56373,
  56382,
  56384,
  56386,
  56389,
  56401,
  56425,
  56431,
  56433,
  56435,
  56437,
  56438,
  56440,
  56441,
  56442,
  56443,
  56444,
  56446,
  56447,
  56448,
  56449,
  56450,
  56452,
  56453,
  56455,
  56456,
  56459,
  56465,
  56466,
  56468,
  56469,
  56472,
  56473,
  56474,
  56475,
  56484,
  56570,
  56626,
  56633,
  56641,
  56655,
  56662,
  56672];